/* ###### 6.1 Content  ###### */

@media (max-width: 575px) {
	.main-content-left-show {
	  overflow: hidden;
	}
  }

  @media (max-width: 991.98px) {
	.main-content-left-show .main-content-left {
	  display: block;
	  position: fixed;
	  top: 0;
	  bottom: 0;
	  left: 0;
	  background-color: $white;
	  z-index: 800;
	  border-right: 1px solid $gray-100;
	  box-shadow: 0 0 3px rgba(20, 17, 45, 0.1);
	  overflow-y: auto;
	  padding: 0 20px 20px;
	}

	.main-content-mail .card {
	  border: 0px !important;
	}
  }

  @media (max-width: 575px) {
	.main-content-left-show .main-content-body {
	  display: none;
	}
  }

  @media (max-width: 991.98px) {
	.main-content-body-show {
	  .main-header {
		.main-header-menu-icon {
		  display: none;
		}

		.main-header-left .main-header-arrow {
		  display: block;
		}
	  }

	  .main-content-left {
		display: none;
	  }

	  .main-content-body {
		display: block;
	  }
	}

	.main-content {
	  padding: 0px;
	  margin-top: 60px;
	  padding-bottom: 20px;
	}

	.main-content-breadcrumb {
	  margin-top: 20px;
	}
  }

  @media (min-width: 992px) {
	.main-content {
	  padding: 30px 0;
	  padding-bottom: 20px;
	  margin-top: 110px;
	}

	.app .main-content {
	  margin-top: 63px;
	  padding-top: 0;

	  &.main-content-app-email {
		margin-top: 0px !important;
	  }
	}
  }

  /*.main-content .container, .main-content .container-fluid {
	  display: flex;
	  padding-left: 20px;
	  padding-right: 20px;
  }*/

  .main-content {
	&.main-content-flex .container, &.main-content-dlex .container-fluid {
	  display: flex;
	}
  }

  @media (min-width: 992px) {
	.main-content .container {
	  padding: 0;
	}
  }

  @media (max-width: 991.98px) {
	.main-content .container {
	  max-width: none;
	}
  }

  @media (min-width: 992px) {
	.main-content-app {
	  height: calc(100vh - 104px);
	}
  }

  .main-content-app {
	.container, .container-fluid {
	  height: 100%;
	  padding: 0;
	}
  }

  .main-content-left {
	width: 100%;
  }

  @media (min-width: 992px) {
	.main-content-left {
	  display: block;
	}

	.main-content-mail .main-content-header {
	  display: none;
	}
  }

  .main-content-mail .main-content-header {
	height: 60px;
	margin-bottom: 0;
  }

  .main-content-header {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.main-logo {
	  flex: 1;
	  text-align: center;
	}
  }

  .main-content-body {
	flex: 1;
  }

  .main-content-right {
	padding-left: 0;
	display: none;
  }

  @media (min-width: 992px) {
	.main-content-right {
	  display: block;
	}
  }

  @media (max-width: 400px) {
	.iti {
	  margin-bottom: 10px;
	}
  }

  @media (max-width: 568px) {
	.main-content-breadcrumb {
	  display: block !important;

	  span {
		display: block;
		padding: 2px 10px;
		display: none;
	  }

	  .main-content-title {
		padding: 10px;
	  }
	}
  }

  .main-content-breadcrumb {
	display: flex;
	align-items: center;
	font-size: 11px;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: #737688;
	margin-bottom: 20px;
	background: $white;
	border: 0px solid $gray-100;
	box-shadow: 0 2px 10px -1px rgba(8, 35, 171, 0.05);
	border-radius: 0px;
  }

  .app .main-content-breadcrumb {
	margin-top: 20px;
  }

  .main-content-breadcrumb span {
	position: relative;
	padding: 5px 10px 4px 20px;
	line-height: 25px;

	&::before {
	  right: -10px;
	  z-index: 3;
	  border-left-style: solid;
	  border-left-width: 11px;
	  position: absolute;
	  top: -1px;
	  width: 0;
	  height: 0;
	  content: '';
	  border-top: 18px solid transparent;
	  border-bottom: 18px solid transparent;
	  border-left-color: $white;
	}

	&::after {
	  position: absolute;
	  top: -1px;
	  width: 0;
	  height: 0;
	  content: '';
	  border-top: 18px solid transparent;
	  border-bottom: 18px solid transparent;
	  right: -11px;
	  z-index: 2;
	  border-left: 11px solid #e3e3ec;
	}

	&:last-child {
	  color: #22252f;
	}
  }

  .main-content-title {
	color: #14112d;
	font-weight: 600;
	font-size: 14px;
	position: relative;
	margin-bottom: 20px;
	text-transform: uppercase;
	padding-right: 13px;
  }

  @media (min-width: 992px) {
	.main-content-title {
	  margin-bottom: 40px;
	}
  }

  .main-content-label, .card-table-two .card-title, .card--eight .card-title {
	color: #14112d;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 1;
	margin-bottom: 10px;
	letter-spacing: .2px;
  }

  .main-content-label-sm {
	font-size: 10px;
	font-weight: 600;
	text-transform: uppercase;
	color: #737688;
	letter-spacing: .5px;
  }

  .main-content-text {
	font-size: 12px;
	line-height: 1.4;
	display: block;
	color: #737688;
  }

  .main-home-slider {
	position: relative;
	z-index: 4;
	width: 100%;
	height: 600px;
	max-height: 1800px;
	overflow: hidden;
	background-color: #1904be;

	.chart-wrapper {
	  position: absolute;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: -20px;
	  z-index: 3;
	  display: flex;
	  align-items: flex-end;
	  opacity: .2;

	  .flot-chart {
		width: 100%;
		height: 400px;
	  }
	}

	.card-columns {
	  column-gap: 5px;
	  column-count: 3;
	  width: 500px;
	  transform: rotate(37deg);
	  position: absolute;
	  top: 9%;
	  left: 24%;
	  z-index: 4;
	  opacity: .25;

	  .card {
		position: relative;
		border-width: 0;
		background-color: transparent;
		margin-bottom: 5px;
	  }
	}
  }

  @media (min-width: 576px) {
	.main-home-slider {
	  height: 600px;
	}
  }

  @media (min-width: 1200px) {
	.main-home-slider {
	  height: 100vh;
	  max-height: 780px;
	}
  }

  @media (min-width: 1440px) {
	.main-home-slider {
	  max-height: 900px;
	}
  }

  @media (min-width: 1680px) {
	.main-home-slider {
	  max-height: 1050px;
	}
  }

  @media (min-width: 1920px) {
	.main-home-slider {
	  max-height: 1200px;
	}
  }

  @media (min-width: 2560px) {
	.main-home-slider {
	  max-height: 1600px;
	}
  }

  @media (min-width: 2880px) {
	.main-home-slider {
	  max-height: 1800px;
	}
  }

  @media (min-width: 768px) {
	.main-home-slider .chart-wrapper {
	  opacity: 1;
	}
  }

  @media (min-width: 992px) {
	.main-home-slider .chart-wrapper .flot-chart {
	  height: 500px;
	}
  }

  @media (min-width: 576px) {
	.main-home-slider .card-columns {
	  width: 120%;
	  left: 16%;
	  transform: rotate(44deg);
	}
  }

  @media (min-width: 768px) {
	.main-home-slider .card-columns {
	  transform: rotate(45deg);
	  width: 100%;
	  top: 0;
	  left: auto;
	  right: -45%;
	  opacity: 1;
	}
  }

  @media (min-width: 992px) {
	.main-home-slider .card-columns {
	  width: 80%;
	  top: -10%;
	  right: -30%;
	  column-gap: 10px;
	}
  }

  @media (min-width: 1200px) {
	.main-home-slider .card-columns {
	  width: 70%;
	  right: -15%;
	  top: -15%;
	}
  }

  @media (min-width: 1300px) {
	.main-home-slider .card-columns {
	  right: -5%;
	}
  }

  @media (min-width: 992px) {
	.main-home-slider .card-columns .card {
	  margin-bottom: 10px;
	}
  }

  .main-home-content {
	position: absolute;
	top: 20px;
	left: 20px;
	z-index: 5;
	color: $white-7;
	right: 20px;

	.main-logo {
	  font-size: 30px;
	  font-weight: 700;
	  display: flex;
	  line-height: 1;
	  color: $white;
	  margin-bottom: 5px;
	  text-indent: -1px;
	  color: $white;
	  letter-spacing: -.5px;
	}

	h5 {
	  font-size: 12px;
	  font-weight: 400;
	  letter-spacing: .2px;
	  margin-bottom: 25px;
	}

	.logo-group {
	  margin-bottom: 60px;
	  display: flex;

	  i {
		font-weight: 400;
		font-size: 32px;
		line-height: .2;

		+ i {
		  margin-left: 10px;
		}
	  }
	}

	h1 {
	  color: $white;
	  font-weight: 300;
	  font-size: 24px;
	  margin-bottom: 20px;
	}

	p {
	  &:first-of-type {
		font-size: 14px;
		line-height: 1.7;
		margin-bottom: 25px;
	  }

	  &:last-of-type {
		display: flex;
		margin-bottom: 100px;

		.btn, .sp-container button {
		  flex: 1;
		  text-transform: uppercase;
		  font-size: 11px;
		  font-weight: 500;
		  min-height: inherit;
		  letter-spacing: 1px;
		  padding: 10px 15px;
		}
	  }
	}
  }

  @media (min-width: 576px) {
	.main-home-content {
	  top: 40px;
	  left: 40px;
	  right: auto;
	  width: 400px;
	}
  }

  @media (min-width: 768px) {
	.main-home-content {
	  width: 410px;
	}
  }

  @media (min-width: 576px) {
	.main-home-content .main-logo {
	  font-size: 36px;
	  letter-spacing: -1px;
	}
  }

  @media (min-width: 576px) {
	.main-home-content h5 {
	  font-size: 15px;
	}
  }

  @media (min-width: 576px) {
	.main-home-content h1 {
	  font-size: 2.03125rem;
	}
  }

  .sp-container .main-home-content p:last-of-type button {
	flex: 1;
	text-transform: uppercase;
	font-size: 11px;
	font-weight: 500;
	min-height: inherit;
	letter-spacing: 1px;
	padding: 10px 15px;
  }

  @media (min-width: 768px) {
	.main-home-content p:last-of-type {
	  .btn, .sp-container button {
		padding: 10px 25px;
		flex: none;
	  }
	}

	.sp-container .main-home-content p:last-of-type button {
	  padding: 10px 25px;
	  flex: none;
	}
  }

  @media (min-width: 992px) {
	.main-home-content p:last-of-type {
	  .btn, .sp-container button {
		flex: 1;
	  }
	}

	.sp-container .main-home-content p:last-of-type button {
	  flex: 1;
	}
  }

  .main-home-content p:last-of-type {
	.btn + .btn, .sp-container button + .btn {
	  margin-left: 5px;
	}
  }

  .sp-container .main-home-content p:last-of-type button + .btn, .main-home-content p:last-of-type .sp-container .btn + button, .sp-container .main-home-content p:last-of-type .btn + button, .main-home-content p:last-of-type .sp-container button + button, .sp-container .main-home-content p:last-of-type button + button {
	margin-left: 5px;
  }

  .main-home-content nav:last-child {
	display: flex;

	a {
	  font-size: 24px;
	  color: $white-5;

	  &:hover, &:focus {
		color: $white;
	  }

	  + a {
		margin-left: 8px;
	  }
	}
  }

  .main-content-choose-demo {
	position: relative;
	z-index: 5;
	background-color: #0f0373;
	color: $white-5;
	padding: 0;

	.container {
	  display: block;
	  padding: 20px;
	}

	.title-label {
	  text-transform: uppercase;
	  letter-spacing: 1px;
	  font-size: 10px;
	  color: $primary;
	  margin-bottom: 3px;
	}

	.title {
	  font-size: 20px;
	  font-weight: 400;
	  color: $white;
	  letter-spacing: -.5px;
	  margin-bottom: 10px;
	}

	.title-text {
	  font-weight: 300;
	  margin-bottom: 20px;
	}

	.card {
	  border-width: 0;
	  margin-bottom: 10px;
	  height: 200px;
	  overflow: hidden;
	  position: relative;

	  img {
		transition: all 0.2s ease-in-out;
	  }

	  figure {
		margin-bottom: 0;

		&:hover img, &:focus img {
		  transform: scale(1.75, 1.75);
		}

		&:hover figcaption, &:focus figcaption {
		  opacity: 1;
		}
	  }

	  figcaption {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(25, 4, 190, 0.75);
		opacity: 0;
		transition: all 0.2s ease-in-out;

		.btn, .sp-container button {
		  text-transform: uppercase;
		  letter-spacing: 1.5px;
		  font-size: 11px;
		  font-weight: 500;
		  min-height: inherit;
		  padding: 10px 20px;
		  border-radius: 2px;
		}
	  }
	}
  }

  @media (min-width: 576px) {
	.main-content-choose-demo .container {
	  padding: 40px;
	}
  }

  @media (min-width: 992px) {
	.main-content-choose-demo .container {
	  padding: 100px 40px;
	}
  }

  @media (min-width: 1200px) {
	.main-content-choose-demo .container {
	  padding: 100px 0;
	}
  }

  @media (min-width: 768px) {
	.main-content-choose-demo .title-label {
	  font-size: 11px;
	}
  }

  @media (min-width: 768px) {
	.main-content-choose-demo .title {
	  font-size: 30px;
	}
  }

  @media (min-width: 576px) {
	.main-content-choose-demo .title-text {
	  margin-bottom: 40px;
	}
  }

  @media (min-width: 768px) {
	.main-content-choose-demo .title-text {
	  font-size: 16px;
	}
  }

  @media (min-width: 992px) {
	.main-content-choose-demo .title-text {
	  margin-bottom: 60px;
	}
  }

  @media (min-width: 576px) {
	.main-content-choose-demo .card {
	  height: 155px;
	}
  }

  @media (min-width: 768px) {
	.main-content-choose-demo .card {
	  height: 215px;
	  margin-bottom: 15px;
	}
  }

  @media (min-width: 1200px) {
	.main-content-choose-demo .card {
	  height: 255px;
	}
  }

  @media (prefers-reduced-motion: reduce) {
	.main-content-choose-demo .card img {
	  transition: none;
	}
  }

  @media (prefers-reduced-motion: reduce) {
	.main-content-choose-demo .card figcaption {
	  transition: none;
	}
  }

  .sp-container .main-content-choose-demo .card figcaption button {
	text-transform: uppercase;
	letter-spacing: 1.5px;
	font-size: 11px;
	font-weight: 500;
	min-height: inherit;
	padding: 10px 20px;
	border-radius: 2px;
  }

  .main-content-choose-demo {
	.card.coming-soon {
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  color: #14112d;
	  background-color: $background;

	  .typcn {
		line-height: .9;
		font-size: 48px;
		margin-bottom: 10px;
	  }

	  h6 {
		margin-bottom: 0;
		text-transform: uppercase;
		font-weight: 800;
		letter-spacing: 1px;
	  }
	}

	.main-content-label, .card-table-two .card-title {
	  color: #737688;
	  margin-bottom: 5px;
	  letter-spacing: 1px;
	}
  }

  .card-table-two .main-content-choose-demo .card-title, .main-content-choose-demo .card--eight .card-title, .card--eight .main-content-choose-demo .card-title {
	color: #737688;
	margin-bottom: 5px;
	letter-spacing: 1px;
  }

  .main-content-choose-demo .main-content-title {
	font-size: 15px;
	font-weight: 400;
	margin-bottom: 0;
	color: $white;
  }

  @media (min-width: 768px) {
	.main-content-choose-demo .main-content-title {
	  font-size: 17px;
	}
  }

  .main-toggle-group-demo {
	display: flex;

	.main-toggle + .main-toggle {
	  margin-left: 10px;
	}
  }

  .main-content-left-components {
	border-right: 1px solid $gray-100;
	width: 200px;

	.component-item {
	  label {
		display: block;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 700;
		letter-spacing: .5px;

		~ label {
		  margin-top: 30px;
		}
	  }

	  .nav-link {
		padding: 0;
		display: block;
		font-size: 13px;
		color: #22252f;

		+ .nav-link {
		  margin-top: 5px;
		}

		&:hover {
		  color: $primary;
		}

		&.active {
		  color: $primary;
		  font-weight: 500;
		}
	  }
	}
  }

  .main-content-right {
	width: 100%;
	position: relative;
	flex-shrink: 0;
	display: none;
  }

  @media (min-width: 992px) {
	.main-content-right {
	  display: block;
	}
  }

  .app .main-content-right-components {
	position: sticky;
	border-right: 0;
	top: 4rem;
  }

  .main-content-right-components .component-item {
	padding: 20px;
	background: $white;
	border-radius: 0px;
	box-shadow: 0 2px 10px -1px rgba(8, 35, 171, 0.05);
	border-top: 1px solid $gray-100;

	a {
	  &:first-child {
		border-top: 0;
		padding-top: 0;
	  }

	  &:last-child {
		padding-bottom: 0;
	  }
	}

	label {
	  display: block;
	  text-transform: uppercase;
	  font-size: 13px;
	  font-weight: 700;
	  letter-spacing: .5px;
	  margin-bottom: 22px;

	  ~ label {
		margin-top: 30px;
	  }
	}

	.nav-link {
	  padding: 8px 0;
	  display: block;
	  font-size: 13px;
	  font-weight: 400;
	  border-top: 1px solid #efefef;
	  color: #22252f;

	  + .nav-link {
		margin-top: 0;
	  }

	  &:hover {
		color: $primary;
	  }

	  &.active {
		color: $primary;
		font-weight: 500;
	  }
	}
  }

  .main-page-header {
	position: relative;
	padding: 30px 0 40px;
	border-bottom: 1px solid #dee2ef;
	margin-bottom: 40px;
  }

  .main-page-title {
	font-weight: 700;
	font-size: 40px;
	font-family: "Raleway", sans-serif;
	margin-bottom: 15px;
	line-height: 1;
	letter-spacing: -1.5px;
  }

  .main-page-text {
	font-size: 20px;
	font-weight: 300;
	margin-bottom: 30px;
  }

  .main-page-content-list {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
	  display: flex;
	  align-items: center;
	  font-size: 13px;
	  font-weight: 500;

	  + li {
		margin-top: 5px;
	  }

	  i {
		margin-right: 10px;
		font-size: 10px;
		line-height: 0;
		color: #737688;
		text-indent: 1px;
	  }
	}
  }

  .main-page-label {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 5px;
	letter-spacing: -.2px;
  }

  .main-syntax-wrapper {
	position: relative;

	.badge {
	  padding: 4px 5px;
	  position: absolute;
	  top: 5px;
	  right: 5px;
	  text-transform: uppercase;
	  font-weight: 500;
	  font-size: 9px;
	  letter-spacing: normal;
	  border-radius: 1px;
	}

	pre {
	  border-color: $gray-100;
	  background-color: $white;
	  padding: 15px;
	  font-size: 13px;
	}

	code[class*="language-"], pre[class*="language-"] {
	  font-family: 'Roboto Mono', monospace;
	}
  }

  .main-content-left {
	.ui-datepicker .ui-datepicker-calendar td {
	  border: 1px solid #efefef;

	  &:first-child {
		border-left: 0;
	  }
	}

	&.card--calendar .ui-datepicker .ui-datepicker-calendar .ui-datepicker-today {
	  a {
		border: 0;
	  }

	  background: $background;
	}
  }

  @media (max-width: 992px) {
	.main-content-dashboard-four {
	  padding-top: 0;
	}
  }

  @media (max-width: 568px) {
	.main-profile-body .media.profile-footer {
	  display: block !important;

	  .media-body {
		padding: 3px 0 15px 0;
	  }
	}

	.main-content-body-profile .nav {
	  padding: 10px 20px !important;
	}
  }

  @media (min-width: 992px) {
	.main-header .main-header-left .main-logo.mobile-logo {
	  display: none;
	}
  }

  .main-content-header h6 {
	text-transform: capitalize;
	margin-bottom: 0;
  }

  .main-header-left {
	border-right: 0 !important;
  }

  .main-traffic-detail-item > div:first-child > span:first-child {
	color: #737688;
  }

  .main-content-header-right .btn, .main-content-dashboard-four .main-content-header-right .btn {
	margin-top: 20px;
  }

  /* ############### DASHBOARD STYLES ############### */

  /* ###### 8.1 Dashboard One  ###### */

  .main-content-dashboard {
	padding-top: 20px;
  }

  @media (min-width: 992px) {
	.main-content-dashboard {
	  padding-top: 30px;
	}
  }

  .main-dashboard-one-title {
	margin-bottom: 20px;
  }

  @media (min-width: 576px) {
	.main-dashboard-one-title {
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	}
  }

  @media (min-width: 992px) {
	.main-dashboard-one-title {
	  margin-bottom: 30px;
	}
  }

  .main-dashboard-title {
	font-size: 22px;
	font-weight: 700;
	color: #14112d;
	letter-spacing: -1px;
	margin-bottom: 3px;
  }

  .main-dashboard-text {
	font-size: 13px;
	margin-bottom: 0;
  }

  .main-dashboard-date {
	display: flex;
	align-items: center;
	margin-top: 20px;

	.date {
	  display: flex;

	  > div {
		&:first-child {
		  font-size: 32px;
		  font-family: Arial, sans-serif;
		  font-weight: 500;
		  color: #14112d;
		  line-height: .9;
		}

		&:last-child {
		  margin-left: 5px;

		  span {
			display: block;
			color: #14112d;
			font-weight: 700;
			font-size: 12px;
			line-height: 1.2;
			letter-spacing: -.5px;

			&:last-child {
			  color: #737688;
			  font-weight: 400;
			}
		  }
		}
	  }
	}

	> i {
	  font-size: 21px;
	  line-height: 0;
	  display: block;
	  margin: 0 15px;
	  color: #737688;
	}
  }

  @media (min-width: 576px) {
	.main-dashboard-date {
	  margin-top: 0;
	}
  }

  @media (min-width: 768px) {
	.main-dashboard-date .date > div:first-child {
	  font-size: 36px;
	}
  }

  @media (min-width: 768px) {
	.main-dashboard-date .date > div:last-child span {
	  font-size: 13px;
	}
  }

  @media (min-width: 768px) {
	.main-dashboard-date > i {
	  margin: 0 20px;
	  font-size: 24px;
	}
  }

  .main-dashboard-nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid $gray-100;
	margin-bottom: 20px;

	.nav-link {
	  font-size: 12px;
	  padding: 5px 10px 8px;
	  display: flex;
	  align-items: center;
	  line-height: 1;

	  &:hover, &:focus {
		color: $primary;
	  }

	  + .nav-link {
		border-left: 1px solid $gray-100;
	  }
	}

	.nav {
	  &:first-child .nav-link {
		color: #14112d;
		display: none;

		&:hover, &:focus, &.active {
		  color: $primary;
		}

		&:first-child {
		  padding-left: 0;
		  display: block;
		}

		&:last-child {
		  display: block;
		}
	  }

	  &:last-child .nav-link {
		color: #22252f;
		display: none;

		&:hover, &:focus {
		  color: $primary;
		}

		i {
		  font-size: 16px;
		  margin-right: 7px;
		  line-height: 0;
		}

		&:last-child {
		  padding-right: 0;
		  display: block;

		  i {
			margin-right: 0;
		  }
		}
	  }
	}
  }

  @media (min-width: 576px) {
	.main-dashboard-nav .nav-link {
	  font-size: 0.875rem;
	}
  }

  @media (min-width: 992px) {
	.main-dashboard-nav .nav-link {
	  padding: 5px 15px 10px;
	}
  }

  @media (min-width: 576px) {
	.main-dashboard-nav .nav:first-child .nav-link {
	  display: block;
	}
  }

  @media (min-width: 768px) {
	.main-dashboard-nav .nav:last-child .nav-link {
	  display: block;
	}
  }

  @media (min-width: 768px) {
	.main-dashboard-nav .nav:last-child .nav-link:last-child {
	  display: none;
	}
  }

  /* ###### 8.2 Dashboard Two  ###### */

  .main-content-dashboard-two {
	background-color: #fdfdfd;
	transition: all 0.4s;

	.main-content-title {
	  letter-spacing: -.5px;
	}

	.main-content-body {
	  padding: 0 20px;
	}

	.main-header-menu-icon {
	  margin-right: 0;
	}
  }

  @media (prefers-reduced-motion: reduce) {
	.main-content-dashboard-two {
	  transition: none;
	}
  }

  @media (min-width: 992px) {
	.main-content-dashboard-two .main-content-body {
	  padding: 0 20px 20px;
	}
  }

  .main-dashboard-header-right {
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;

	> div {
	  label {
		display: block;
		margin-bottom: 5px;
	  }

	  h5 {
		font-size: 20px;
		font-weight: 700;
		font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		color: #14112d;
		margin-bottom: 0;
		line-height: 1;
	  }

	  &:first-child {
		flex-basis: 100%;
	  }

	  + div {
		margin-top: 10px;
	  }
	}
  }

  @media (min-width: 576px) {
	.main-dashboard-header-right {
	  flex-wrap: nowrap;
	}
  }

  @media (min-width: 768px) {
	.main-dashboard-header-right {
	  margin-top: 0;
	}
  }

  @media (min-width: 768px) {
	.main-dashboard-header-right > div {
	  text-align: right;
	}
  }

  @media (max-width: 575.98px) {
	.main-dashboard-header-right > div + div {
	  flex-basis: 50%;
	}
  }

  @media (min-width: 576px) {
	.main-dashboard-header-right > div + div {
	  margin-left: 25px;
	  margin-top: 0;
	  flex-shrink: 0;
	}
  }

  @media (min-width: 992px) {
	.main-dashboard-header-right > div + div {
	  margin-left: 50px;
	}
  }

  .main-star {
	display: flex;
	align-items: center;

	i {
	  font-size: 20px;
	  line-height: 0;
	  color: #737688;
	  display: inline-block;

	  &.typcn {
		line-height: .9;
	  }

	  &.active {
		color: $warning;
	  }
	}

	span {
	  font-size: 13px;
	  display: inline-block;
	  margin-left: 5px;
	}
  }

  .table-dashboard-two {
	thead > tr > {
	  th, td {
		white-space: nowrap;
	  }
	}

	tbody > tr > {
	  th, td {
		white-space: nowrap;
	  }

	  th:not(:first-child), td:not(:first-child) {
		font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		font-weight: 600;
		font-size: 13px;
	  }
	}
  }

  /* ###### 8.3 Dashboard Three  ###### */

  .main-content-dashboard-three {
	position: relative;
	padding: 0;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: #fdfdfd;
  }

  @media (min-width: 992px) {
	.main-content-dashboard-three {
	  border-left: 1px solid $gray-100;
	  margin-left: 64px;
	}
  }

  .main-header-dashboard-three {
	height: auto;

	.container-fluid {
	  padding: 15px 20px;
	}

	.main-header-center {
	  margin-left: 0;
	}

	.main-header-right {
	  margin-left: auto;
	}
  }

  @media (min-width: 992px) {
	.main-header-dashboard-three .main-header-left {
	  display: none;
	}
  }

  .main-content-body-dashboard-three {
	padding: 20px;
	position: relative;
	width: 100%;

	.main-content-title {
	  letter-spacing: -.5px;
	}
  }

  /* ###### 8.4 Dashboard Four  ###### */

  .main-navbar-dashboard-four {
	box-shadow: 0 0 30px rgba(20, 17, 45, 0.08);
  }

  @media (min-width: 992px) {
	.main-navbar-dashboard-four .container-fluid {
	  padding-left: 25px;
	  padding-right: 25px;
	}
  }

  .main-content-dashboard-four {
	padding: 20px;

	~ .main-footer {
	  .container, .container-fluid {
		padding-left: 25px;
		padding-right: 25px;
	  }
	}

	> {
	  .dashboard {
		display: block;
	  }

	  .media-dashboard .media-aside {
		margin-top: 20px;
	  }
	}

	.main-content-header {
	  height: auto;
	  margin-bottom: 20px;
	  align-items: flex-start;
	  padding: 0;
	  box-shadow: 0 2px 10px -1px rgba(8, 35, 171, 0.05);
	  border-radius: 0px;
	}
  }

  @media (min-width: 992px) {
	.main-content-dashboard-four {
	  flex: 1;
	  padding: 30px 25px;
	}
  }

  @media (min-width: 1200px) {
	.main-content-dashboard-four > .media-dashboard {
	  display: flex;
	  align-items: stretch;
	  height: 100%;
	}
  }

  @media (min-width: 1200px) {
	.main-content-dashboard-four > .media-dashboard .media-aside {
	  margin-top: 0;
	  display: block;
	  width: 320px;
	}
  }

  @media (min-width: 1200px) {
	.main-content-dashboard-four > .media-dashboard > .media-body {
	  padding: 0 20px 0 0;
	}
  }

  @media (min-width: 768px) {
	.main-content-dashboard-four .main-content-header {
	  display: flex;
	}
  }

  .main-content-header-right {
	.media {
	  label {
		margin-bottom: 2px;
		font-size: 10px;
		font-weight: 500;
		letter-spacing: .5px;
		text-transform: uppercase;
		color: #737688;
	  }

	  h6 {
		color: #14112d;
		margin-bottom: 0;
	  }

	  + .media {
		margin-left: 20px;
		padding-left: 20px;
		border-left: 1px solid $gray-100;
	  }
	}

	.btn:first-of-type, .sp-container button:first-of-type {
	  margin-left: 30px;
	}
  }

  @media (min-width: 992px) {
	.main-content-header-right {
	  display: flex;
	  align-items: center;
	}
  }

  .sp-container .main-content-header-right button:first-of-type {
	margin-left: 30px;
  }

  .main-content-header-right {
	.btn + .btn, .sp-container button + .btn {
	  margin-left: 5px;
	}
  }

  .sp-container .main-content-header-right button + .btn, .main-content-header-right .sp-container .btn + button, .sp-container .main-content-header-right .btn + button, .main-content-header-right .sp-container button + button, .sp-container .main-content-header-right button + button {
	margin-left: 5px;
  }

  .main-media-date {
	h1 {
	  font-size: 42px;
	  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	  color: #14112d;
	  line-height: .95;
	}

	.media-body {
	  margin-left: 5px;
	}

	p {
	  color: #14112d;
	  font-weight: 700;
	  margin-bottom: 0;
	  text-transform: uppercase;
	}

	span {
	  display: block;
	  line-height: 1.2;
	}
  }

  /* ###### 8.5 Dashboard Five  ###### */

  .main-body-sidebar.main-light .main-sidebar {
	background-color: $background;
	border-right-color: $background;
	box-shadow: 2px 0 3px rgba(20, 17, 45, 0.04);
  }

  .main-content-dashboard-five {
	background-color: #eeeff4;
	transition: all 0.4s;

	.main-header {
	  background-color: $background;
	  box-shadow: 0 2px 3px rgba(20, 17, 45, 0.05);
	}

	.main-header-menu-icon {
	  margin-right: 0;
	}

	.main-header-center .form-control {
	  border-color: $gray-100;
	}

	.main-content-header {
	  padding: 20px 20px 0;
	}

	.main-content-body {
	  padding: 20px;
	}

	.card {
	  background-color: $background;
	  border-color: $gray-100;
	  box-shadow: 0 2px 10px -1px rgba(8, 35, 171, 0.05);
	}

	.card-header {
	  padding: 20px 20px 0;
	  background-color: transparent;
	}

	.card-text {
	  font-size: 12px;
	  line-height: 1.4;
	  color: #737688;
	  margin-bottom: 0;
	}
  }

  @media (prefers-reduced-motion: reduce) {
	.main-content-dashboard-five {
	  transition: none;
	}
  }

  @media (min-width: 1200px) {
	.main-content-dashboard-five .main-content-header {
	  padding: 25px 25px 0;
	}
  }

  .table-talk-time {
	margin-bottom: 0;

	thead > tr {
	  th, td {
		padding: 7px 10px;
	  }
	}

	tbody > tr {
	  th, td {
		padding: 7px 10px;
	  }
	}

	thead > tr {
	  th:first-child, td:first-child {
		padding-left: 20px;
	  }
	}

	tbody > tr {
	  th:first-child, td:first-child {
		padding-left: 20px;
	  }
	}
  }

  .dashboard-five-stacked-chart {
	height: 200px;
  }

  @media (min-width: 768px) {
	.dashboard-five-stacked-chart {
	  height: 240px;
	}
  }

  @media (min-width: 847px) {
	.dashboard-five-stacked-chart {
	  height: 260px;
	}
  }

  @media (min-width: 992px) {
	.dashboard-five-stacked-chart {
	  height: 240px;
	}
  }

  @media (min-width: 1067px) {
	.dashboard-five-stacked-chart {
	  height: 260px;
	}
  }

  /* ###### 8.6 Dashboard Six  ###### */

  .main-light {
	.main-iconbar, .main-iconbar-aside {
	  background-color: $background;
	}

	.main-iconbar-body {
	  .nav {
		border-left-color: $background;
	  }

	  .nav-item + .nav-item, .nav-sub, .nav-sub-item + .nav-sub-item {
		border-top-color: #bdc6d6;
	  }
	}
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
	.main-light .main-iconbar-aside.show + .main-content {
	  box-shadow: none;
	}
  }

  .main-content-dashboard-six {
	border-left-color: $background;
	box-shadow: 0 0 7px rgba(20, 17, 45, 0.08);
	position: relative;
	padding: 0;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: $background;
  }

  @media (min-width: 992px) {
	.main-content-dashboard-six {
	  border-left: 1px solid $gray-100;
	  margin-left: 64px;
	  z-index: 550;
	}
  }

  .main-header-dashboard-six {
	background-color: $white;
	border-bottom: 1px solid $gray-100;
	box-shadow: 0 0 10px rgba(20, 17, 45, 0.06);

	.main-header-menu-icon {
	  margin-right: 0;
	}
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
	.main-header-dashboard-six .main-header-center {
	  margin-left: 5px;
	}
  }

  @media (min-width: 1200px) {
	.main-header-dashboard-six .main-header-center {
	  margin-left: 0;
	}
  }

  @media (min-width: 992px) {
	.main-header-dashboard-six .main-header-menu-icon {
	  display: none;
	}
  }

  .main-content-body-dashboard-six {
	padding: 25px;

	.main-content-title {
	  letter-spacing: -.8px;
	}

	.card {
	  border-color: $gray-100;
	  box-shadow: 0 0 10px rgba(20, 17, 45, 0.06);
	}

	.card-header {
	  background-color: transparent;
	  padding: 20px 20px 0;
	}

	.card-title {
	  text-transform: uppercase;
	  font-weight: 700;
	}
  }

  /* ###### 8.7 Dashboard Seven  ###### */

  .main-minimal {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: $white;

	.main-navbar-three {
	  .nav-item.active {
		&::before {
		  bottom: -3px;
		}

		.nav-link {
		  font-weight: 700;
		}
	  }

	  .nav-link {
		font-size: 13px;
	  }
	}

	&.main-navbar-show .main-header {
	  z-index: auto;
	}
  }

  @media (min-width: 1200px) {
	.main-minimal {
	  padding: 10px 0 0;
	}
  }

  .main-content-title-minimal {
	color: #14112d;
	font-weight: 700;
	font-size: 24px;
	letter-spacing: -1px;
	line-height: 1;
	margin-bottom: 8px;

	span {
	  font-weight: 700;
	}
  }

  .main-content-text-minimal {
	font-weight: 400;
	color: #22252f;
	margin-bottom: 20px;
  }

  .media-dashboard-one {
	display: block;

	.media-right {
	  width: 100%;
	  flex-shrink: 0;
	}

	.media-body {
	  margin-bottom: 20px;
	}
  }

  @media (min-width: 992px) {
	.media-dashboard-one {
	  display: flex;
	  align-items: stretch;
	}
  }

  @media (min-width: 992px) {
	.media-dashboard-one .media-right {
	  width: 280px;
	}
  }

  @media (min-width: 992px) {
	.media-dashboard-one .media-body {
	  margin-right: 20px;
	  margin-bottom: 0;
	}
  }

  /* ###### 8.8 Dashboard Eight  ###### */

  .main-dashboard-eight {
	background-color: #dde2e9;
  }

  .main-header-primary {
	background-color: $primary;

	.main-header-center {
	  .form-control {
		background-color: #0033cc;
		border-width: 0;
		height: 38px;
		border-radius: 2px;
		color: $white;

		&::placeholder {
		  color: $white-4;
		}

		&:focus + .btn {
		  color: $white;
		}
	  }

	  .sp-container .form-control:focus + button {
		color: $white;
	  }
	}
  }

  .sp-container .main-header-primary .main-header-center .form-control:focus + button {
	color: $white;
  }

  .main-header-primary .main-header-center {
	.btn, .sp-container button {
	  font-size: 14px;
	}
  }

  .sp-container .main-header-primary .main-header-center button {
	font-size: 14px;
  }

  .main-header-primary .main-header-center {
	.btn:hover, .sp-container button:hover {
	  color: $white;
	}
  }

  .sp-container .main-header-primary .main-header-center button:hover {
	color: $white;
  }

  .main-header-primary .main-header-center {
	.btn:focus, .sp-container button:focus {
	  color: $white;
	}
  }

  .sp-container .main-header-primary .main-header-center button:focus {
	color: $white;
  }

  .main-header-primary {
	.main-logo {
	  color: $white;
	  font-weight: 600;
	  letter-spacing: -1.2px;

	  span {
		color: rgba(255, 255, 255, 0.65);
	  }
	}

	.main-header-menu-icon span {
	  background-color: rgba(255, 255, 255, 0.65);

	  &::before, &::after {
		background-color: rgba(255, 255, 255, 0.65);
	  }
	}

	.main-header-message > a {
	  color: rgba(255, 255, 255, 0.75);
	}

	.main-header-notification {
	  > a {
		color: rgba(255, 255, 255, 0.75);

		&::after {
		  display: none;
		}
	  }

	  .dropdown-menu {
		border-width: 0;
		box-shadow: 0 0 5px rgba(20, 17, 45, 0.1);
	  }
	}

	.main-profile-menu {
	  .dropdown-menu {
		border-width: 0;
		box-shadow: 0 0 5px rgba(20, 17, 45, 0.1);
	  }

	  &.show .main-img-user::before {
		display: none;
	  }
	}
  }

  @media (min-width: 576px) {
	.main-header-primary .main-header-notification .dropdown-menu {
	  top: 43px;
	}
  }

  @media (min-width: 992px) {
	.main-header-primary .main-header-notification .dropdown-menu {
	  top: 45px;
	}
  }

  @media (min-width: 576px) {
	.main-header-primary .main-profile-menu .dropdown-menu {
	  top: 46px;
	}
  }

  @media (min-width: 992px) {
	.main-header-primary .main-profile-menu .dropdown-menu {
	  top: 48px;
	}
  }

  .main-navbar-dashboard-eight {
	background-color: #f9f9f9;
	border-bottom-width: 0;

	.main-navbar-header {
	  background-color: #0040ff;
	  border-bottom-width: 0;
	  color: $white;

	  .main-logo {
		color: $white;
	  }
	}

	.nav-item.active {
	  &::before {
		border-bottom-color: #1a53ff;
	  }

	  .nav-link {
		color: #1a53ff;
	  }
	}

	.nav-link {
	  text-transform: capitalize;
	  font-weight: 500;
	  font-size: 14px;
	}

	.nav-sub:not(.nav-sub-mega) {
	  background-color: #f9f9f9;
	  border-width: 0;

	  &::before {
		content: '';
		position: absolute;
		top: -5px;
		left: -5px;
		right: -5px;
		height: 5px;
		background-color: #f9f9f9;
		z-index: 4;
	  }
	}

	.nav-sub-item {
	  > .nav-sub {
		left: 180px;

		&::before {
		  left: -5px;
		  top: -5px;
		  bottom: -5px;
		  right: auto;
		  width: 5px;
		  height: auto;
		}
	  }

	  &.active .nav-sub-link {
		color: $primary;
	  }
	}

	.nav-sub-link:hover {
	  color: $primary;
	}

	.nav-sub-mega {
	  .container, .container-fluid {
		background-color: #f9f9f9;
		border-width: 0;
	  }
	}
  }

  @media (min-width: 992px) {
	.main-navbar-dashboard-eight .nav-sub:not(.nav-sub-mega) {
	  box-shadow: 0 0 5px $background;
	}
  }

  @media (min-width: 992px) {
	.main-navbar-dashboard-eight .nav-sub-mega {
	  .container, .container-fluid {
		box-shadow: 0 5px 3px -3px $background;
	  }
	}
  }

  .main-content-dashboard-eight {
	padding-top: 25px;

	.main-content-title {
	  letter-spacing: -.8px;
	}

	.card {
	  border-width: 0;
	  border-radius: 2px;
	  box-shadow: 0 0 5px rgba(20, 17, 45, 0.1);
	}

	.card-title {
	  font-weight: 500;
	}
  }

  @media (max-width: 575px) {
	.main-content-dashboard-eight .container {
	  padding-left: 20px;
	  padding-right: 20px;
	}
  }

  /* ###### 8.9 Dashboard Nine  ###### */

  .main-body-dashboard-nine {
	background-color: $background;
  }

  @media (min-width: 992px) {
	.main-body-dashboard-nine .main-header .container-fluid {
	  padding-left: 20px;
	  padding-right: 20px;
	}
  }

  @media (min-width: 992px) {
	.main-body-dashboard-nine.main-sidebar-hide .main-header-dashboard-nine {
	  left: 0;
	}
  }

  .main-sidebar-indigo-dark {
	background-color: #4130c5;
	border-right-width: 0;

	.main-logo {
	  color: $white;
	  letter-spacing: -1.4px;
	}

	.main-sidebar-loggedin .media-body {
	  h6 {
		color: $white;
		font-weight: 500;
	  }

	  span {
		color: $white-5;
	  }
	}

	.main-img-user::after {
	  box-shadow: none;
	}

	.main-sidebar-body {
	  .nav-label {
		border-bottom-color: $white-2;
		color: rgba(255, 255, 255, 0.35);
		font-weight: 400;
	  }

	  .nav-item {
		+ .nav-item {
		  margin-top: -1px;

		  &::before {
			border-top-color: rgba(255, 255, 255, 0.12);
		  }
		}

		&.active {
		  position: relative;
		  z-index: 5;

		  &::before {
			content: '';
			position: absolute;
			top: -1px;
			left: -20px;
			right: -20px;
			background-color: #33269c;
			height: 41px;
		  }

		  .nav-link {
			font-weight: 500;
			color: $white;

			&:hover, &:focus {
			  font-weight: 500;
			  color: $white;
			}

			i, &:hover i, &:focus i {
			  color: $white;
			}
		  }
		}
	  }

	  .nav-link {
		color: rgba(255, 255, 255, 0.45);
		font-weight: 400;

		i {
		  color: rgba(255, 255, 255, 0.75);
		}

		&.with-sub::after {
		  opacity: .3;
		}

		&:hover, &:focus {
		  color: rgba(255, 255, 255, 0.75);
		}
	  }

	  .nav-sub {
		border-left-color: $white-1;
		padding-left: 0;
		padding-bottom: 0;
		position: relative;

		&::before {
		  content: '';
		  position: absolute;
		  top: 0;
		  left: -32px;
		  right: -20px;
		  bottom: 0;
		  background-color: $black-1;
		  z-index: 4;
		}
	  }

	  .nav-sub-item.active .nav-sub-link {
		color: $white;
	  }

	  .nav-sub-link {
		padding-left: 21px;
		color: $white-4;
		border-top-color: $white-1;
		position: relative;
		z-index: 5;

		&:hover, &:focus {
		  color: $white;
		}
	  }
	}
  }

  .main-header-dashboard-nine {
	background-color: $white;

	.main-header-menu-icon {
	  margin-right: 0;
	}
  }

  @media (min-width: 992px) {
	.main-header-dashboard-nine {
	  position: fixed;
	  top: 0;
	  left: 220px;
	  right: 0;
	  box-shadow: 0 0 15px rgba(20, 17, 45, 0.1);
	}
  }

  @media (min-width: 1200px) {
	.main-header-dashboard-nine {
	  left: 250px;
	}
  }

  .main-content-dashboard-nine {
	position: relative;

	.main-content-header {
	  display: block;
	  padding: 20px;
	  background-color: #ebedf2;

	  .nav {
		justify-content: flex-start;
	  }

	  .nav-link {
		color: #22252f;
		font-weight: 500;

		&:hover, &:focus {
		  color: #14112d;
		}

		&.active {
		  color: #005995;

		  &::before {
			top: 34px;
			bottom: auto;
			background-color: #005995;
		  }
		}

		+ .nav-link {
		  margin-top: 0;
		  margin-left: 25px;
		}

		&:last-child {
		  position: relative;

		  &::after {
			content: '';
			position: relative;
			display: inline-block;
			width: 25px;
		  }
		}
	  }
	}

	.main-content-header-top {
	  margin-bottom: 20px;
	}

	.main-content-title {
	  letter-spacing: -.7px;
	  font-size: 24px;
	}

	.main-content-body {
	  padding: 20px;
	}

	.card {
	  border-width: 0;
	  box-shadow: 0 2px 10px -1px rgba(8, 35, 171, 0.05);
	}
  }

  @media (min-width: 992px) {
	.main-content-dashboard-nine {
	  margin-top: 64px;
	}
  }

  @media (min-width: 992px) {
	.main-content-dashboard-nine .main-content-header {
	  padding: 20px 20px 15px;
	}
  }

  @media (max-width: 991.98px) {
	.main-content-dashboard-nine .main-content-header .nav-wrapper {
	  overflow: hidden;
	  width: 100%;
	  height: 20px;
	}
  }

  @media (max-width: 991.98px) {
	.main-content-dashboard-nine .main-content-header .nav {
	  flex-direction: row;
	  flex-wrap: nowrap;
	  overflow-x: auto;
	  width: calc(100vw - 20px);
	}
  }

  @media (max-width: 991.98px) {
	.main-content-dashboard-nine .main-content-header .nav-link {
	  white-space: nowrap;
	  padding-bottom: 10px;
	}
  }

  @media (min-width: 992px) {
	.main-content-dashboard-nine .main-content-header .nav-link {
	  &:nth-child(5), &:last-child {
		font-weight: 400;
	  }
	}
  }

  @media (min-width: 992px) {
	.main-content-dashboard-nine .main-content-header .nav-link:nth-child(5) {
	  margin-left: auto;
	}
  }

  @media (min-width: 768px) {
	.main-content-dashboard-nine .main-content-header .nav-link:last-child::after {
	  display: none;
	}
  }

  @media (min-width: 576px) {
	.main-content-dashboard-nine .main-content-header-top {
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	  margin-bottom: 20px;
	}
  }

  /* ###### 8.10 Dashboard Ten  ###### */

  .main-iconbar-primary {
	background-color: #0040ff;
	padding-top: 0;

	.main-img-user::after {
	  box-shadow: none;
	}

	.main-iconbar-logo {
	  background-color: #005995;
	  color: $white;
	  height: 64px;

	  &::after {
		display: none;
	  }
	}

	.nav {
	  justify-content: center;
	}

	.nav-link {
	  width: 42px;
	  height: 42px;
	  color: $white-5;
	  border-radius: 0px;
	  transition: all 0.2s ease-in-out;

	  &:hover, &:focus {
		color: $white;
		background-color: $white-1;
	  }

	  &.active {
		color: $white;
		background-color: $white-2;
	  }
	}

	.main-iconbar-help {
	  color: $white-5;

	  &:hover, &:focus {
		color: $white;
	  }
	}
  }

  @media (prefers-reduced-motion: reduce) {
	.main-iconbar-primary .nav-link {
	  transition: none;
	}
  }

  .main-iconbar-aside-primary {
	width: 250px;
	background-color: $background;
	box-shadow: 0 0 16px rgba(20, 17, 45, 0.08);
	border-right: 1px solid $gray-100;

	.main-iconbar-header {
	  padding: 18px 25px;
	  background-color: $white;
	  border-bottom: 1px solid $gray-100;
	}

	.main-iconbar-body {
	  padding: 20px 25px;

	  .nav {
		padding-top: 0;
		padding-left: 0;
		border-left-width: 0;
	  }

	  .nav-item + .nav-item, .nav-sub, .nav-sub-item + .nav-sub-item {
		border-top-color: $gray-100;
	  }

	  .nav-link {
		&:hover, &:focus, &.active {
		  color: $primary;
		}
	  }
	}

	.main-iconbar-toggle-menu {
	  display: none;
	}
  }

  @media (min-width: 992px) {
	.main-iconbar-aside-primary.show + .main-content {
	  margin-left: 64px;
	}
  }

  .main-content-dashboard-ten {
	padding: 0;
	min-height: 100vh;
	background-color: $background;

	.main-header {
	  position: fixed;
	  top: 0;
	  left: 0;
	  right: 0;
	  background-color: $white;
	}

	.main-header-center {
	  margin-left: 0;
	}

	.main-content-body {
	  margin-top: 64px;
	}

	.main-content-body-left {
	  flex: 1;
	  padding: 20px;
	}

	.main-content-body-right {
	  background-color: $background;
	  padding: 20px;
	  display: none;
	}
  }

  @media (min-width: 992px) {
	.main-content-dashboard-ten {
	  display: flex;
	  flex-direction: column;
	  justify-content: flex-start;
	  margin-left: 64px;
	}
  }

  @media (min-width: 992px) {
	.main-content-dashboard-ten .main-header {
	  left: 64px;
	}
  }

  @media (min-width: 992px) {
	.main-content-dashboard-ten .main-header .container-fluid {
	  padding-left: 20px;
	  padding-right: 20px;
	}
  }

  @media (min-width: 1200px) {
	.main-content-dashboard-ten .main-content-body {
	  display: flex;
	}
  }

  .main-content-body-right {
	background-color: $background;
	padding: 20px;
  }

  @media (min-width: 1200px) {
	.main-content-dashboard-ten .main-content-body-right {
	  display: block;
	  width: 280px;
	  box-shadow: 0 0 6px rgba(20, 17, 45, 0.1);
	}
  }

  @media (min-width: 1300px) {
	.main-content-dashboard-ten .main-content-body-right {
	  width: 300px;
	}
  }

  .main-content-dashboard-ten {
	.main-content-title {
	  letter-spacing: -.8px;
	}

	.card {
	  border-width: 0;
	  box-shadow: 0 0 6px $background;
	}

	.main-footer {
	  margin-top: auto;
	}
  }

/*--------panel----------*/

.expanel-default {
	border: #ddd !important;
  }
  
  .expanel-primary {
	border: $primary !important;
  }
  
  .expanel-secondary {
	border: #737688 !important;
  }
  
  .expanel-success {
	border: $success !important;
  }
  
  .expanel-danger {
	border: $danger !important;
  }
  
  .expanel-primary > .expanel-heading {
	color: $white !important;
	background-color: $primary !important;
	border-color: $primary !important;
  }
  
  .expanel-secondary > .expanel-heading {
	color: $white !important;
	background-color: #737688 !important;
	border-color: #737688 !important;
  }
  
  .expanel-success > .expanel-heading {
	color: $white !important;
	background-color: $success !important;
	border-color: $success !important;
  }
  
  .expanel-danger > .expanel-heading {
	color: $white !important;
	background-color: $danger  !important;
	border-color: $danger  !important;
  }
  
  .expanel-warning > .expanel-heading {
	color: $white !important;
	background-color: $warning !important;
	border-color: $warning !important;
  }
  
  .expanel-title {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	font-size: 16px !important;
	color: inherit !important;
  }
  
  .expanel {
	margin-bottom: 20px;
	background-color: $white !important;
	border: 1px solid $gray-100 !important;
	border-radius: 0px !important;
  }
  
  .expanel-default > .expanel-heading {
	background-color: $background !important;
	border-color: 1px solid $gray-100 !important;
  }
  
  .expanel-heading {
	padding: 10px 15px !important;
	border-bottom: 1px solid $gray-100;
	border-top-left-radius: 0px !important;
	border-top-right-radius: 0px !important;
  }
  
  .expanel-footer {
	padding: 10px 15px !important;
	background-color: $background !important;
	border-top: 1px solid $gray-100 !important;
	border-bottom-right-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
  }
  
  .expanel-body {
	padding: 15px !important;
  }
/*--- PAGINATION ---*/

.pagination {
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 0px;
  }

  .pagination-lg {
	.page-link {
	  padding: 0.75rem 1.5rem;
	  font-size: 1.09375rem;
	  line-height: 1.5;
	}

	.page-item {
	  &:first-child .page-link {
		border-top-left-radius: 0.3rem;
		border-bottom-left-radius: 0.3rem;
	  }

	  &:last-child .page-link {
		border-top-right-radius: 0.3rem;
		border-bottom-right-radius: 0.3rem;
	  }
	}
  }

  .pagination-sm {
	.page-link {
	  padding: 0.25rem 0.5rem;
	  font-size: 0.76562rem;
	  line-height: 1.5;
	}

	.page-item {
	  &:first-child .page-link {
		border-top-left-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	  }

	  &:last-child .page-link {
		border-top-right-radius: 0.2rem;
		border-bottom-right-radius: 0.2rem;
	  }
	}
  }

  .pagination-dark {
	.page-item.active .page-link {
	  background-color: $primary;
	  color: $white;
	}

	.page-link {
	  font-weight: 400;
	  background-color: $white-05;
	  color: $white-8;

	  &:hover, &:focus {
		color: $white;
		background-color: $white-08;
	  }
	}
  }

  .pagination-primary .page-item.active .page-link {
	background-color: $primary;
  }

  .pagination-success .page-item.active .page-link {
	background-color: $success;
	border-color: $success !important;
  }

  .pagination-circled {
	.page-item {
	  + .page-item {
		margin-left: 5px;
	  }

	  &:first-child .page-link, &:last-child .page-link {
		border-radius: 100%;
	  }
	}

	.page-link {
	  padding: 0;
	  width: 40px;
	  border-radius: 100%;
	}

	.active {
	  background-color: transparent !important;

	  .page-link {
		border-radius: 100%;
	  }
	}
  }

  .page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: $primary;
	background-color: $white;
	border: 1px solid $gray-100;

	&:hover {
	  z-index: 2;
	  color: #005995;
	  text-decoration: none;
	  background-color: $background;
	  border-color: $gray-100;
	}

	&:focus {
	  z-index: 2;
	  outline: 0;
	  box-shadow: 0 0 0 0.2rem rgba(104, 75, 219, 0.25);
	}
  }

  .page-item {
	&:first-child .page-link {
	  margin-left: 0;
	  border-top-left-radius: 0px;
	  border-bottom-left-radius: 0px;
	}

	&:last-child .page-link {
	  border-top-right-radius: 0px;
	  border-bottom-right-radius: 0px;
	}

	&.active .page-link {
	  z-index: 1;
	  color: $white;
	  background-color: $primary;
	  border-color: $primary;
	}

	&.disabled .page-link {
	  color: #737688;
	  pointer-events: none;
	  cursor: auto;
	  background-color: $white;
	  border-color: $gray-100;
	}

	&:first-child .page-link, &:last-child .page-link {
	  border-radius: 0;
	}

	+ .page-item {
	  margin-left: 1px;
	}

	&.active {
	  background-color: $primary !important;
	}
  }

  .page-link {
	color: #22252f;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 36px;
	height: 38px;
	border-width: 0;
	background-color: $background;
	margin-left: 0;
	font-weight: 500;

	i {
	  font-size: 18px;
	  line-height: 0;
	}

	&:hover, &:focus {
	  color: #14112d;
	  background-color: $background;
	}
  }

  .page {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 100vh;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 100vh;
  }

  .page-header {
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 20px !important;
	margin-bottom: 20px;
	padding: 1rem 0;
	border-radius: 0;
	border: 1px solid transparent;
  }

  .page-title {
	margin: 0;
	font-size: 18px;
	font-weight: 500;
  }

  .page-header .breadcrumb {
	padding: 0;
	background: none;

	.breadcrumb-item a {
	  color: #2f3542;
	}
  }

  .page-heaer-icon {
	width: 20px;
	height: 20px;
  }

  .page-header .breadcrumb .breadcrumb-item.active {
	color: $primary;
  }

  @media (max-width: 568px) {
	.page-header {
	  display: block;
	}

	.page-title {
	  margin-bottom: 5px;
	}
  }

  .page {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 100vh;
  }

  /*--- PAGINATION ---*/

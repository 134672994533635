/*--- JUMBOTRON ---*/

.jumbotron {
	padding: 2rem 1rem;
	margin-bottom: 2rem;
	background-color: $background;
	border-radius: 0.3rem;
  }
  
  @media (min-width: 576px) {
	.jumbotron {
	  padding: 4rem 2rem;
	}
  }
  
  .jumbotron-fluid {
	padding-right: 0;
	padding-left: 0;
	border-radius: 0;
  }
  
  /*--- JUMBOTRON ---*/
.br-theme-bars-horizontal .br-widget {
  a {
    &.br-active, &.br-selected {
      background-color: #005995 !important;
    }
  }

  .br-current-rating {
    color: #005995 !important;
  }
}

.br-theme-bars-pill .br-widget a {
  background-color: $background !important;

  &.br-active, &.br-selected {
    background-color: #005995 !important;
    color: white !important;
  }

  color: #005995 !important;
}

.br-theme-bars-square .br-widget a {
  &.br-active, &.br-selected {
    border: 2px solid #005995 !important;
    color: #005995 !important;
  }

  border: 2px solid rgba(107, 122, 144, 0.1) !important;
  background-color: $white !important;
  color: #005995 !important;
}

.br-theme-bars-movie .br-widget {
  a {
    &.br-active, &.br-selected {
      background-color: #005995 !important;
    }

    background-color: $background !important;
  }

  .br-current-rating {
    color: #005995 !important;
  }
}

.br-theme-bars-1to10 .br-widget {
  a {
    background-color: $background !important;

    &.br-active, &.br-selected {
      background-color: #005995 !important;
    }
  }

  .br-current-rating {
    color: #005995 !important;
  }
}

.br-theme-fontawesome-stars .br-widget a {
  &.br-active:after, &.br-selected:after {
    color: #005995 !important;
  }
}

.br-theme-fontawesome-stars-o .br-widget a {
  &.br-active:after, &.br-selected:after {
    content: '\f005';
    color: #005995 !important;
  }

  &.br-fractional:after {
    content: '\f123';
    color: #005995 !important;
  }
}

.br-theme-fontawesome-stars .br-widget a:after {
  content: '\f005';
  color: #ebedf5 !important;
}

.br-theme-fontawesome-stars-o .br-widget a:after {
  content: '\f006';
  color: #ebedf5 !important;
}

.main-rating-value {
  font-size: 40px;
  font-weight: 400;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #14112d;
  margin-bottom: 0;
  letter-spacing: -.5px;
  line-height: .7;
}

.main-rating-label {
  margin-bottom: 10px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: .5px;
}

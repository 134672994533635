.main-logo {
  img {
    max-width: 180px;
  }
}

.main-header {
  background-color: $primary;

  .horizontal-mainwrapper {
    .horizontalMenu {
      .horizontalMenu-list {
        background-color: $primary;

        li {
          a {
            color: $white;
            padding: 21px 15px !important;
          }

          a.active, a:hover {
            background-color: #005995;
            transition: 0.5s all;
          }
        }
      }
    }
  }
}

.hor-menu .horizontalMenu > .horizontalMenu-list > li:first-child, .hor-menu .horizontalMenu > .horizontalMenu-list > li:last-child, .horizontalMenu > .horizontalMenu-list > li > a {
  border-left: none;
  border-right: none;
}

.animated-arrow span, .animated-arrow span:after, .animated-arrow span:before {
  background-color: white;
}

.main-header-left {
  padding-bottom: 0 !important;
  padding-top: 8px;
}

.header-link:hover, .header-link:active, .header-icons:hover {
  color: $white !important;
  fill: $white !important;
}

.toggle-sidebar {
  box-shadow: 5px 5px 20px 3px #1c2d5b;
  margin-left: 10px;
  display: none;
}

.login_buttons {
  display: flex;

  a {
    color: $white !important;
    margin-left: 10px;
  }
}

.lds-ring {
  margin: 0 !important;
  width: 30px;
  height: 30px;

  div {
    width: 30px;
    height: 30px;
  }
}


.custom-selector {
  width: 100%;
  position: relative;

  button {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;

    &::after {
      margin-left: auto;
      display: inline-block;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      width: 0;
      height: 0;
    }
  }

  .selector-list {
    width: 100%;
    background-color: white;
    z-index: 999 !important;
    overflow: scroll;
    max-height: 200px;
    position: absolute;
    top: 40px;
    left: 0;
    display: none;

    &.show {
      display: block;
    }

    .list-item {
      display: block;
      width: 100%;
      padding: 6px 18px;
      border-bottom: 1px solid #eee;
      color: $black;
    }

    a.list-item:hover, a.selected {
      background-color: $primary;
      color: $white;
    }
  }
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 10px !important;
}

.tags-selector .select2-container {
  width: 100% !important;

  .select2-selection {
    border-radius: 5px !important;
  }
}

textarea.form-control {
  height: 100px;
}

.select2-container {
  display: block;
}

.btn-icon.rounded {
  border-radius: 100% !important;
  width: 30px !important;
  height: 30px !important;
  padding: 7px !important;
}


.custom-file-upload {
  label.file_name_label {
    border: 1px solid #e9edf4;
    width: 80%;
    padding: 7px 10px;
    color: #737688;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  label.browse_file_label {
    min-width: 20%;
    border: 1px solid $primary;
    background-color: $primary;
    color: white;
    padding: 7px 10px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: #005995;
      border-color: #005995
    }
  }
}

.browse_image_label {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.card-img-top {
  height: 250px;
}

.image-with-overlay {
  .image-overlay {
    position: absolute;
    background-color: rgb(0 0 0 / 70%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 5px;

    .image-overlay-content {
      display: flex;
      color: #FFF;
      height: 100%;
      justify-content: space-between;
      padding: 20px;
      align-items: center;
    }
  }

  &:hover {
    .image-overlay {


      .image-overlay-content {

      }
    }
  }
}


.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}

.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.page-link:focus {
  box-shadow: none !important;
}

.container-loader {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

ul.pagination {
  justify-content: center;
}

.icon-loading-button {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  justify-content: space-between;

  .dimmer {
    margin-top: -13px;
    margin-right: 13px;
  }

  .lds-ring {
    width: 19px;
    height: 19px;

    div {
      width: 19px;
      height: 19px;
    }
  }
}

.lds-ring.light div {
  border-color: #ffffff transparent transparent transparent;
}


.image-placeholder {
  width: 100%;
  height: 250px;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: rgb(0 0 0 / 78%);

  .modal-body {
    background-color: #f3f7fd;
  }
}

.MuiSlider-colorPrimary {
  color: $primary !important;
}

.slide-show-images-container {
  .slide-show-images {
    width: 100% !important;
    height: 100% !important;
  }
}

.sortable-icon svg {
  cursor: move;
}

.wd-350 {
  max-width: 350px !important;
  min-width: 350px !important;
  width: 350px !important;
}

.dropdown-select {

  .dropdown-select-btn-group {
    border: 1px solid #e9edf4;
    border-radius: 0.3rem;
    color: #686868;

    button.dropdown-toggle {
      color: #686868;
      padding-right: 0.3rem;
    }
  }

  .dropdown-select-search {
    .search-icon {
      position: absolute;
      top: 10px;
      left: 10px;
      stroke: #666;
    }

    input {
      padding-left: 35px;
    }

    .browse-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      stroke: #666;

      &:hover {
        cursor: pointer;
        stroke: black;
      }
    }
  }
}

.badge-with-remove-btn {
  padding: 0 0 0 0.375rem;
  margin-bottom: 3px;

  button {
    background-color: transparent;
    border: 0;
    color: white !important;
    transition: 0.3s all;
    border-top-right-radius: 10rem;
    border-bottom-right-radius: 10rem;
    padding-right: 0.375rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;

    &:hover {
      background-color: $danger;
      transition: 0.3s all;
    }
  }
}

.modal-fullscreen {
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0;

  .modal-content {
    max-height: 100% !important;
    height: 100% !important;
  }
}


body:has(.modal.show) {
  overflow: hidden;
}

.badge .close {
  margin-left: 7px;
  font-size: 12px;
}

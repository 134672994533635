/*--- BACKGROUNDS ---*/

.bg-transparent {
	background-color: transparent;
  }
  
  .bg-gray-100 {
	background-color: $gray-100;
  }
  
  .bg-gray-200 {
	background-color: $gray-200;
  }
  
  .bg-gray-300 {
	background-color: $gray-300;
  }
  
  .bg-gray-400 {
	background-color: $gray-400;
  }
  
  .bg-gray-500 {
	background-color: $gray-500;
  }
  
  .bg-gray-600 {
	background-color: $gray-600;
  }
  
  .bg-gray-700 {
	background-color: $gray-700;
  }
  
  .bg-gray-800 {
	background-color: $gray-800;
  }
  
  .bg-gray-900 {
	background-color: $gray-900;
  }
  
  .bg-white-1 {
	background-color: $white-1;
  }
  
  .bg-white-2 {
	background-color: $white-2;
  }
  
  .bg-white-3 {
	background-color: $white-3;
  }
  
  .bg-white-4 {
	background-color: $white-4;
  }
  
  .bg-white-5 {
	background-color: $white-5;
  }
  
  .bg-white-6 {
	background-color: $white-6;
  }
  
  .bg-white-7 {
	background-color: $white-7;
  }
  
  .bg-white-8 {
	background-color: $white-8;
  }
  
  .bg-white-9 {
	background-color: $white-9;
  }
  
  .bg-black-1 {
	background-color: $black-1;
  }
  
  .bg-black-2 {
	background-color: $black-2;
  }
  
  .bg-black-3 {
	background-color: $black-3;
  }
  
  .bg-black-4 {
	background-color: $black-4;
  }
  
  .bg-black-5 {
	background-color: $black-5;
  }
  
  .bg-black-6 {
	background-color: $black-6;
  }
  
  .bg-black-7 {
	background-color: $black-7;
  }
  
  .bg-black-8 {
	background-color: $black-8;
  }
  
  .bg-black-9 {
	background-color: $black-9;
  }
  
  .bg-indigo {
	background-color: $indigo !important;
  }
  
  .bg-purple {
	background-color: $purple !important;
  }
  
  .bg-pink {
	background-color: $pink !important;
  }
  
  .bg-orange {
	background-color: #fd7e14;
  }
  
  .bg-teal {
	background-color: $teal;
  }
  
  .bg-purple-dark {
	background-color: #59339d;
  }
  
  .bg-orange {
	background-color: $orange !important;
  }
  
  .bg-orange-transparent {
	background-color: rgba(244, 123, 37, 0.15);
  }
  
  .bg-pink-transparent {
	background-color: rgba(241, 0, 117, 0.11);
  }
  
  .bg-teal-transparent {
	background-color: rgba(0, 204, 204, 0.11);
  }
  
  /*--- BACKGROUNDS ---*/
  
  /* Graident Colors */
  
  .bg-gradient-primary {
	background: linear-gradient(45deg, #6E71E6 15%, $primary 85%);
  }
  
  .bg-gradient-secondary {
	background: linear-gradient(45deg, #f76e9c 15%, $secondary 85%);
  }
  
  .bg-gradient-success {
	background: linear-gradient(45deg, #3efdcd 15%, $success 85%);
  }
  
  .bg-gradient-danger {
	background: linear-gradient(45deg, #ff706b 15%, $danger 85%);
  }
  
  .bg-gradient-warning {
	background: linear-gradient(45deg, #f9c362 15%, $warning 85%);
  }
  
  .bg-gradient-info {
	background: linear-gradient(45deg, #81d9fb 15%, $info 85%);
  }
  
  .bg-gradient-teal {
	background: linear-gradient(45deg, #08eaea 15%, $teal 85%);
  }
  
  .bg-gradient-pink {
	background: linear-gradient(45deg, #fb68b0 15%, $pink 85%);
  }
  
  .bg-gradient-orange {
	background: linear-gradient(45deg, #fb8b61 15%, $orange 85%);
  }
  
  /* Graident Colors */
  
  .bg-primary {
	background-color: $primary !important;
  }
  
  a.bg-primary {
	&:hover, &:focus {
	  background-color: #0040ff !important;
	}
  }
  
  button.bg-primary {
	&:hover, &:focus {
	  background-color: #0040ff !important;
	}
  }
  
  .bg-secondary {
	background-color: $secondary !important;
  }
  
  a.bg-secondary {
	&:hover, &:focus {
	  background-color: #ef6591 !important;
	}
  }
  
  button.bg-secondary {
	&:hover, &:focus {
	  background-color: #ef6591 !important;
	}
  }
  
  .bg-success {
	background-color: $success !important;
  }
  
  a.bg-success {
	&:hover, &:focus {
	  background-color: #4bd2b0 !important;
	}
  }
  
  button.bg-success {
	&:hover, &:focus {
	  background-color: #4bd2b0 !important;
	}
  }
  
  .bg-info {
	background-color: $info !important;
  }
  
  a.bg-info {
	&:hover, &:focus {
	  background-color: #117a8b !important;
	}
  }
  
  button.bg-info {
	&:hover, &:focus {
	  background-color: #117a8b !important;
	}
  }
  
  .bg-warning {
	background-color: $warning !important;
  }
  
  a.bg-warning {
	&:hover, &:focus {
	  background-color: #ef9c05 !important;
	}
  }
  
  button.bg-warning {
	&:hover, &:focus {
	  background-color: #ef9c05 !important;
	}
  }
  
  .bg-danger {
	background-color: $danger !important;
  }
  
  a.bg-danger {
	&:hover, &:focus {
	  background-color: #ef4352 !important;
	}
  }
  
  button.bg-danger {
	&:hover, &:focus {
	  background-color: #ef4352 !important;
	}
  }
  
  .bg-light {
	background-color: $background  !important;
  }
  
  a.bg-light {
	&:hover, &:focus {
	  background-color: #f1f5fb !important;
	}
  }
  
  button.bg-light {
	&:hover, &:focus {
	  background-color: #f1f5fb !important;
	}
  }
  
  .bg-dark {
	background-color: $dark !important;
  }
  
  a.bg-dark {
	&:hover, &:focus {
	  background-color: #0e0c13 !important;
	}
  }
  
  button.bg-dark {
	&:hover, &:focus {
	  background-color: #0e0c13 !important;
	}
  }
  
  .bg-green {
	background-color: #26eda2 !important;
  }
  
  a.bg-green {
	&:hover, &:focus {
	  background-color: #14b97b !important;
	}
  }
  
  button.bg-green {
	&:hover, &:focus {
	  background-color: #14b97b !important;
	}
  }
  
  .bg-white {
	background-color: $white !important;
  }
  
  .bg-transparent {
	background-color: transparent !important;
  }
  
  .bg-primary-transparent {
	background-color: rgba(76, 132, 255, 0.15);
  }
  
  .bg-secondary-transparent {
	background-color: rgba(255, 187, 0, 0.15);
  }
  
  .bg-success-transparent {
	background-color: rgba(78, 220, 184, 0.15);
  }
  
  .bg-info-transparent {
	background-color: rgba(61, 180, 236, 0.15);
  }
  
  .bg-warning-transparent {
	background-color: rgba(255, 193, 7, 0.15);
  }
  
  .bg-danger-transparent {
	background-color: rgba(220, 53, 69, 0.15);
  }
  
  .bg-dark-transparent {
	background-color: rgba(75, 66, 97, 0.15);
  }
  
  .bg-purple-transparent {
	background-color: rgba(133, 0, 255, 0.15);
  }
  
  .bg-pink-transparent {
	background-color: rgba(241, 0, 117, 0.15);
  }
  
  .bg-primary-dark {
	background-color: #0033cc !important;
  }
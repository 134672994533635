/*--demo gallery--*/

.demo-gallery > ul {
	margin-bottom: 0;
  
	> li {
	  float: left;
	  margin-bottom: 20px;
	  width: 200px;
	  border: 0;
	}
  }
  
  @media (max-width: 768px) {
	.demo-gallery > ul > li {
	  width: 100%;
	}
  }
  
  .gallery-search .form-control {
	padding: 0.440rem 1.75rem;
  }
  
  .demo-gallery {
	> ul > li a {
	  border-radius: 0px;
	  display: block;
	  overflow: hidden;
	  position: relative;
	  float: left;
	  height: 100%;
	  width: 100%;
  
	  > img {
		opacity: 1;
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
	  }
  
	  &:hover {
		> img, .demo-gallery-poster > img {
		  opacity: .5;
		}
	  }
  
	  .demo-gallery-poster {
		background-color: $black-1;
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		-webkit-transition: background-color 0.15s ease 0s;
		-o-transition: background-color 0.15s ease 0s;
		transition: background-color 0.15s ease 0s;
  
		> img {
		  left: 50%;
		  margin-left: -10px;
		  margin-top: -10px;
		  opacity: 0;
		  position: absolute;
		  top: 50%;
		  -webkit-transition: opacity 0.3s ease 0s;
		  -o-transition: opacity 0.3s ease 0s;
		  transition: opacity 0.3s ease 0s;
		}
	  }
  
	  &:hover .demo-gallery-poster {
		background-color: $black-5;
	  }
	}
  
	.justified-gallery > a {
	  > img {
		-webkit-transition: -webkit-transform 0.15s ease 0s;
		-moz-transition: -moz-transform 0.15s ease 0s;
		-o-transition: -o-transform 0.15s ease 0s;
		transition: transform 0.15s ease 0s;
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
		height: 100%;
		width: 100%;
	  }
  
	  &:hover {
		> img {
		  -webkit-transform: scale3d(1.1, 1.1, 1.1);
		  transform: scale3d(1.1, 1.1, 1.1);
		}
  
		.demo-gallery-poster > img {
		  opacity: 1;
		}
	  }
  
	  .demo-gallery-poster {
		background-color: $black-1;
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		-webkit-transition: background-color 0.15s ease 0s;
		-o-transition: background-color 0.15s ease 0s;
		transition: background-color 0.15s ease 0s;
  
		> img {
		  left: 50%;
		  margin-left: -10px;
		  margin-top: -10px;
		  opacity: 0;
		  position: absolute;
		  top: 50%;
		  -webkit-transition: opacity 0.3s ease 0s;
		  -o-transition: opacity 0.3s ease 0s;
		  transition: opacity 0.3s ease 0s;
		}
	  }
  
	  &:hover .demo-gallery-poster {
		background-color: $black-5;
	  }
	}
  
	.video .demo-gallery-poster img {
	  height: 48px;
	  margin-left: -24px;
	  margin-top: -24px;
	  opacity: 0.8;
	  width: 48px;
	}
  
	&.dark > ul > li a {
	  border: 3px solid #04070a;
	}
  }
  
  .home .demo-gallery {
	padding-bottom: 80px;
  }
  
  /*--demo gallery--*/
/*--- TABLE STYLES ---*/

.table {
	width: 100%;
	margin-bottom: 1rem;
	color: #22252f;
  
	th, td {
	  padding: 0.75rem;
	  vertical-align: middle;
	  border-top: 1px solid $gray-100;
	}
  
	thead th {
	  vertical-align: bottom;
	  border-bottom: 2px solid $gray-100;
	}
  
	tbody + tbody {
	  border-top: 2px solid $background;
	}
  }
  
  .table-sm {
	th, td {
	  padding: 0.3rem;
	}
  }
  
  .table-bordered {
	border: 1px solid $gray-100;
  
	th, td {
	  border: 1px solid $gray-100;
	}
  
	thead {
	  th, td {
		border-bottom-width: 0px;
	  }
	}
  }
  
  .table-borderless {
	th, td, thead th, tbody + tbody {
	  border: 0;
	}
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
	background-color: $black-05;
  }
  
  .table-hover tbody tr:hover {
	color: #22252f;
	background-color: $background;
  }
  
  .table-primary {
	background-color: #c6d4ff;
  
	> {
	  th, td {
		background-color: #c6d4ff;
	  }
	}
  
	th, td, thead th, tbody + tbody {
	  border-color: #95afff;
	}
  }
  
  .table-hover .table-primary:hover {
	background-color: #adc1ff;
  
	> {
	  td, th {
		background-color: #adc1ff;
	  }
	}
  }
  
  .table-secondary {
	background-color: #d9dde5;
  
	> {
	  th, td {
		background-color: #d9dde5;
	  }
	}
  
	th, td, thead th, tbody + tbody {
	  border-color: #b9c1ce;
	}
  }
  
  .table-hover .table-secondary:hover {
	background-color: #cacfdb;
  
	> {
	  td, th {
		background-color: #cacfdb;
	  }
	}
  }
  
  .table-success {
	background-color: #c8e9b8;
  
	> {
	  th, td {
		background-color: #c8e9b8;
	  }
	}
  
	th, td, thead th, tbody + tbody {
	  border-color: #99d67b;
	}
  }
  
  .table-hover .table-success:hover {
	background-color: #b9e3a5;
  
	> {
	  td, th {
		background-color: #b9e3a5;
	  }
	}
  }
  
  .table-info {
	background-color: #bee5eb;
  
	> {
	  th, td {
		background-color: #bee5eb;
	  }
	}
  
	th, td, thead th, tbody + tbody {
	  border-color: #86cfda;
	}
  }
  
  .table-hover .table-info:hover {
	background-color: #abdde5;
  
	> {
	  td, th {
		background-color: #abdde5;
	  }
	}
  }
  
  .table-warning {
	background-color: #ffeeba;
  
	> {
	  th, td {
		background-color: #ffeeba;
	  }
	}
  
	th, td, thead th, tbody + tbody {
	  border-color: #ffdf7e;
	}
  }
  
  .table-hover .table-warning:hover {
	background-color: #ffe8a1;
  
	> {
	  td, th {
		background-color: #ffe8a1;
	  }
	}
  }
  
  .table-danger {
	background-color: #f5c6cb;
  
	> {
	  th, td {
		background-color: #f5c6cb;
	  }
	}
  
	th, td, thead th, tbody + tbody {
	  border-color: #ed969e;
	}
  }
  
  .table-hover .table-danger:hover {
	background-color: #f1b0b7;
  
	> {
	  td, th {
		background-color: #f1b0b7;
	  }
	}
  }
  
  .table-light {
	background-color: #fcfcfd;
  
	> {
	  th, td {
		background-color: #fcfcfd;
	  }
	}
  
	th, td, thead th, tbody + tbody {
	  border-color: #f9fafb;
	}
  }
  
  .table-hover .table-light:hover {
	background-color: #ededf3;
  
	> {
	  td, th {
		background-color: #ededf3;
	  }
	}
  }
  
  .table-dark {
	background-color: #c8ccd3;
  
	> {
	  th, td {
		background-color: #c8ccd3;
	  }
	}
  
	th, td, thead th, tbody + tbody {
	  border-color: #99a0ae;
	}
  }
  
  .table-hover .table-dark:hover {
	background-color: #babfc8;
  
	> {
	  td, th {
		background-color: #babfc8;
	  }
	}
  }
  
  .table-active {
	background-color: $background;
  
	> {
	  th, td {
		background-color: $background;
	  }
	}
  }
  
  .table-hover .table-active:hover {
	background-color: $background;
  
	> {
	  td, th {
		background-color: $background;
	  }
	}
  }
  
  .table {
	.thead-dark th {
	  color: $white;
	  background-color: $dark;
	  border-color: #49597b;
	}
  
	.thead-light th {
	  color: #22252f;
	  background-color: $background;
	  border-color: $gray-100;
	}
  }
  
  .table-dark {
	color: $white;
	background-color: $dark;
  
	th, td, thead th {
	  border-color: #49597b;
	}
  
	&.table-bordered {
	  border: 0;
	}
  
	&.table-striped tbody tr:nth-of-type(odd) {
	  background-color: $white-05;
	}
  
	&.table-hover tbody tr:hover {
	  color: $white;
	  background-color: $white-75;
	}
  }
  
  @media (max-width: 575.98px) {
	.table-responsive-sm {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
  
	  > .table-bordered {
		border: 0;
	  }
	}
  }
  
  @media (max-width: 767.98px) {
	.table-responsive-md {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
  
	  > .table-bordered {
		border: 0;
	  }
	}
  }
  
  @media (max-width: 991.98px) {
	.table-responsive-lg {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
  
	  > .table-bordered {
		border: 0;
	  }
	}
  }
  
  @media (max-width: 1199.98px) {
	.table-responsive-xl {
	  display: block;
	  width: 100%;
	  overflow-x: auto;
	  -webkit-overflow-scrolling: touch;
  
	  > .table-bordered {
		border: 0;
	  }
	}
  }
  
  .table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
  
	> .table-bordered {
	  border: 0;
	}
  }
  
  table.dataTable tbody tr.selected {
	background-color: $background;
  }
  
  .table {
	color: #2f3542;
  
	thead {
	  th, td {
		color: #14112d;
		font-weight: 700;
		font-size: 11px;
		letter-spacing: .5px;
		text-transform: uppercase;
		border-bottom-width: 1px;
		border-top-width: 0;
	  }
	}
  
	tbody tr {
	  background-color: $white-5;
  
	  th {
		font-weight: 500;
	  }
	}
  
	th, td {
	  padding: 9px 15px;
	  line-height: 1.462;
	  border-right: 1px solid $gray-100;
	}
  }
  
  .table-striped tbody tr:nth-of-type(2n+1) {
	background-color: $background;
  }
  
  .table-bordered thead {
	th, td {
	  border-top-width: 1px;
	  padding-top: 7px;
	  padding-bottom: 7px;
	  background-color: $white-5;
	}
  }
  
  /*DATA TABLE*/
  
  table.dataTable {
	border: 1px solid $gray-100;
  
	&.no-footer {
	  border-bottom-color: $gray-100;
	}
  
	thead {
	  th, td {
		border-top-width: 0;
		border-bottom-width: 0;
		padding: 8px 10px;
		font-weight: 700;
		font-size: 12px;
		color: #14112d;
	  }
  
	  .sorting_asc, .sorting_desc {
		background-image: none;
		position: relative;
	  }
	}
  
	&.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child::before {
	  top: 9.5px;
	  left: 7px;
	  width: 16px;
	  height: 16px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  border-width: 0;
	  box-shadow: none;
	  background-color: $primary;
	  font-size: 14px;
	  font-weight: 700;
	  line-height: 17px;
	  color: #ffffff;
	}
  
	> tbody > tr.child {
	  ul.dtr-details {
		display: block;
	  }
  
	  span.dtr-title {
		font-weight: 500;
	  }
	}
  }
  
  .dataTables_wrapper {
	.page-link {
	  width: auto;
	  height: auto;
	  padding: 0;
	  font-size: 12px;
	  background: transparent !important;
	  margin-top: 2px;
	}
  
	.dataTables_length {
	  text-align: left;
  
	  label {
		display: block;
		margin-bottom: 15px;
	  }
  
	  .select2-container--default {
		margin-right: 5px;
  
		.select2-selection--single {
		  height: 32px;
		  border-color: $gray-100;
		  border-radius: 0px;
  
		  .select2-selection__rendered {
			line-height: 32px;
		  }
  
		  .select2-selection__arrow {
			height: 30px;
  
			b {
			  border-top-color: #737688;
			}
		  }
		}
	  }
	}
  
	.dataTables_filter {
	  text-align: left;
  
	  label {
		display: block;
		margin-bottom: 15px;
	  }
  
	  input {
		margin-left: 0;
		border: 1px solid $gray-100;
		padding: 5px 10px;
		line-height: 1.539;
		color: #22252f;
		border-radius: 0px;
		width: 100%;
  
		&::placeholder {
		  color: #737688;
		}
	  }
	}
  
	.dataTables_info {
	  margin-top: 15px;
	  padding: 5px 0;
	  text-align: left;
	}
  
	.dataTables_paginate {
	  padding-top: 0;
	  margin-top: 15px;
	  text-align: left;
  
	  .paginate_button {
		background-color: $background;
		padding: 5px 10px;
		margin: 0;
		border: 0;
		border-radius: 1px;
		transition: all 0.2s ease-in-out;
  
		+ .paginate_button {
		  margin-left: 3px;
		}
  
		&.disabled {
		  background-color: #eceff3;
		  color: $background !important;
  
		  &:hover, &:focus {
			background-color: #eceff3;
			color: $background !important;
		  }
		}
  
		&.previous {
		  margin-right: 3px;
		}
  
		&.next {
		  margin-left: 3px;
		}
  
		&:hover, &:focus {
		  border: 0;
		  background-image: none;
		  background-color: $background;
		  color: #22252f !important;
		}
  
		&.current {
		  border: 0;
		  background-image: none;
		  background-color: $primary;
		  color: $white !important;
  
		  &:hover, &:focus {
			border: 0;
			background-image: none;
			background-color: $primary;
			color: $white !important;
		  }
		}
	  }
	}
  }
  
  @media (min-width: 576px) {
	.dataTables_wrapper .dataTables_length {
	  float: left;
	}
  }
  
  @media (min-width: 576px) {
	.dataTables_wrapper .dataTables_filter {
	  float: right;
	  margin-top: 0;
	}
  }
  
  @media (min-width: 576px) {
	.dataTables_wrapper .dataTables_filter input {
	  width: auto;
	}
  }
  
  @media (min-width: 576px) {
	.dataTables_wrapper .dataTables_info {
	  float: left;
	}
  }
  
  @media (min-width: 576px) {
	.dataTables_wrapper .dataTables_paginate {
	  float: right;
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.dataTables_wrapper .dataTables_paginate .paginate_button {
	  transition: none;
	}
  }
  
  table.dataTable tfoot {
	th, td {
	  border-top: 1px solid $background;
	}
  }
  
  /*DATA TABLE*/
  /*--- TABLE STYLES ---*/
  
  .main-table-reference {
	margin-top: 40px;
	background-color: $white;
  
	> {
	  thead > tr > {
		th, td {
		  padding: 10px 15px;
		  border: 1px solid $gray-100;
		  font-size: 13px;
		}
	  }
  
	  tbody > tr > {
		th, td {
		  padding: 10px 15px;
		  border: 1px solid $gray-100;
		  font-size: 13px;
		}
	  }
  
	  thead > tr > {
		th, td {
		  background-color: $background;
		  color: #14112d;
		  font-size: 12px;
		  font-weight: 700;
		  text-transform: uppercase;
		  letter-spacing: 1px;
		  border-bottom: 0;
		}
	  }
	}
  }
/*--- CAROUSEL ---*/

.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 15;
	display: flex;
	justify-content: center;
	padding-left: 0;
	margin-right: 15%;
	margin-left: 15%;
	list-style: none;
  
	li {
	  box-sizing: content-box;
	  flex: 0 1 auto;
	  width: 30px;
	  height: 3px;
	  margin-right: 3px;
	  margin-left: 3px;
	  text-indent: -999px;
	  cursor: pointer;
	  background-color: $white;
	  background-clip: padding-box;
	  border-top: 10px solid transparent;
	  border-bottom: 10px solid transparent;
	  opacity: .5;
	  transition: opacity 0.6s ease;
	}
  
	.active {
	  opacity: 1;
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.carousel-indicators li {
	  transition: none;
	}
  }
  
  .carousel-caption {
	position: absolute;
	right: 15%;
	bottom: 20px;
	top: 40%;
	left: 15%;
	z-index: 10;
	padding-top: 20px;
	padding-bottom: 20px;
	color: $white;
	text-align: center;
  }
  
  .carousel-control-prev-icon, .carousel-control-next-icon {
	background-image: none;
	display: block;
	width: auto;
	height: auto;
  }
  
  .carousel-control-prev-icon svg, .carousel-control-next-icon svg {
	width: 48px;
	height: 48px;
  }
  
  .carousel-indicators li {
	width: 10px;
	height: 10px;
	border-radius: 100%;
  }
  
  /*--- CAROUSEL ---*/
/*--- RANGE SLIDER ---*/

.irs-min, .irs-max {
	font-family: "Roboto", sans-serif;
	border-radius: 0;
  }
  
  .irs-from, .irs-to, .irs-single {
	font-family: 'Roboto', sans-serif;
	background-color: $primary;
	border-radius: 0;
  }
  
  .irs-bar {
	background-image: none;
	background-color: $primary;
  }
  
  .irs-bar-edge {
	background-image: none;
	background-color: $primary;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
  }
  
  .irs-line-left {
	background-image: none;
	background-color: $background;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
  }
  
  .irs-line-mid {
	background-image: none;
	background-color: $background;
  }
  
  .irs-line-right {
	background-image: none;
	background-color: $background;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
  }
  
  .irs-from::after, .irs-to::after, .irs-single::after {
	border-top-color: $primary;
  }
  
  .irs-slider {
	background-image: none;
  
	&:before {
	  content: '';
	  width: 2px;
	  height: 18px;
	  position: absolute;
	  background-color: $primary;
	  left: 50%;
	  margin-left: -1px;
	}
  
	&:hover:before, &:focus:before {
	  background-color: $primary;
	}
  }
  
  /* #################### COLOR VARIANT SKINS #################### */
  
  .irs-primary {
	.irs-bar, .irs-bar-edge, .irs-slider::before, .irs-from, .irs-to, .irs-single {
	  background-color: $primary;
	}
  
	.irs-from::after, .irs-to::after, .irs-single::after {
	  border-top-color: $primary;
	}
  }
  
  .irs-success {
	.irs-bar, .irs-bar-edge, .irs-slider::before, .irs-from, .irs-to, .irs-single {
	  background-color: $success;
	}
  
	.irs-from::after, .irs-to::after, .irs-single::after {
	  border-top-color: $success;
	}
  }
  
  .irs-dark {
	.irs-bar, .irs-bar-edge, .irs-slider::before, .irs-from, .irs-to, .irs-single {
	  background-color: $dark;
	}
  
	.irs-from::after, .irs-to::after, .irs-single::after {
	  border-top-color: $dark;
	}
  }
  
  /* #################### MODERN SKIN #################### */
  
  .irs-modern {
	.irs-line-left, .irs-line-mid, .irs-line-right {
	  height: 6px;
	  box-shadow: inset -2px 2px 2px 0 rgba(34, 34, 34, 0.1);
	}
  
	.irs-bar, .irs-bar-edge {
	  height: 6px;
	  box-shadow: inset 0 1px 1px 0 rgba(34, 34, 34, 0.1);
	}
  
	.irs-slider {
	  top: 19px;
	  background-color: $white;
	  border: 1px solid $primary;
	  border-radius: 0;
  
	  &::before {
		width: 4px;
		height: 9px;
		background-color: $white;
		border-left: 1px solid $primary;
		border-right: 1px solid $primary;
		margin-left: -2px;
		top: 3px;
	  }
	}
  
	&.irs-primary .irs-slider {
	  border-color: $primary;
  
	  &::before {
		border-color: $primary;
	  }
	}
  
	&.irs-success .irs-slider {
	  border-color: $success;
  
	  &::before {
		border-color: $success;
	  }
	}
  
	&.irs-dark .irs-slider {
	  border-color: #14112d;
  
	  &::before {
		border-color: #14112d;
	  }
	}
  }
  
  /* #################### OUTLINE SKIN #################### */
  
  .irs-outline {
	.irs-line {
	  border: 1px solid $primary;
	  height: 10px;
	  border-radius: 5px;
	}
  
	.irs-line-left, .irs-line-mid, .irs-line-right {
	  height: 10px;
	  background-color: transparent;
	}
  
	.irs-bar {
	  height: 10px;
	}
  
	.irs-bar-edge {
	  height: 10px;
	  border-top-left-radius: 5px;
	  border-bottom-left-radius: 5px;
	}
  
	.irs-slider {
	  top: 21px;
	  width: 18px;
	  background-color: $white;
	  border: 1px solid $primary;
	  border-radius: 100%;
  
	  &::before {
		margin-left: -4px;
		top: 4px;
		width: 8px;
		height: 8px;
		border-radius: 100%;
	  }
  
	  &:hover::before, &:focus::before {
		background-color: $primary;
	  }
	}
  
	&.irs-primary {
	  .irs-line, .irs-slider {
		border-color: $primary;
	  }
  
	  .irs-line {
		&:hover::before, &:focus::before {
		  background-color: $primary;
		}
	  }
  
	  .irs-slider {
		&:hover::before, &:focus::before {
		  background-color: $primary;
		}
	  }
	}
  
	&.irs-success {
	  .irs-line, .irs-slider {
		border-color: $success;
	  }
  
	  .irs-line {
		&:hover::before, &:focus::before {
		  background-color: #339701;
		}
	  }
  
	  .irs-slider {
		&:hover::before, &:focus::before {
		  background-color: #339701;
		}
	  }
	}
  
	&.irs-dark {
	  .irs-line, .irs-slider {
		border-color: $dark;
	  }
  
	  .irs-line {
		&:hover::before, &:focus::before {
		  background-color: #313c53;
		}
	  }
  
	  .irs-slider {
		&:hover::before, &:focus::before {
		  background-color: #313c53;
		}
	  }
	}
  }
  
  /*--- RANGE SLIDER ---*/
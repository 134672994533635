:root {
	--blue:#4c84ff;
	--indigo:#4c84ff;
	--purple:#8c3feb;
	--pink:#ff2b93;
	--red:#ff5c77;
	--orange:#fd7e14;
	--yellow:#fcb32d;
	--green:#28c76f;
	--teal:#00cccc;
	--cyan:#2dc3fc;
	--white:#fff;
	--gray:#737688;
	--gray-dark:#191621;
	--primary:#4c84ff;
	--secondary:#737688;
	--success:#28c76f;
	--info:#2dc3fc;
	--warning:#fcb32d;
	--danger:#ff5c77;
	--light:#f3f7fd;
	--dark:#191621;
	--breakpoint-xs:0;
	--breakpoint-sm:576px;
	--breakpoint-md:768px;
	--breakpoint-lg:992px;
	--breakpoint-xl:1200px;
	--font-family-sans-serif:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--font-family-monospace:SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  .toast {
	background-color: rgba(255, 255, 255, 0.95);
	border-color: rgba(20, 17, 45, 0.16);
	box-shadow: 0 2px 3px rgba(20, 17, 45, 0.06);
	border-radius: 3px;
  }

  .toast-header {
	border-bottom-color: rgba(205, 212, 224, 0.4);

	.close {
	  font-weight: 300;
	  color: #737688;
	}
  }

  * {
	box-sizing: border-box;

	&::before, &::after {
	  box-sizing: border-box;
	}
  }

  html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
	display: block;
  }

  body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5;
	color: #101735;
	text-align: left;
	background-color: $light;
  }

  [tabindex="-1"]:focus {
	outline: 0 !important;
  }

  hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
  }

  h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
  }

  p {
	margin-top: 0;
	margin-bottom: 1rem;
  }

  abbr {
	&[title], &[data-original-title] {
	  text-decoration: underline;
	  text-decoration: underline solid;
	  cursor: help;
	  border-bottom: 0;
	  text-decoration-skip-ink: none;
	}
  }

  address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
  }

  ol, ul, dl {
	margin-top: 0;
	margin-bottom: 1rem;
  }

  ol ol, ul ul, ol ul, ul ol {
	margin-bottom: 0;
  }

  dt {
	font-weight: 700;
  }

  dd {
	margin-bottom: .5rem;
	margin-left: 0;
  }

  blockquote {
	margin: 0 0 1rem;
  }

  b, strong {
	font-weight: bolder;
  }

  small {
	font-size: 80%;
  }

  sub, sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
  }

  sub {
	bottom: -.25em;
  }

  sup {
	top: -.5em;
  }

  a {
	color: #4c84ff;
	text-decoration: none;
	background-color: transparent;

	&:hover {
	  color: #005995;
	  text-decoration: none;
	}

	&:not([href]):not([tabindex]) {
	  color: inherit;
	  text-decoration: none;

	  &:hover {
		color: inherit;
		text-decoration: none;
	  }

	  &:focus {
		color: inherit;
		text-decoration: none;
		outline: 0;
	  }
	}
  }

  pre, code, kbd, samp {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	font-size: 1em;
  }

  pre {
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
  }

  figure {
	margin: 0 0 1rem;
  }

  img {
	vertical-align: middle;
	border-style: none;
	max-width: 100%;
  }

  svg {
	overflow: hidden;
	vertical-align: middle;
  }

  table {
	border-collapse: collapse;
  }

  caption {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	color: #737688;
	text-align: left;
	caption-side: bottom;
  }

  th {
	text-align: inherit;
  }

  label {
	display: inline-block;
	margin-bottom: 0.5rem;
  }

  button {
	border-radius: 0;

	&:focus {
	  outline: 1px solid;
	  outline: 5px auto -webkit-focus-ring-color;
	}
  }

  input, button, select, optgroup, textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
  }

  button, input {
	overflow: visible;
  }

  button {
	text-transform: none;
  }

  select {
	text-transform: none;
	word-wrap: normal;
  }

  button, [type="button"], [type="reset"], [type="submit"] {
	-webkit-appearance: button;
  }

  button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
	cursor: pointer;
  }

  button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none;
  }

  input {
	&[type="radio"], &[type="checkbox"] {
	  box-sizing: border-box;
	  padding: 0;
	}

	&[type="date"], &[type="time"], &[type="datetime-local"], &[type="month"] {
	  -webkit-appearance: listbox;
	}
  }

  textarea {
	overflow: auto;
	resize: vertical;
  }

  fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
  }

  legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: .5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal;
  }

  progress {
	vertical-align: baseline;
  }

  [type="number"] {
	&::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
	  height: auto;
	}
  }

  [type="search"] {
	outline-offset: -2px;
	-webkit-appearance: none;

	&::-webkit-search-decoration {
	  -webkit-appearance: none;
	}
  }

  ::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
  }

  output {
	display: inline-block;
  }

  summary {
	display: list-item;
	cursor: pointer;
  }

  template {
	display: none;
  }

  [hidden] {
	display: none !important;
  }

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
  }

  h1, .h1 {
	font-size: 2.1875rem;
  }

  h2, .h2 {
	font-size: 1.75rem;
  }

  h3, .h3 {
	font-size: 1.53125rem;
  }

  h4, .h4 {
	font-size: 1.3125rem;
  }

  h5, .h5 {
	font-size: 1.09375rem;
  }

  h6, .h6 {
	font-size: 0.875rem;
  }

  .lead {
	font-size: 1.09375rem;
	font-weight: 300;
  }

  hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid $black-1;
  }

  small, .small {
	font-size: 80%;
	font-weight: 400;
  }

  mark, .mark {
	padding: 0.2em;
	background-color: #fcf8e3;
  }

  .initialism {
	font-size: 90%;
	text-transform: uppercase;
  }

  .blockquote {
	margin-bottom: 1rem;
	font-size: 1.09375rem;
  }

  .blockquote-footer {
	display: block;
	font-size: 80%;
	color: #737688;

	&::before {
	  content: "\2014\00A0";
	}
  }

  body * {
	&::-webkit-scrollbar {
	  width: 5px;
	  height: 5px;
	  transition: .3s background;
	}

	&::-webkit-scrollbar-thumb {
	  background: #c1c0d0;
	}

	&:hover::-webkit-scrollbar-thumb {
	  background: #a9a7b9;
	}

	&::-webkit-scrollbar-track {
	  background: #e6e3ec;
	}
  }

  .img-fluid {
	max-width: 100%;
	height: auto;
  }

  .img-thumbnail {
	padding: 0.25rem;
	background-color: $white;
	border: 1px solid $gray-100;
	border-radius: 0px;
	max-width: 100%;
	height: auto;
  }

  .figure {
	display: inline-block;
  }

  .figure-img {
	margin-bottom: 0.5rem;
	line-height: 1;
  }

  .figure-caption {
	font-size: 90%;
	color: #737688;
  }

  code {
	font-size: 87.5%;
	color: #ff4668;
	word-break: break-word;
  }

  a > code {
	color: inherit;
  }

  kbd {
	padding: 0.2rem 0.4rem;
	font-size: 87.5%;
	color: $white;
	background-color: #14112d;
	border-radius: 0.2rem;

	kbd {
	  padding: 0;
	  font-size: 100%;
	  font-weight: 700;
	}
  }

  pre {
	display: block;
	font-size: 87.5%;
	color: #14112d;

	code {
	  font-size: inherit;
	  color: inherit;
	  word-break: normal;
	}
  }

  .pre-scrollable {
	max-height: 340px;
	overflow-y: scroll;
  }

  /*--- TOAST & MODAL ---*/

  .close {
	float: right;
	font-size: 1.3125rem;
	font-weight: 700;
	line-height: 1;
	color: #101735;
	text-shadow: 0 1px 0 $white;
	opacity: .5;

	&:hover {
	  color: #101735;
	  text-decoration: none;
	}

	&:not(:disabled):not(.disabled) {
	  &:hover, &:focus {
		opacity: .75;
	  }
	}
  }

  button.close {
	padding: 0;
	background-color: transparent;
	border: 0;
	appearance: none;
  }

  a.close.disabled {
	pointer-events: none;
  }

  .toast {
	max-width: 350px;
	overflow: hidden;
	font-size: 0.875rem;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border: 1px solid $black-1;
	box-shadow: 0 0.25rem 0.75rem $black-1;
	backdrop-filter: blur(10px);
	opacity: 0;
	border-radius: 0.25rem;

	&:not(:last-child) {
	  margin-bottom: 0.75rem;
	}

	&.showing {
	  opacity: 1;
	}

	&.show {
	  display: block;
	  opacity: 1;
	}

	&.hide {
	  display: none;
	}
  }

  .toast-header {
	display: flex;
	align-items: center;
	padding: 0.25rem 0.75rem;
	color: #737688;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border-bottom: 1px solid $black-05;
  }

  .toast-body {
	padding: 0.75rem;
  }

  /*--- TOAST & MODAL ---*/

  @media print {
	* {
	  text-shadow: none !important;
	  box-shadow: none !important;

	  &::before, &::after {
		text-shadow: none !important;
		box-shadow: none !important;
	  }
	}

	a:not(.btn) {
	  text-decoration: underline;
	}

	abbr[title]::after {
	  content: " (" attr(title) ")";
	}

	pre {
	  white-space: pre-wrap !important;
	  border: 1px solid #737688;
	  page-break-inside: avoid;
	}

	blockquote {
	  border: 1px solid #737688;
	  page-break-inside: avoid;
	}

	thead {
	  display: table-header-group;
	}

	tr, img {
	  page-break-inside: avoid;
	}

	p, h2, h3 {
	  orphans: 3;
	  widows: 3;
	}

	h2, h3 {
	  page-break-after: avoid;
	}

	@page {
	  size: a3;
	}

	body, .container {
	  min-width: 992px !important;
	}

	.navbar {
	  display: none;
	}

	.badge {
	  border: 1px solid #101735;
	}

	.table {
	  border-collapse: collapse !important;

	  td, th {
		background-color: $white !important;
	  }
	}

	.table-bordered {
	  th, td {
		border: 1px solid $gray-100 !important;
	  }
	}

	.table-dark {
	  color: inherit;

	  th, td, thead th, tbody + tbody {
		border-color: $gray-100;
	  }
	}

	.table .thead-dark th {
	  color: inherit;
	  border-color: $gray-100;
	}
  }

  .demo-static-toast .toast {
	opacity: 1;
  }

/*--- INVOICE ---*/

@media (min-width: 768px) {
	.invoice-header {
	  display: flex;
	  justify-content: space-between;
	  flex-direction: row-reverse;
	}
  }
  
  .invoice-title {
	margin-bottom: 0;
	text-transform: uppercase;
	color: $background;
	font-weight: 700;
  }
  
  .invoice-info-row {
	display: flex;
	justify-content: space-between;
	padding: 5px 0;
	margin-bottom: 0;
	font-size: 13px;
  
	+ .invoice-info-row {
	  border-top: 1px solid $gray-100;
	}
  
	span:first-child {
	  color: #22252f;
	}
  }
  
  /*--- INVOICE ---*/
  
  /* ###### 7.5 Invoice  ###### */
  
  .main-content-left-invoice {
	display: block;
	padding: 0;
  
	.main-content-breadcrumb, .main-content-title {
	  padding-left: 20px;
	}
  }
  
  @media (max-width: 991.98px) {
	.main-content-left-invoice {
	  width: 100%;
	}
  }
  
  @media (min-width: 992px) {
	.main-content-left-invoice {
	  border-right: 1px solid $gray-100;
	}
  }
  
  .main-invoice-list {
	position: relative;
	border-top: 1px solid $gray-100;
  
	.media {
	  align-items: center;
	  border: 1px solid transparent;
	  border-right-width: 0;
	  padding: 10px 20px;
	  position: relative;
  
	  &:hover, &:focus {
		cursor: pointer;
		background-color: $background;
	  }
  
	  + .media {
		margin-top: -1px;
  
		&::before {
		  content: '';
		  position: absolute;
		  top: -1px;
		  left: 55px;
		  right: 0;
		  border-top: 1px solid $gray-100;
		}
	  }
	}
  
	.media-icon {
	  width: 24px;
	  text-align: center;
	  font-size: 32px;
	  color: #737688;
	}
  
	.media-body {
	  margin-left: 15px;
  
	  h6 {
		font-weight: 500;
		color: #14112d;
		margin-bottom: 3px;
		display: flex;
		align-items: center;
		justify-content: space-between;
  
		span:last-child {
		  color: $primary;
		}
	  }
  
	  div {
		display: flex;
		justify-content: space-between;
		align-items: center;
	  }
  
	  p {
		margin-bottom: 0;
		font-size: 11px;
		font-family: Arial, sans-serif;
		color: $dark;
  
		span {
		  color: #737688;
		}
	  }
	}
  
	.selected {
	  background-color: $background;
	  border-top-color: $gray-100;
	  border-bottom-color: $gray-100;
	  border-left-color: $primary;
  
	  &:first-child {
		border-top-color: transparent;
	  }
	}
  }
  
  @media (min-width: 992px) {
	.main-invoice-list {
	  height: calc(100% - 65px);
	}
  }
  
  @media (min-width: 992px) {
	.main-invoice-list .media {
	  padding: 10px 15px;
	}
  }
  
  .main-content-body-invoice {
	position: relative;
  }
  
  @media (min-width: 992px) {
	.main-content-body-invoice {
	  display: block;
	}
  }
  
  .billed-from {
	margin-top: 25px;
	margin-bottom: 25px;
  
	h6 {
	  color: #14112d;
	  font-size: 14px;
	  font-weight: 700;
	}
  
	p {
	  font-size: 13px;
	  margin-bottom: 0;
	}
  }
  
  @media (min-width: 768px) {
	.billed-from {
	  margin-top: 0;
	}
  }
  
  .billed-to {
	h6 {
	  color: #14112d;
	  font-size: 14px;
	  font-weight: 700;
	}
  
	p {
	  font-size: 13px;
	  margin-bottom: 0;
	}
  }
  
  .table-invoice {
	thead > tr > {
	  th, td {
		font-size: 11px;
		font-weight: 700;
		color: #737688;
	  }
	}
  
	tbody > tr > {
	  th:first-child, td:first-child {
		color: #14112d;
		font-weight: 500;
	  }
  
	  th:first-child .invoice-notes, td:first-child .invoice-notes {
		margin-right: 20px;
	  }
  
	  th:first-child .invoice-notes .section-label-sm, td:first-child .invoice-notes .section-label-sm {
		font-weight: 600;
	  }
  
	  th:first-child .invoice-notes p, td:first-child .invoice-notes p {
		font-size: 13px;
		font-weight: 400;
		color: #22252f;
	  }
	}
  }
/*--- profile ---*/

.profile-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 20px;
	background: $white;
	left: 0;
	right: 0;
  
	a {
	  width: 34px;
	  height: 34px;
	  background: $background;
	  color: $default-color;
	  margin: 1px auto;
	  text-align: center;
	  line-height: 34px;
	  display: inline-block;
	  border-radius: 50%;
	  font-size: 12px;
  
	  &:hover {
		background: $primary;
		color: $white;
	  }
	}
  }
  
  .profile-user {
	position: relative;
  
	.profile-edit {
	  position: absolute;
	  width: 30px;
	  height: 30px;
	  border-radius: 50%;
	  line-height: 30px;
	  right: 0;
	  background: $background;
	  margin: 0 auto;
	  text-align: center;
	}
  }
  
  /*--- profile ---*/
  
  /* ###### 7.8 Profile  ###### */
  
  .main-content-profile {
	flex: 1;
  }
  
  @media (max-width: 991.98px) {
	.main-content-profile {
	  .container, .container-fluid {
		display: block;
	  }
	}
  }
  
  .main-content-left-profile {
	padding-left: 0;
	padding-right: 0;
	display: block;
	border-bottom: 1px solid $gray-100;
	padding-bottom: 25px;
	width: auto;
  }
  
  @media (min-width: 992px) {
	.main-content-left-profile {
	  width: 270px;
	  padding-right: 20px;
	  padding-bottom: 0;
	  border-right: 1px solid $gray-100;
	  border-bottom: 0;
	}
  }
  
  @media (min-width: 1200px) {
	.main-content-left-profile {
	  padding-right: 25px;
	}
  }
  
  .main-profile-overview {
	.main-img-user {
	  width: 100px;
	  height: 100px;
	  margin: 0 auto;
  
	  &::after {
		display: none;
	  }
	}
  
	.btn-icon-list {
	  .btn, .sp-container button {
		border-radius: 100%;
	  }
	}
  }
  
  .sp-container .main-profile-overview .btn-icon-list button {
	border-radius: 100%;
  }
  
  .main-profile-name {
	color: #14112d;
	font-weight: 700;
	font-size: 20px;
	margin-bottom: 2px;
  }
  
  .main-profile-name-text {
	color: #737688;
	font-size: 13px;
	margin-bottom: 0;
  }
  
  .main-profile-bio {
	font-size: 13px;
	margin-bottom: 20px;
  }
  
  .main-profile-social-list {
	.media {
	  align-items: center;
  
	  + .media {
		margin-top: 20px;
	  }
	}
  
	.media-icon {
	  width: 40px;
	  height: 40px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  line-height: 0;
	  font-size: 21px;
	  position: relative;
	  top: 2px;
	  border-radius: 100%;
	}
  
	.media-body {
	  margin-left: 20px;
  
	  span {
		display: block;
		font-size: 12px;
		font-weight: 500;
	  }
  
	  a {
		font-size: 13px;
	  }
	}
  }
  
  .main-content-body-profile {
	.nav {
	  flex-direction: column;
	  padding: 10px 20px 20px 0;
	  border-bottom: 1px solid $gray-100;
	}
  
	.main-nav-line .nav-link {
	  text-transform: uppercase;
	  font-size: 13px;
	  font-weight: 500;
	  color: $dark;
  
	  &.active {
		font-weight: 700;
		color: $primary;
  
		&::before {
		  background-color: $primary;
		}
	  }
	}
  }
  
  @media (min-width: 576px) {
	.main-content-body-profile .nav {
	  flex-direction: row;
	  align-items: center;
	  padding-bottom: 10px;
	}
  }
  
  @media (min-width: 992px) {
	.main-content-body-profile .nav {
	  padding-left: 20px;
	}
  }
  
  @media (min-width: 1200px) {
	.main-content-body-profile .nav {
	  padding-left: 25px;
	}
  }
  
  @media (min-width: 576px) and (max-width: 767.98px) {
	.main-content-body-profile .main-nav-line .nav-link.active::before {
	  width: auto;
	  top: auto;
	  left: 0;
	  right: 0;
	  height: 2px;
	}
  }
  
  @media (min-width: 576px) {
	.main-content-body-profile .main-nav-line .nav-link.active::before {
	  bottom: -11px;
	}
  }
  
  @media (min-width: 576px) and (max-width: 767.98px) {
	.main-content-body-profile .main-nav-line .nav-link + .nav-link {
	  margin-top: 0;
	  margin-left: 20px;
	}
  }
  
  .main-profile-body {
	padding: 15px 0 0;
  }
  
  @media (min-width: 576px) {
	.main-profile-body {
	  padding-top: 0px;
	}
  }
  
  @media (min-width: 1200px) {
	.main-profile-body {
	  padding-left: 0px;
	}
  }
  
  .main-profile-view-chart {
	position: relative;
	width: calc(100% - 10px);
	height: 200px;
  }
  
  @media (min-width: 375px) {
	.main-profile-view-chart {
	  width: 100%;
	}
  }
  
  @media (min-width: 576px) {
	.main-profile-view-chart {
	  height: 250px;
	}
  }
  
  .main-profile-view-info {
	position: absolute;
	top: 0;
	left: 0;
  
	h6 {
	  font-size: 32px;
	  font-weight: 500;
	  color: #14112d;
	  margin-bottom: 0;
	}
  
	span {
	  font-size: 12px;
	  color: $pink;
	  margin-left: 5px;
	}
  
	p {
	  font-size: 13px;
	  margin-bottom: 0;
	}
  }
  
  .main-traffic-detail-item {
	> div:first-child {
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	  font-size: 12px;
	  margin-bottom: 7px;
  
	  > span {
		&:first-child {
		  color: #737688;
		}
  
		&:last-child {
		  font-size: 11px;
		  font-weight: 700;
		  color: #14112d;
  
		  span {
			color: #737688;
			font-weight: 400;
		  }
		}
	  }
	}
  
	+ .main-traffic-detail-item {
	  margin-top: 25px;
	}
  
	.progress {
	  height: 8px;
	}
  }
  
  @media (max-width: 768px) {
	.main-profile-work-list .media + .media, .main-profile-contact-list .media + .media {
	  margin-top: 25px;
	}
  }
  
  @media (min-width: 768px) {
	.main-profile-work-list .media + .media {
	  margin-left: 25px;
	}
  
	.main-profile-contact-list .media + .media {
	  margin-left: 35px;
	}
  }
  
  .main-profile-work-list {
	.media-logo {
	  width: 40px;
	  height: 40px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  line-height: 0;
	  color: $white;
	  font-size: 21px;
	  position: relative;
	  top: 2px;
	  border-radius: 100%;
	}
  
	.media-body {
	  margin-left: 20px;
  
	  h6 {
		color: #14112d;
		font-weight: 500;
		margin-bottom: 2px;
	  }
  
	  span {
		display: block;
		margin-bottom: 5px;
	  }
  
	  p {
		margin-bottom: 0;
		font-size: 12px;
		color: #737688;
	  }
	}
  }
  
  .main-profile-contact-list {
	.media {
	  align-items: center;
	}
  
	.media-icon {
	  width: 40px;
	  height: 40px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  line-height: 0;
	  font-size: 21px;
	  position: relative;
	  top: 2px;
	  border-radius: 100%;
	}
  
	.media-body {
	  margin-left: 25px;
  
	  span {
		font-size: 12px;
		color: #737688;
		display: block;
		line-height: 1.3;
	  }
  
	  div {
		font-weight: 500;
		color: #14112d;
	  }
	}
  }
  
  .main-profile-menu {
	> .main-img-user {
	  outline: none;
	  width: 32px;
	  height: 32px;
	}
  
	.dropdown-item {
	  i {
		font-size: 16px;
		margin-right: 10px;
		width: 20px;
		text-align: center;
  
		&.typcn {
		  line-height: .9;
		}
	  }
  
	  &:hover, &:focus {
		background-color: transparent;
		color: $primary;
	  }
  
	  + .dropdown-item {
		border-top: 1px solid $gray-100;
	  }
  
	  &:last-child {
		margin-bottom: 0px;
	  }
	}
  
	&.show .dropdown-menu {
	  display: block;
	}
  }
  
  @media (min-width: 576px) {
	.main-profile-menu.show .main-img-user::before {
	  display: block;
	}
  }
  
  .main-header-profile {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-bottom: 1px solid $gray-100;
  
	.main-img-user {
	  width: 120px;
	  height: 120px;
	  margin-bottom: 20px;
  
	  &::after {
		display: none;
	  }
	}
  
	h6 {
	  font-size: 24px;
	  font-weight: 700;
	  color: #14112d;
	  margin-bottom: 0;
	}
  
	span {
	  display: block;
	  font-size: 13px;
	}
  }
  
  @media (min-width: 576px) {
	.main-header-profile .main-img-user {
	  width: 80px;
	  height: 80px;
	}
  }
  
  @media (min-width: 576px) {
	.main-header-profile h6 {
	  font-size: 18px;
	}
  }
  
  .main-profile-menu {
	position: relative;
	margin: 10px;
  
	.dropdown-item + .dropdown-item {
	  border-top: 1px solid  $gray-100;
	}
  }
  
  .main-header-profile {
	border-bottom: 1px solid  $gray-100;
  }
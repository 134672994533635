.align-baseline {
	vertical-align: baseline !important;
  }
  
  .align-top {
	vertical-align: top !important;
  }
  
  .align-middle {
	vertical-align: middle !important;
  }
  
  .align-bottom {
	vertical-align: bottom !important;
  }
  
  .align-text-bottom {
	vertical-align: text-bottom !important;
  }
  
  .align-text-top {
	vertical-align: text-top !important;
  }
  
  .align-items-start {
	align-items: flex-start !important;
  }
  
  .align-items-end {
	align-items: flex-end !important;
  }
  
  .align-items-center {
	align-items: center !important;
  }
  
  .align-items-baseline {
	align-items: baseline !important;
  }
  
  .align-items-stretch {
	align-items: stretch !important;
  }
  
  .align-content-start {
	align-content: flex-start !important;
  }
  
  .align-content-end {
	align-content: flex-end !important;
  }
  
  .align-content-center {
	align-content: center !important;
  }
  
  .align-content-between {
	align-content: space-between !important;
  }
  
  .align-content-around {
	align-content: space-around !important;
  }
  
  .align-content-stretch {
	align-content: stretch !important;
  }
  
  .align-self-auto {
	align-self: auto !important;
  }
  
  .align-self-start {
	align-self: flex-start !important;
  }
  
  .align-self-end {
	align-self: flex-end !important;
  }
  
  .align-self-center {
	align-self: center !important;
  }
  
  .align-self-baseline {
	align-self: baseline !important;
  }
  
  .align-self-stretch {
	align-self: stretch !important;
  }
  
  @media (min-width: 576px) {
	.align-items-sm-start {
	  align-items: flex-start !important;
	}
  
	.align-items-sm-end {
	  align-items: flex-end !important;
	}
  
	.align-items-sm-center {
	  align-items: center !important;
	}
  
	.align-items-sm-baseline {
	  align-items: baseline !important;
	}
  
	.align-items-sm-stretch {
	  align-items: stretch !important;
	}
  
	.align-content-sm-start {
	  align-content: flex-start !important;
	}
  
	.align-content-sm-end {
	  align-content: flex-end !important;
	}
  
	.align-content-sm-center {
	  align-content: center !important;
	}
  
	.align-content-sm-between {
	  align-content: space-between !important;
	}
  
	.align-content-sm-around {
	  align-content: space-around !important;
	}
  
	.align-content-sm-stretch {
	  align-content: stretch !important;
	}
  
	.align-self-sm-auto {
	  align-self: auto !important;
	}
  
	.align-self-sm-start {
	  align-self: flex-start !important;
	}
  
	.align-self-sm-end {
	  align-self: flex-end !important;
	}
  
	.align-self-sm-center {
	  align-self: center !important;
	}
  
	.align-self-sm-baseline {
	  align-self: baseline !important;
	}
  
	.align-self-sm-stretch {
	  align-self: stretch !important;
	}
  }
  
  @media (min-width: 768px) {
	.align-items-md-start {
	  align-items: flex-start !important;
	}
  
	.align-items-md-end {
	  align-items: flex-end !important;
	}
  
	.align-items-md-center {
	  align-items: center !important;
	}
  
	.align-items-md-baseline {
	  align-items: baseline !important;
	}
  
	.align-items-md-stretch {
	  align-items: stretch !important;
	}
  
	.align-content-md-start {
	  align-content: flex-start !important;
	}
  
	.align-content-md-end {
	  align-content: flex-end !important;
	}
  
	.align-content-md-center {
	  align-content: center !important;
	}
  
	.align-content-md-between {
	  align-content: space-between !important;
	}
  
	.align-content-md-around {
	  align-content: space-around !important;
	}
  
	.align-content-md-stretch {
	  align-content: stretch !important;
	}
  
	.align-self-md-auto {
	  align-self: auto !important;
	}
  
	.align-self-md-start {
	  align-self: flex-start !important;
	}
  
	.align-self-md-end {
	  align-self: flex-end !important;
	}
  
	.align-self-md-center {
	  align-self: center !important;
	}
  
	.align-self-md-baseline {
	  align-self: baseline !important;
	}
  
	.align-self-md-stretch {
	  align-self: stretch !important;
	}
  }
  
  @media (min-width: 992px) {
	.align-items-lg-start {
	  align-items: flex-start !important;
	}
  
	.align-items-lg-end {
	  align-items: flex-end !important;
	}
  
	.align-items-lg-center {
	  align-items: center !important;
	}
  
	.align-items-lg-baseline {
	  align-items: baseline !important;
	}
  
	.align-items-lg-stretch {
	  align-items: stretch !important;
	}
  
	.align-content-lg-start {
	  align-content: flex-start !important;
	}
  
	.align-content-lg-end {
	  align-content: flex-end !important;
	}
  
	.align-content-lg-center {
	  align-content: center !important;
	}
  
	.align-content-lg-between {
	  align-content: space-between !important;
	}
  
	.align-content-lg-around {
	  align-content: space-around !important;
	}
  
	.align-content-lg-stretch {
	  align-content: stretch !important;
	}
  
	.align-self-lg-auto {
	  align-self: auto !important;
	}
  
	.align-self-lg-start {
	  align-self: flex-start !important;
	}
  
	.align-self-lg-end {
	  align-self: flex-end !important;
	}
  
	.align-self-lg-center {
	  align-self: center !important;
	}
  
	.align-self-lg-baseline {
	  align-self: baseline !important;
	}
  
	.align-self-lg-stretch {
	  align-self: stretch !important;
	}
  }
  
  @media (min-width: 1200px) {
	.align-items-xl-start {
	  align-items: flex-start !important;
	}
  
	.align-items-xl-end {
	  align-items: flex-end !important;
	}
  
	.align-items-xl-center {
	  align-items: center !important;
	}
  
	.align-items-xl-baseline {
	  align-items: baseline !important;
	}
  
	.align-items-xl-stretch {
	  align-items: stretch !important;
	}
  
	.align-content-xl-start {
	  align-content: flex-start !important;
	}
  
	.align-content-xl-end {
	  align-content: flex-end !important;
	}
  
	.align-content-xl-center {
	  align-content: center !important;
	}
  
	.align-content-xl-between {
	  align-content: space-between !important;
	}
  
	.align-content-xl-around {
	  align-content: space-around !important;
	}
  
	.align-content-xl-stretch {
	  align-content: stretch !important;
	}
  
	.align-self-xl-auto {
	  align-self: auto !important;
	}
  
	.align-self-xl-start {
	  align-self: flex-start !important;
	}
  
	.align-self-xl-end {
	  align-self: flex-end !important;
	}
  
	.align-self-xl-center {
	  align-self: center !important;
	}
  
	.align-self-xl-baseline {
	  align-self: baseline !important;
	}
  
	.align-self-xl-stretch {
	  align-self: stretch !important;
	}
  }
  
  .flex-1 {
	flex: 1;
  }
  
  .flex-0 {
	flex: 0;
  }
  
  .flex-row {
	flex-direction: row !important;
  }
  
  .flex-column {
	flex-direction: column !important;
  }
  
  .flex-row-reverse {
	flex-direction: row-reverse !important;
  }
  
  .flex-column-reverse {
	flex-direction: column-reverse !important;
  }
  
  .flex-wrap {
	flex-wrap: wrap !important;
  }
  
  .flex-nowrap {
	flex-wrap: nowrap !important;
  }
  
  .flex-wrap-reverse {
	flex-wrap: wrap-reverse !important;
  }
  
  .flex-fill {
	flex: 1 1 auto !important;
  }
  
  .flex-grow-0 {
	flex-grow: 0 !important;
  }
  
  .flex-grow-1 {
	flex-grow: 1 !important;
  }
  
  .flex-shrink-0 {
	flex-shrink: 0 !important;
  }
  
  .flex-shrink-1 {
	flex-shrink: 1 !important;
  }
  
  @media (min-width: 576px) {
	.flex-sm-row {
	  flex-direction: row !important;
	}
  
	.flex-sm-column {
	  flex-direction: column !important;
	}
  
	.flex-sm-row-reverse {
	  flex-direction: row-reverse !important;
	}
  
	.flex-sm-column-reverse {
	  flex-direction: column-reverse !important;
	}
  
	.flex-sm-wrap {
	  flex-wrap: wrap !important;
	}
  
	.flex-sm-nowrap {
	  flex-wrap: nowrap !important;
	}
  
	.flex-sm-wrap-reverse {
	  flex-wrap: wrap-reverse !important;
	}
  
	.flex-sm-fill {
	  flex: 1 1 auto !important;
	}
  
	.flex-sm-grow-0 {
	  flex-grow: 0 !important;
	}
  
	.flex-sm-grow-1 {
	  flex-grow: 1 !important;
	}
  
	.flex-sm-shrink-0 {
	  flex-shrink: 0 !important;
	}
  
	.flex-sm-shrink-1 {
	  flex-shrink: 1 !important;
	}
  }
  
  @media (min-width: 768px) {
	.flex-md-row {
	  flex-direction: row !important;
	}
  
	.flex-md-column {
	  flex-direction: column !important;
	}
  
	.flex-md-row-reverse {
	  flex-direction: row-reverse !important;
	}
  
	.flex-md-column-reverse {
	  flex-direction: column-reverse !important;
	}
  
	.flex-md-wrap {
	  flex-wrap: wrap !important;
	}
  
	.flex-md-nowrap {
	  flex-wrap: nowrap !important;
	}
  
	.flex-md-wrap-reverse {
	  flex-wrap: wrap-reverse !important;
	}
  
	.flex-md-fill {
	  flex: 1 1 auto !important;
	}
  
	.flex-md-grow-0 {
	  flex-grow: 0 !important;
	}
  
	.flex-md-grow-1 {
	  flex-grow: 1 !important;
	}
  
	.flex-md-shrink-0 {
	  flex-shrink: 0 !important;
	}
  
	.flex-md-shrink-1 {
	  flex-shrink: 1 !important;
	}
  }
  
  @media (min-width: 992px) {
	.flex-lg-row {
	  flex-direction: row !important;
	}
  
	.flex-lg-column {
	  flex-direction: column !important;
	}
  
	.flex-lg-row-reverse {
	  flex-direction: row-reverse !important;
	}
  
	.flex-lg-column-reverse {
	  flex-direction: column-reverse !important;
	}
  
	.flex-lg-wrap {
	  flex-wrap: wrap !important;
	}
  
	.flex-lg-nowrap {
	  flex-wrap: nowrap !important;
	}
  
	.flex-lg-wrap-reverse {
	  flex-wrap: wrap-reverse !important;
	}
  
	.flex-lg-fill {
	  flex: 1 1 auto !important;
	}
  
	.flex-lg-grow-0 {
	  flex-grow: 0 !important;
	}
  
	.flex-lg-grow-1 {
	  flex-grow: 1 !important;
	}
  
	.flex-lg-shrink-0 {
	  flex-shrink: 0 !important;
	}
  
	.flex-lg-shrink-1 {
	  flex-shrink: 1 !important;
	}
  }
  
  @media (min-width: 1200px) {
	.flex-xl-row {
	  flex-direction: row !important;
	}
  
	.flex-xl-column {
	  flex-direction: column !important;
	}
  
	.flex-xl-row-reverse {
	  flex-direction: row-reverse !important;
	}
  
	.flex-xl-column-reverse {
	  flex-direction: column-reverse !important;
	}
  
	.flex-xl-wrap {
	  flex-wrap: wrap !important;
	}
  
	.flex-xl-nowrap {
	  flex-wrap: nowrap !important;
	}
  
	.flex-xl-wrap-reverse {
	  flex-wrap: wrap-reverse !important;
	}
  
	.flex-xl-fill {
	  flex: 1 1 auto !important;
	}
  
	.flex-xl-grow-0 {
	  flex-grow: 0 !important;
	}
  
	.flex-xl-grow-1 {
	  flex-grow: 1 !important;
	}
  
	.flex-xl-shrink-0 {
	  flex-shrink: 0 !important;
	}
  
	.flex-xl-shrink-1 {
	  flex-shrink: 1 !important;
	}
  }
  
  .float-left {
	float: left !important;
  }
  
  .float-right {
	float: right !important;
  }
  
  .float-none {
	float: none !important;
  }
  
  @media (min-width: 576px) {
	.float-sm-left {
	  float: left !important;
	}
  
	.float-sm-right {
	  float: right !important;
	}
  
	.float-sm-none {
	  float: none !important;
	}
  }
  
  @media (min-width: 768px) {
	.float-md-left {
	  float: left !important;
	}
  
	.float-md-right {
	  float: right !important;
	}
  
	.float-md-none {
	  float: none !important;
	}
  }
  
  @media (min-width: 992px) {
	.float-lg-left {
	  float: left !important;
	}
  
	.float-lg-right {
	  float: right !important;
	}
  
	.float-lg-none {
	  float: none !important;
	}
  }
  
  @media (min-width: 1200px) {
	.float-xl-left {
	  float: left !important;
	}
  
	.float-xl-right {
	  float: right !important;
	}
  
	.float-xl-none {
	  float: none !important;
	}
  }
  
  .embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;
  
	&::before {
	  display: block;
	  content: "";
	}
  
	.embed-responsive-item, iframe, embed, object, video {
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  border: 0;
	}
  }
  
  .embed-responsive-21by9::before {
	padding-top: 42.85714%;
  }
  
  .embed-responsive-16by9::before {
	padding-top: 56.25%;
  }
  
  .embed-responsive-4by3::before {
	padding-top: 75%;
  }
  
  .embed-responsive-1by1::before {
	padding-top: 100%;
  }
  
  .clearfix::after {
	display: block;
	clear: both;
	content: "";
  }
  
  .overflow-auto {
	overflow: auto !important;
  }
  
  .overflow-hidden {
	overflow: hidden !important;
  }
  
  .fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
  }
  
  .fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
  }
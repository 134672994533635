/*--- BADGES ---*/

.badge {
	display: inline-block;
	padding: 3px 6px;
	font-size: 12px;
	font-weight: 400;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0px;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  @media (prefers-reduced-motion: reduce) {
	.badge {
	  transition: none;
	}
  }
  
  a.badge {
	&:hover, &:focus {
	  text-decoration: none;
	}
  }
  
  .badge:empty {
	display: none;
  }
  
  .btn .badge, .sp-container button .badge {
	position: relative;
	top: -1px;
  }
  
  .badge-pill {
	padding-right: 0.6em;
	padding-left: 0.6em;
	border-radius: 10rem;
  }
  
  .badge-primary {
	color: $white;
	background-color: $primary;
  }
  
  a.badge-primary {
	&:hover {
	  color: $white;
	  background-color: #0040ff;
	}
  
	&:focus {
	  color: $white;
	  background-color: #0040ff;
	  outline: 0;
	  box-shadow: 0 0 0 0.2rem rgba(104, 75, 219, 0.5);
	}
  
	&.focus {
	  outline: 0;
	  box-shadow: 0 0 0 0.2rem rgba(104, 75, 219, 0.5);
	}
  }
  
  .badge-secondary {
	color: $white;
	background-color: #737688;
  }
  
  a.badge-secondary {
	&:hover {
	  color: $white;
	  background-color: #ef6591;
	}
  
	&:focus {
	  color: $white;
	  background-color: #ef6591;
	  outline: 0;
	  box-shadow: 0 0 0 0.2rem rgba(121, 135, 161, 0.5);
	}
  
	&.focus {
	  outline: 0;
	  box-shadow: 0 0 0 0.2rem rgba(121, 135, 161, 0.5);
	}
  }
  
  .badge-success {
	color: $white;
	background-color: $success;
  }
  
  a.badge-success {
	&:hover {
	  color: $white;
	  background-color: #4bd2b0;
	}
  
	&:focus {
	  color: $white;
	  background-color: #4bd2b0;
	  outline: 0;
	  box-shadow: 0 0 0 0.2rem rgba(78, 220, 184, 0.5);
	}
  
	&.focus {
	  outline: 0;
	  box-shadow: 0 0 0 0.2rem rgba(78, 220, 184, 0.5);
	}
  }
  
  .badge-info {
	color: $white;
	background-color: $info;
  }
  
  a.badge-info {
	&:hover {
	  color: $white;
	  background-color: #117a8b;
	}
  
	&:focus {
	  color: $white;
	  background-color: #117a8b;
	  outline: 0;
	  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
	}
  
	&.focus {
	  outline: 0;
	  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
	}
  }
  
  .badge-warning {
	color: #14112d;
	background-color: $warning;
  }
  
  a.badge-warning {
	&:hover {
	  color: #14112d;
	  background-color: #ef9c05;
	}
  
	&:focus {
	  color: #14112d;
	  background-color: #ef9c05;
	  outline: 0;
	  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
	}
  
	&.focus {
	  outline: 0;
	  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
	}
  }
  
  .badge-danger {
	color: $white;
	background-color: $danger;
  }
  
  a.badge-danger {
	&:hover {
	  color: $white;
	  background-color: #ef4352;
	}
  
	&:focus {
	  color: $white;
	  background-color: #ef4352;
	  outline: 0;
	  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
	}
  
	&.focus {
	  outline: 0;
	  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
	}
  }
  
  .badge-light {
	color: #14112d;
	background-color: $background;
  }
  
  a.badge-light {
	&:hover {
	  color: #14112d;
	  background-color: #d5d9e4;
	}
  
	&:focus {
	  color: #14112d;
	  background-color: #d5d9e4;
	  outline: 0;
	  box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
	}
  
	&.focus {
	  outline: 0;
	  box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
	}
  }
  
  .badge-dark {
	color: $white;
	background-color: $dark;
  }
  
  a.badge-dark {
	&:hover {
	  color: $white;
	  background-color: #0e0c13;
	}
  
	&:focus {
	  color: $white;
	  background-color: #0e0c13;
	  outline: 0;
	  box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
	}
  
	&.focus {
	  outline: 0;
	  box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
	}
  }
  
  /*--- BADGES ---*/
  
  .nav-link-badge {
	position: absolute;
	top: 5px;
	left: 20px;
	display: block !important;
	padding: 3px 5px !important;
	border-radius: 50px;
	color: $white;
	font-size: 11px;
	line-height: 1;
  }
/*--- ACCORDION ---*/

.accordion {
	> .card {
	  overflow: hidden;
  
	  &:not(:first-of-type) {
		.card-header:first-child {
		  border-radius: 0;
		}
  
		&:not(:last-of-type) {
		  border-bottom: 0;
		  border-radius: 0;
		}
	  }
  
	  &:first-of-type {
		border-bottom: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	  }
  
	  &:last-of-type {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	  }
  
	  .card-header {
		margin-bottom: -1px;
	  }
	}
  
	.card {
	  border-width: 0;
  
	  + .card {
		margin-top: 1px;
	  }
	}
  
	.card-header {
	  padding: 0;
	  background-color: transparent;
	  font-weight: 500;
  
	  a {
		display: block;
		padding: 0.75rem;
		color: #14112d;
		position: relative;
		font-weight: 500;
		font-size: 14px;
		background-color: $white;
		border: 1px solid $gray-100;
  
		&:hover, &:focus {
		  color: #14112d;
		}
  
		&.collapsed {
		  color: #22252f;
		  background-color: $background;
  
		  &:hover, &:focus {
			color: #14112d;
			background-color: $background;
		  }
		}
	  }
	}
  
	.card-body {
	  padding: 1rem;
	  background-color: $white;
	  transition: none;
	  border: 1px solid $gray-100;
	}
  }
  
  .accordion-primary {
	.card {
	  border-color: $primary;
	}
  
	.card-header a {
	  color: $primary;
  
	  &:hover, &:focus {
		color: $primary;
	  }
  
	  &.collapsed {
		background-color: $primary;
		color: $white-8;
		font-weight: 500;
		letter-spacing: -.1px;
  
		&:hover, &:focus {
		  background-color: $primary;
		  color: $white;
		}
	  }
	}
  }
  
  .accordion-blue {
	.card {
	  border-color: $primary;
	}
  
	.card-header a {
	  color: $primary;
  
	  &:hover, &:focus {
		color: $primary;
	  }
  
	  &.collapsed {
		background-color: $primary;
		color: $white-8;
		font-weight: 500;
		letter-spacing: -.1px;
  
		&:hover, &:focus {
		  background-color: #1a53ff;
		  color: $white;
		}
	  }
	}
  }
  
  .accordion-dark {
	.card {
	  border-color: #14112d;
	}
  
	.card-header a {
	  color: #14112d;
  
	  &:hover, &:focus {
		color: #14112d;
	  }
  
	  &.collapsed {
		background-color: #14112d;
		color: $white-8;
		font-weight: 500;
		letter-spacing: -.1px;
  
		&:hover, &:focus {
		  background-color: #14112d;
		  color: $white;
		}
	  }
	}
  }
  
  .accordion-gray {
	.card {
	  border-color: #737688;
	}
  
	.card-header a {
	  color: #737688;
  
	  &:hover, &:focus {
		color: #737688;
	  }
  
	  &.collapsed {
		background-color: #737688;
		color: $white-8;
		font-weight: 500;
		letter-spacing: -.1px;
  
		&:hover, &:focus {
		  background-color: #6a7a96;
		  color: $white;
		}
	  }
	}
  }
  
  /*--- ACCORDION ---*/
  
  /*--- CUSTOM STYLES ---*/
  
  .main-accordion {
	.accordion-item {
	  background-color: $background;
	  border: 1px solid #dee2ef;
	  position: relative;
	  transition: all 0.2s ease-in-out;
  
	  &:hover, &:focus {
		cursor: pointer;
		background-color: #f4f7fd;
		border-color: #bcc4de;
		z-index: 4;
	  }
  
	  &.active {
		background-color: $white;
		z-index: 5;
		border-color: #bcc4de;
  
		.accordion-title {
		  color: $primary;
		}
  
		.accordion-body {
		  display: block;
		}
	  }
  
	  + .accordion-item {
		margin-top: -1px;
	  }
	}
  
	.accordion-header {
	  display: flex;
	  align-items: center;
	  padding: 15px 20px;
	}
  
	.accordion-title {
	  font-weight: 700;
	  color: #22252f;
	  margin: 0;
	  transition: all 0.2s ease-in-out;
	}
  
	.accordion-body {
	  display: none;
	  padding: 0 20px 20px;
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.main-accordion .accordion-item {
	  transition: none;
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.main-accordion .accordion-title {
	  transition: none;
	}
  }
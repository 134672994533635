/*--- PRODUCTS ---*/

.product-img {
    .img-main {
      display: inline-block;
    }
  
    .img-reverse {
      display: none;
    }
  
    &:hover {
      .img-main {
        display: none;
      }
  
      .img-reverse {
        display: inline-block;
      }
    }
  }
  
  .product-timeline ul.timeline-1 {
    list-style-type: none;
    position: relative;
  
    &:before {
      content: ' ';
      border: 1px solid $gray-100;
      margin: 20px 20px 0 22px;
      display: inline-block;
      position: absolute;
      left: -2px;
      width: 2px;
      height: 97%;
      z-index: 0;
      content: ' ';
      border: 1px solid #e5eafb;
      margin: 20px 20px 0 22px;
      display: inline-block;
      position: absolute;
      left: -2px;
      width: 2px;
      height: 94%;
      z-index: 0;
    }
  
    > li {
      margin: 22px 0;
      padding-left: 1.5rem;
  
      .product-icon {
        width: 38px;
        height: 38px;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        left: 1px;
        background-color: #f3f6f7;
        font-size: 20px;
        line-height: 38px;
        font-size: 17px;
      }
    }
  
    list-style-type: none;
    position: relative;
  
    > li {
      margin: 22px 0;
      padding-left: 3.5rem;
  
      .product-icon {
        width: 40px;
        height: 40px;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        left: 1px;
        background-color: #f3f6f7;
        font-size: 20px;
        line-height: 40px;
        font-size: 21px;
      }
    }
  }
  
  /*--- PRODUCTS ---*/
/* ###### 6.2 Footer  ###### */

.main-footer {
	background-color: $white;
	border-top: 1px solid $gray-100;
	margin-top: auto;
  
	.container {
	  font-size: 12px;
	  padding: 10px 20px;
	  align-items: center;
	  justify-content: space-between;
	  text-align: center;
	}
  
	.container-fluid {
	  font-size: 12px;
	  padding: 10px 20px;
	  align-items: center;
	  justify-content: space-between;
	  text-align: center;
	  padding-left: 20px;
	  padding-right: 20px;
	}
  }
  
  @media (min-width: 992px) {
	.main-footer {
	  display: block;
	}
  }
  
  @media (min-width: 992px) {
	.main-footer .container {
	  padding: 10px 0;
	  margin: 0 auto;
	  text-align: center;
	  line-height: 20px;
	}
  
	.app .main-content-contacts {
	  margin-bottom: 20px !important;
	}
  }
  
  .app {
	.main-footer .container-fluid {
	  padding: 10px 20px;
	  text-align: center;
	}
  
	&.sidebar-mini {
	  .main-footer {
		box-shadow: 0 2px 10px -1px rgba(8, 35, 171, 0.05);
		margin-left: 250px;
	  }
  
	  &.sidenav-toggled .main-footer {
		margin-left: 0px;
	  }
	}
  }
  
  @media (max-width: 767px) {
	.app.sidebar-mini .main-footer {
	  margin-left: 0px;
	}
  }
  
  .main-footer-app {
	.container, .container-fluid {
	  border-top-width: 0;
	}
  }
  
  .main-footer-demo {
	background-color: #0c025b;
	color: $white-4;
	height: 60px;
  
	.container {
	  border-top-width: 0;
	}
  
	a {
	  color: $white-5;
  
	  &:hover, &:focus {
		color: $white;
	  }
	}
  }
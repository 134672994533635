// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$background:#f3f7fd;
$default-color:#101735;

/*Color variables*/
$primary:#005995;
$secondary:#ffbb00;
$pink:#ff2b93;
$teal:#00cccc;
$purple:#8c3feb ;
$success:#28c76f ;
$warning:#fcb32d;
$danger:#ff5c77;
$info:#2dc3fc;
$orange:#fe6931;
$dark:#191621;
$indigo: #1c2d5b;
$white:#fff;
$black:#000;
$light:#f3f7fd;

/*gray variables*/
$gray-100:#e9edf4;
$gray-200:#e5e7ec;
$gray-300:#d3d6dc;
$gray-400:#bcbfc5;
$gray-500:#a6a6cc;
$gray-600:#9292bf;
$gray-700:#6f6f9c;
$gray-800:#505086;
$gray-900:#31315d;

/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);

/*shadow variables*/
$shadow:0 4px 25px 0 rgba(13, 26, 53, 0.1);

$border:#e9edf4;



/*--- DATE PICKER ---*/

.select2-container--default.select2-container--disabled {
	.select2-selection--single, .select2-selection--multiple {
	  background-color: #f5f5f9;
	}
  }
  
  .ui-datepicker {
	background-color: $white;
	border: 1px solid $gray-100;
	font-family: inherit;
	font-size: inherit;
	padding: 10px;
	margin: 1px 0 0;
	display: none;
	width: auto !important;
	z-index: 5 !important;
  
	.ui-datepicker-header {
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  position: relative;
	  font-weight: 500;
	  font-size: 12px;
	  text-transform: uppercase;
	  color: #22252f;
	  padding: 0 0 5px;
	  letter-spacing: 1px;
	  border: 0;
	  background-color: transparent;
	  border-top-left-radius: 0px;
	  border-top-right-radius: 0px;
  
	  .ui-datepicker-next, .ui-datepicker-prev {
		text-indent: -99999px;
		color: #737688;
		top: 3px;
	  }
  
	  .ui-datepicker-next::before, .ui-datepicker-prev::before {
		font-size: 16px;
		font-family: 'Ionicons';
		position: absolute;
		top: -2px;
		text-indent: 0;
	  }
  
	  .ui-datepicker-next {
		&:hover::before, &:focus::before {
		  color: $dark;
		}
	  }
  
	  .ui-datepicker-prev {
		&:hover::before, &:focus::before {
		  color: $dark;
		}
	  }
  
	  .ui-datepicker-next {
		order: 3;
  
		&:before {
		  right: 10px;
		  content: '\f3d1';
		}
	  }
  
	  .ui-datepicker-prev:before {
		left: 10px;
		content: '\f3cf';
	  }
  
	  .ui-datepicker-next-hover, .ui-datepicker-prev-hover {
		color: #22252f;
		cursor: pointer;
		top: 1px;
		border: 0;
		background-color: transparent;
	  }
	}
  
	.ui-datepicker-title {
	  color: $dark;
	  font-weight: 700;
	  font-size: 13px;
	}
  
	.ui-datepicker-calendar {
	  margin: 0;
	  background-color: transparent;
	  border-bottom-right-radius: 0px;
	  border-bottom-left-radius: 0px;
  
	  th {
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 700;
		letter-spacing: 1px;
		padding: 6px 9px;
		color: $dark;
	  }
  
	  td {
		border: 1px solid $white;
		padding: 0;
		background-color: $white;
		text-align: right;
  
		&:last-child {
		  border-right: 0;
		}
  
		&.ui-datepicker-other-month .ui-state-default {
		  color: #737688;
		}
  
		span {
		  transition: all 0.2s ease-in-out;
		  padding: 5px;
		  color: #22252f;
		  padding: 6px 10px;
		  display: block;
		  font-weight: 400;
		  font-size: 12px;
		  border: 0;
		  border-radius: 1px;
		}
  
		a {
		  transition: all 0.2s ease-in-out;
		  padding: 5px;
		  color: #22252f;
		  padding: 6px 10px;
		  display: block;
		  font-weight: 400;
		  font-size: 12px;
		  border: 0;
		  border-radius: 1px;
  
		  &:hover {
			background-color: $white;
			color: #22252f;
		  }
		}
	  }
  
	  .ui-datepicker-today a {
		background-color: $primary;
		color: $white;
  
		&:hover, &:focus {
		  background-color: $primary;
		  color: $white;
		}
	  }
	}
  }
  
  @media (max-width: 320px) {
	.ui-datepicker .ui-datepicker-calendar th {
	  padding: 4px 0;
	  letter-spacing: normal;
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.ui-datepicker .ui-datepicker-calendar td {
	  span, a {
		transition: none;
	  }
	}
  }
  
  .ui-datepicker-multi {
	.ui-datepicker-group {
	  padding-right: 15px;
	  width: auto;
	  float: left;
  
	  .ui-datepicker-title {
		margin: auto;
	  }
  
	  .ui-datepicker-prev::before {
		left: 10px;
	  }
  
	  .ui-datepicker-next::before {
		right: 10px;
	  }
  
	  table {
		margin: 0;
	  }
	}
  
	.ui-datepicker-group-last {
	  padding-right: 0;
	}
  }
  
  .ui-datepicker-inline {
	border-color: $gray-100;
  }
  
  @media (min-width: 576px) {
	.ui-datepicker-inline {
	  max-width: 270px;
	}
  }
  
  .sp-container {
	background-color: $white;
	border-color: $gray-100;
	z-index: 200;
  
	button {
	  border: 0;
	  padding: 8px 15px;
	  background-image: none;
	  background-color: $background;
	  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	  font-size: 12px;
	  text-shadow: none;
	  text-transform: capitalize;
	  border-radius: 2px;
  
	  &:hover, &:focus {
		border: 0;
		background-image: none;
		background-color: #737688;
		text-shadow: none;
	  }
	}
  }
  
  .sp-button-container .sp-cancel {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 12px;
	text-transform: capitalize;
	color: #737688 !important;
  
	&:hover, &:focus {
	  color: $dark !important;
	  text-decoration: none;
	}
  }
  
  .sp-picker-container {
	border-left: 0;
  }
  
  .sp-replacer {
	border-color: $gray-100;
	background-color: $white;
  
	&:hover, &:focus {
	  border-color: #b4bdce;
	}
  
	&.sp-active {
	  border-color: #737688;
	}
  }
  
  .sp-dd {
	text-indent: -99999px;
	position: relative;
	width: 10px;
  
	&::before {
	  content: '\f280';
	  font-family: 'Ionicons';
	  font-size: 15px;
	  color: #737688;
	  position: absolute;
	  text-indent: 0;
	  left: 0;
	  z-index: 1;
	}
  }
  
  .sp-preview {
	border: 0;
  }
  
  .sp-dragger {
	background-color: transparent;
  }
  
  .sp-choose {
	background-color: $background;
  }
  
  .sp-palette .sp-thumb-el {
	&:hover, &.sp-thumb-active {
	  border-color: $dark;
	}
  }
  
  .datetimepicker {
	box-shadow: none;
	border-radius: 0;
	border-width: 1px;
	border-color: $gray-100;
	min-width: 270px;
	padding: 0 10px 10px;
  
	&::before {
	  display: none;
	}
  
	table {
	  width: 100%;
  
	  th {
		&.prev, &.next, &.switch {
		  background-color: $white;
		  color: #22252f;
		}
  
		&.prev, &.next {
		  position: relative;
		}
  
		&.prev span, &.next span {
		  position: absolute;
		  top: 0;
		  right: 0;
		  bottom: 0;
		  left: 0;
		}
  
		&.prev span::before, &.next span::before {
		  font-family: 'Ionicons';
		  position: absolute;
		  top: 50%;
		  font-size: 16px;
		  color: #14112d;
		  line-height: 0;
		}
  
		&.prev {
		  &:hover, &:focus {
			background-color: $white;
		  }
		}
  
		&.next {
		  &:hover, &:focus {
			background-color: $white;
		  }
		}
  
		&.prev {
		  &:hover span::before, &:focus span::before {
			color: $primary;
		  }
		}
  
		&.next {
		  &:hover span::before, &:focus span::before {
			color: $primary;
		  }
		}
  
		&.prev span::before {
		  content: '\f3cf';
		  left: 0;
		}
  
		&.next span::before {
		  content: '\f3d1';
		  right: 0;
		}
  
		&.switch {
		  text-transform: uppercase;
		  font-weight: 700;
		  font-size: 13px;
		  letter-spacing: 1px;
		  color: #14112d;
  
		  &:hover, &:focus {
			background-color: $white;
			color: $primary;
		  }
		}
	  }
  
	  td {
		font-size: 0.875rem;
		text-align: center;
  
		&.old, &.new {
		  color: #737688;
		}
  
		&.active {
		  background-color: $primary;
		  color: $white;
  
		  &:hover, &:focus {
			background-color: $primary;
			color: $white;
		  }
		}
	  }
  
	  span.active {
		background-color: $primary;
		color: $white;
  
		&:hover, &:focus {
		  background-color: $primary;
		  color: $white;
		}
	  }
	}
  
	.datetimepicker-days table thead tr {
	  &:first-child th {
		height: 38px;
	  }
  
	  &:last-child th {
		height: 25px;
		text-transform: uppercase;
		color: #22252f;
		font-weight: 700;
		font-size: 11px;
	  }
	}
  }
  
  /* jQuery Simple Datetimepicker */
  
  .main-datetimepicker {
	box-shadow: none;
	border-radius: 0;
	border-color: $gray-100;
	margin-top: 2px;
  
	> {
	  .datepicker_header {
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
  
		> .icon-home {
		  display: none;
		}
  
		a {
		  &:nth-child(2), &:last-child {
			display: block;
			position: relative;
			text-indent: -999999px;
		  }
  
		  &:nth-child(2)::before, &:last-child::before {
			font-family: 'Ionicons';
			position: absolute;
			top: 50%;
			line-height: 0;
			text-indent: 0;
			font-size: 13px;
		  }
  
		  &:nth-child(2)::before {
			content: '\f3cf';
			left: 0;
		  }
  
		  &:last-child::before {
			content: '\f3d1';
			right: 0;
		  }
		}
  
		span {
		  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		  text-transform: uppercase;
		}
	  }
  
	  .datepicker_inner_container {
		border-radius: 0;
		box-shadow: none;
		border-width: 0;
		display: flex;
		height: 225px;
		margin: 0;
		background-color: $white;
  
		&::after {
		  display: none;
		}
  
		> {
		  .datepicker_calendar {
			width: 230px;
			border-width: 0;
			border-radius: 0;
			font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
			padding: 5px;
			float: none;
			margin: 0;
  
			> table {
			  width: 100%;
			  height: 100%;
			}
  
			th {
			  font-size: 11px;
			  text-transform: uppercase;
			  color: #14112d;
			  font-weight: 700;
			  text-align: center;
			  padding: 8px 5px;
			}
  
			td {
			  font-size: 0.875rem;
			  font-weight: 400;
			  text-align: center;
			  padding: 5px;
  
			  &.day_another_month {
				color: #737688;
			  }
  
			  &.hover {
				background-color: $background;
				cursor: pointer;
			  }
  
			  &.active {
				background-color: $primary;
				color: $white;
			  }
			}
		  }
  
		  .datepicker_timelist {
			float: none;
			width: 70px;
			height: 100% !important;
			border-left: 1px solid $gray-100;
  
			> div.timelist_item {
			  padding: 5px 8px;
			  margin: 0 0 0 1px;
  
			  &:hover, &:focus {
				background-color: $background;
			  }
  
			  &.active {
				background-color: $primary;
				color: $white;
			  }
			}
		  }
		}
	  }
	}
  }
  
  /* Picker JS */
  
  .picker-title {
	font-size: 15px;
	font-weight: 500;
  }
  
  .picker-cell__header {
	text-transform: uppercase;
	font-size: 0.875rem;
	font-weight: 700;
	letter-spacing: .4px;
  }
  
  .picker-item {
	font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  
  .picker-picked {
	font-weight: 500;
	color: $primary;
  }
  
  /*--- DATE PICKER ---*/
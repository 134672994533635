/*--- HIGHLIGHT ---*/

.highlight {
    position: relative;
    background: #f7f6fd;
    padding: 15px;
    border-radius: 0 0 3px 3px;
  
    pre {
      margin-bottom: 0;
      max-height: 15rem;
      -moz-tab-size: 4;
      -o-tab-size: 4;
      tab-size: 4;
      -webkit-hyphens: none;
      -moz-hyphens: none;
      -ms-hyphens: none;
      hyphens: none;
    }
  }
  
  /*--- HIGHLIGHT ---*/
/*--- PROGRESS ---*/
@keyframes progress-bar-stripes {
	from {
	  background-position: 1rem 0;
	}
  
	to {
	  background-position: 0 0;
	}
  }
  
  .progress {
	display: flex;
	height: 1rem;
	overflow: hidden;
	font-size: 0.65625rem;
	background-color: $background;
	border-radius: 0px;
  }
  
  .progress-bar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: $white;
	text-align: center;
	white-space: nowrap;
	background-color: $primary;
	transition: width 0.6s ease;
  }
  
  @media (prefers-reduced-motion: reduce) {
	.progress-bar {
	  transition: none;
	}
  }
  
  .progress-bar-striped {
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-size: 1rem 1rem;
  }
  
  .progress-bar-animated {
	animation: progress-bar-stripes 1s linear infinite;
  }
  
  @media (prefers-reduced-motion: reduce) {
	.progress-bar-animated {
	  animation: none;
	}
  }
  
  .progress {
	height: auto;
	border-radius: 0;
  }
  
  .progress-bar {
	border-radius: 0;
	height: 10px;
	font-size: 11px;
  }
  
  .progress-bar-xs {
	height: 5px;
  }
  
  .progress-bar-sm {
	height: 8px;
  }
  
  .progress-bar-lg {
	height: 15px;
  }
  
  /*--- PROGRESS ---*/
  
  .progress-legend-style {
	list-style: none;
	position: absolute;
	top: 20px;
	right: 20px;
	padding: 0;
  
	li {
	  position: relative;
	  text-transform: uppercase;
	  font-size: 10px;
	  font-weight: 500;
	  letter-spacing: 1px;
	  padding-left: 15px;
	  line-height: .95;
	  float: left;
  
	  &:before {
		content: '';
		position: absolute;
		top: 1px;
		left: 3px;
		width: 8px;
		height: 8px;
	  }
  
	  &:first-child::before {
		background: $primary;
	  }
  
	  &:last-child::before {
		background: $success;
	  }
  
	  &:first-child {
		margin-right: 8px;
	  }
	}
  }
  
  .progress {
	background-color: #e6edf7;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	font-size: 0.703125rem;
  }
  
  /* CROSS BROWSER SELECTOR*/
  
  .custom-progress {
	/* RESETS */
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	height: 10px;
	border-radius: 0px;
	background-color: $background;
  
	&::-webkit-progress-bar {
	  background-color: $background;
	  border-radius: 0px;
	}
  
	&::-webkit-progress-value {
	  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	  background-color: $primary;
	  border-radius: 0px;
	}
  
	&::-moz-progress-bar {
	  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	  background-color: $primary;
	}
  
	&::-ms-fill {
	  border-radius: 0px;
	  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	  background-color: $primary;
	}
  }
  
  /* WEBKIT BROWSERS - CHROME, OPERA AND SAFARI */
  
  /* MOZILLA FIREFOX */
  
  /* MICROSOFT EDGE & IE */
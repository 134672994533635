/* ###### 5.1 Buttons  ###### */

.btn-main-primary {
	color: $white !important;
	background-color: $primary !important;
	border-color: $primary !important;

	&:hover {
	  color: $white;
	  background-color: #005995;
	  border-color: #005995;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 0.2rem rgba(104, 75, 219, 0.5);
	}

	&.disabled, &:disabled {
	  color: $white;
	  background-color: $primary;
	  border-color: $primary;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: #005995;
		border-color: #005995;
	  }
	}
  }

  .show > .btn-main-primary.dropdown-toggle {
	color: $white;
	background-color: #005995;
	border-color: #005995;
  }

  .btn-main-primary:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 0.2rem rgba(104, 75, 219, 0.5);
	}
  }

  .show > .btn-main-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(104, 75, 219, 0.5);
  }

  .btn-main-secondary {
	color: $white;
	background-color: $primary;
	border-color: #1a53ff;

	&:hover {
	  color: $white;
	  background-color: #0d49ff;
	  border-color: #005995;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
	}

	&.disabled, &:disabled {
	  color: $white;
	  background-color: $primary;
	  border-color: #1a53ff;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: #0040ff;
		border-color: #0036d9;
	  }
	}
  }

  .show > .btn-main-secondary.dropdown-toggle {
	color: $white;
	background-color: #0040ff;
	border-color: #0036d9;
  }

  .btn-main-secondary:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
	}
  }

  .show > .btn-main-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
  }

  .btn-rounded {
	border-radius: 50px;
  }

  .btn-with-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 38px;

	i {
	  line-height: 0;
	  font-size: 20px;
	  margin-right: 5px;

	  &.typcn {
		line-height: .9;
	  }

	  &.fas {
		font-size: 15px;
	  }

	  &.icon {
		font-size: 18px;
	  }
	}
  }

  .btn-icon {
	width: 38px;
	height: 38px;
	display: flex !important;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;

	i {
	  line-height: 0;
	  font-size: 24px;

	  &.typcn {
		line-height: .85;
	  }
	}
  }

  btn-sm, .btn-group-sm > .btn {
	padding: .25rem .5rem;
	font-size: .875rem;
	line-height: 1.5;
	height: auto !important;
  }

  /*--- BUTTON STYLES ---*/

  .btn, .sp-container button {
	display: inline-block;
	font-weight: 400;
	color: #22252f;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 0px;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
	.btn, .sp-container button {
	  transition: none;
	}
  }

  .btn:hover, .sp-container button:hover {
	color: #22252f;
	text-decoration: none;
  }

  .btn:focus, .sp-container button:focus, .btn.focus, .sp-container button.focus {
	outline: 0;
	box-shadow: none;
  }

  .btn.disabled, .sp-container button.disabled, .btn:disabled, .sp-container button:disabled {
	opacity: 0.65;
  }

  a.btn.disabled, fieldset:disabled a.btn {
	pointer-events: none;
  }

  .btn-primary {
	color: $white;
	background-color: $primary;
	border-color: $primary;

	&:hover {
	  color: $white;
	  background-color: #07436b;
	  border-color: #07436b;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(104, 75, 219, 0.2);
	}

	&.disabled, &:disabled {
	  color: $white;
	  background-color: $primary;
	  border-color: $primary;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: #005995;
		border-color: #005995;
	  }
	}
  }

  .show > .btn-primary.dropdown-toggle {
	color: $white;
	background-color: #005995;
	border-color: #005995;
  }

  .btn-primary:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(104, 75, 219, 0.2);
	}
  }

  .show > .btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(104, 75, 219, 0.2);
  }

  .btn-secondary {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;

	&:hover {
	  color: $white;
	  background-color: #e2aa11;
	  border-color: #e2aa11;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(247, 102, 148, 0.5);
	}

	&.disabled, &:disabled {
	  color: $white;
	  background-color: $secondary;
	  border-color: $secondary;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: $secondary;
		border-color: $secondary;
	  }
	}
  }

  .show > .btn-secondary.dropdown-toggle {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
  }

  .btn-secondary:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(247, 102, 148, 0.5);
	}
  }

  .show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(247, 102, 148, 0.5);
  }

  .btn-success {
	color: $white;
	background-color: $success;
	border-color: $success;

	&:hover {
	  color: $white;
	  background-color: #4bd2b0;
	  border-color: #4bd2b0;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(78, 220, 184, 0.5);
	}

	&.disabled, &:disabled {
	  color: $white;
	  background-color: $success;
	  border-color: $success;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: #4bd2b0;
		border-color: #267101;
	  }
	}
  }

  .show > .btn-success.dropdown-toggle {
	color: $white;
	background-color: #4bd2b0;
	border-color: #267101;
  }

  .btn-success:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(78, 220, 184, 0.5);
	}
  }

  .show > .btn-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(78, 220, 184, 0.5);
  }

  .btn-info {
	color: $white;
	background-color: $info;
	border-color: $info;

	&:hover {
	  color: $white;
	  background-color: #02a7e6;
	  border-color: #02a7e6;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(1, 184, 255, 0.5);
	}

	&.disabled, &:disabled {
	  color: $white;
	  background-color: $info;
	  border-color: $info;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: #02a7e6;
		border-color: #02a7e6;
	  }
	}
  }

  .show > .btn-info.dropdown-toggle {
	color: $white;
	background-color: #02a7e6;
	border-color: #02a7e6;
  }

  .btn-info:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(1, 184, 255, 0.5);
	}
  }

  .show > .btn-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(1, 184, 255, 0.5);
  }

  .btn-warning {
	color: #14112d;
	background-color: $warning;
	border-color: $warning;

	&:hover {
	  color: #14112d;
	  background-color: #ef9c05;
	  border-color: #ef9c05;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(255, 165, 2, 0.5);
	}

	&.disabled, &:disabled {
	  color: #14112d;
	  background-color: $warning;
	  border-color: $warning;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: #14112d;
		background-color: #ef9c05;
		border-color: #c69500;
	  }
	}
  }

  .show > .btn-warning.dropdown-toggle {
	color: #14112d;
	background-color: #ef9c05;
	border-color: #c69500;
  }

  .btn-warning:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(255, 165, 2, 0.5);
	}
  }

  .show > .btn-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(255, 165, 2, 0.5);
  }

  .btn-danger {
	color: $white;
	background-color: $danger;
	border-color: $danger;

	&:hover {
	  color: $white;
	  background-color: #ef4352;
	  border-color: #ef4352;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(255, 71, 87, 0.5);
	}

	&.disabled, &:disabled {
	  color: $white;
	  background-color: $danger;
	  border-color: $danger;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: #ef4352;
		border-color: #b21f2d;
	  }
	}
  }

  .show > .btn-danger.dropdown-toggle {
	color: $white;
	background-color: #ef4352;
	border-color: #b21f2d;
  }

  .btn-danger:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(255, 71, 87, 0.5);
	}
  }

  .show > .btn-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(255, 71, 87, 0.5);
  }

  .btn-light {
	color: #14112d;
	background-color: $background;
	border-color: $background;

	&:hover {
	  color: #14112d;
	  background-color: #dde0e9;
	  border-color: #d5d9e4;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
	}

	&.disabled, &:disabled {
	  color: #14112d;
	  background-color: $background;
	  border-color: $background;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: #14112d;
		background-color: #d5d9e4;
		border-color: #cdd2df;
	  }
	}
  }

  .show > .btn-light.dropdown-toggle {
	color: #14112d;
	background-color: #d5d9e4;
	border-color: #cdd2df;
  }

  .btn-light:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
	}
  }

  .show > .btn-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
  }

  .btn-dark {
	color: $white;
	background-color: $dark;
	border-color: $dark;

	&:hover {
	  color: $white;
	  background-color: #0e0c13;
	  border-color: #0e0c13;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(25, 22, 33, 0.5);
	}

	&.disabled, &:disabled {
	  color: $white;
	  background-color: $dark;
	  border-color: $dark;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: #0e0c13;
		border-color: #232b3b;
	  }
	}
  }

  .show > .btn-dark.dropdown-toggle {
	color: $white;
	background-color: #0e0c13;
	border-color: #232b3b;
  }

  .btn-dark:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(25, 22, 33, 0.5);
	}
  }

  .show > .btn-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(25, 22, 33, 0.5);
  }

  .btn-outline-primary {
	color: $primary;
	border-color: $primary;

	&:hover {
	  color: $white;
	  background-color: $primary;
	  border-color: $primary;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(104, 75, 219, 0.5);
	}

	&.disabled, &:disabled {
	  color: $primary;
	  background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: $primary;
		border-color: $primary;
	  }
	}
  }

  .show > .btn-outline-primary.dropdown-toggle {
	color: $white;
	background-color: $primary;
	border-color: $primary;
  }

  .btn-outline-primary:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(104, 75, 219, 0.5);
	}
  }

  .show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(104, 75, 219, 0.5);
  }

  .btn-outline-secondary {
	color: $secondary;
	border-color: $secondary;

	&:hover {
	  color: $white;
	  background-color: $secondary;
	  border-color: $secondary;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(247, 102, 148, 0.5);
	}

	&.disabled, &:disabled {
	  color: $secondary;
	  background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: $secondary;
		border-color: $secondary;
	  }
	}
  }

  .show > .btn-outline-secondary.dropdown-toggle {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
  }

  .btn-outline-secondary:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(247, 102, 148, 0.5);
	}
  }

  .show > .btn-outline-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(247, 102, 148, 0.5);
  }

  .btn-outline-success {
	color: $success;
	border-color: $success;

	&:hover {
	  color: $white;
	  background-color: $success;
	  border-color: $success;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(78, 220, 184, 0.5);
	}

	&.disabled, &:disabled {
	  color: $success;
	  background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: $success;
		border-color: $success;
	  }
	}
  }

  .show > .btn-outline-success.dropdown-toggle {
	color: $white;
	background-color: $success;
	border-color: $success;
  }

  .btn-outline-success:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(78, 220, 184, 0.5);
	}
  }

  .show > .btn-outline-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(78, 220, 184, 0.5);
  }

  .btn-outline-info {
	color: $info;
	border-color: $info;

	&:hover {
	  color: $white;
	  background-color: $info;
	  border-color: $info;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(23, 162, 184, 0.5);
	}

	&.disabled, &:disabled {
	  color: $info;
	  background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: $info;
		border-color: $info;
	  }
	}
  }

  .show > .btn-outline-info.dropdown-toggle {
	color: $white;
	background-color: $info;
	border-color: $info;
  }

  .btn-outline-info:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(23, 162, 184, 0.5);
	}
  }

  .show > .btn-outline-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(23, 162, 184, 0.5);
  }

  .btn-outline-warning {
	color: $warning;
	border-color: $warning;

	&:hover {
	  color: #14112d;
	  background-color: $warning;
	  border-color: $warning;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(255, 193, 7, 0.5);
	}

	&.disabled, &:disabled {
	  color: $warning;
	  background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: #14112d;
		background-color: $warning;
		border-color: $warning;
	  }
	}
  }

  .show > .btn-outline-warning.dropdown-toggle {
	color: #14112d;
	background-color: $warning;
	border-color: $warning;
  }

  .btn-outline-warning:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(255, 193, 7, 0.5);
	}
  }

  .show > .btn-outline-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(255, 193, 7, 0.5);
  }

  .btn-outline-danger {
	color: $danger;
	border-color: $danger;

	&:hover {
	  color: $white;
	  background-color: $danger;
	  border-color: $danger;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(220, 53, 69, 0.5);
	}

	&.disabled, &:disabled {
	  color: $danger;
	  background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: $danger;
		border-color: $danger;
	  }
	}
  }

  .show > .btn-outline-danger.dropdown-toggle {
	color: $white;
	background-color: $danger;
	border-color: $danger;
  }

  .btn-outline-danger:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(220, 53, 69, 0.5);
	}
  }

  .show > .btn-outline-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(220, 53, 69, 0.5);
  }

  .btn-outline-light {
	color: $background;
	border-color: $background;

	&:hover {
	  color: #14112d;
	  background-color: $background;
	  border-color: $background;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(244, 245, 248, 0.5);
	}

	&.disabled, &:disabled {
	  color: $background;
	  background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: #14112d;
		background-color: $background;
		border-color: $background;
	  }
	}
  }

  .show > .btn-outline-light.dropdown-toggle {
	color: #14112d;
	background-color: $background;
	border-color: $background;
  }

  .btn-outline-light:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(244, 245, 248, 0.5);
	}
  }

  .show > .btn-outline-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(244, 245, 248, 0.5);
  }

  .btn-outline-dark {
	color: $dark;
	border-color: $dark;

	&:hover {
	  color: $white;
	  background-color: $dark;
	  border-color: $dark;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(59, 72, 99, 0.5);
	}

	&.disabled, &:disabled {
	  color: $dark;
	  background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: $dark;
		border-color: $dark;
	  }
	}
  }

  .show > .btn-outline-dark.dropdown-toggle {
	color: $white;
	background-color: $dark;
	border-color: $dark;
  }

  .btn-outline-dark:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(59, 72, 99, 0.5);
	}
  }

  .show > .btn-outline-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(59, 72, 99, 0.5);
  }

  .btn-link {
	font-weight: 400;
	color: $primary;
	text-decoration: none;

	&:hover {
	  color: #005995;
	  text-decoration: none;
	}

	&:focus, &.focus {
	  text-decoration: none;
	  box-shadow: none;
	}

	&:disabled, &.disabled {
	  color: #737688;
	  pointer-events: none;
	}
  }

  .btn-lg, .btn-group-lg > .btn, .sp-container .btn-group-lg > button {
	padding: 0.5rem 1rem;
	font-size: 1.09375rem;
	line-height: 1.5;
	border-radius: 0.3rem;
	height: calc(1.5em + 1rem + 2px) !important;
  }

  .btn-sm, .btn-group-sm > .btn, .sp-container .btn-group-sm > button {
	padding: 0.25rem 0.5rem !important;
	font-size: 0.76562rem;
	line-height: 1.5;
	border-radius: 0.2rem;
	height: calc(1.5em + 0.5rem + 5px) !important;
  }

  .btn-block {
	display: block;
	width: 100%;

	+ .btn-block {
	  margin-top: 0.5rem;
	}
  }

  .btn, .sp-container button {
	border-width: 0;
	line-height: 1.538;
	padding: 9px 20px;
	border-radius: 0px;
	transition: none;
	height: 38px;
  }

  .btn:active, .sp-container button:active, .btn:focus, .sp-container button:focus {
	box-shadow: none;
  }

  .btn-light {
	color: #14112d;
	background-color: #f2f6fd;
	border-color: $gray-100;

	&:hover {
	  color: #14112d;
	  background-color: $gray-100;
	  border-color: $gray-100;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(239, 239, 247, 0.5);
	}

	&.disabled, &:disabled {
	  color: #14112d;
	  background-color: $background;
	  border-color: $gray-100;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: #14112d;
		background-color: $background;
		border-color: $gray-100;
	  }
	}
  }

  .show > .btn-light.dropdown-toggle {
	color: #14112d;
	background-color: $background;
	border-color: $gray-100;
  }

  .btn-light:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(239, 239, 247, 0.5);
	}
  }

  .show > .btn-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(239, 239, 247, 0.5);
  }

  .btn-indigo {
	color: $white;
	background-color: $indigo;
	border-color: $indigo;

	&:hover {
	  color: $white;
	  background-color: #005995;
	  border-color: #005995;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(104, 75, 219, 0.5);
	}

	&.disabled, &:disabled {
	  color: $white;
	  background-color: $indigo;
	  border-color: $indigo;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: #005995;
		border-color: #005995;
	  }
	}
  }

  .show > .btn-indigo.dropdown-toggle {
	color: $white;
	background-color: #005995;
	border-color: #005995;
  }

  .btn-indigo:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(104, 75, 219, 0.5);
	}
  }

  .show > .btn-indigo.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(104, 75, 219, 0.5);
  }

  .btn-purple {
	color: $white;
	background-color: $purple;
	border-color: #643ab0;

	&:hover {
	  color: $white;
	  background-color: #5e37a6;
	  border-color: #4e2d89;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(123, 87, 188, 0.5);
	}

	&.disabled, &:disabled {
	  color: $white;
	  background-color: $purple;
	  border-color: #643ab0;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: #59339d;
		border-color: #482a80;
	  }
	}
  }

  .show > .btn-purple.dropdown-toggle {
	color: $white;
	background-color: #59339d;
	border-color: #482a80;
  }

  .btn-purple:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(123, 87, 188, 0.5);
	}
  }

  .show > .btn-purple.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(123, 87, 188, 0.5);
  }

  .btn-pink {
	color: $white;
	background-color: $pink;
	border-color: #d80069;

	&:hover {
	  color: $white;
	  background-color: #cb0062;
	  border-color: #a50050;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(221, 38, 127, 0.5);
	}

	&.disabled, &:disabled {
	  color: $white;
	  background-color: $pink;
	  border-color: #d80069;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: #be005c;
		border-color: #98004a;
	  }
	}
  }

  .show > .btn-pink.dropdown-toggle {
	color: $white;
	background-color: #be005c;
	border-color: #98004a;
  }

  .btn-pink:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(221, 38, 127, 0.5);
	}
  }

  .show > .btn-pink.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(221, 38, 127, 0.5);
  }

  .btn-gray-500 {
	color: #14112d;
	background-color: #737688;
	border-color: #8896af;

	&:hover {
	  color: $white;
	  background-color: #808faa;
	  border-color: #697b9a;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(120, 133, 158, 0.5);
	}

	&.disabled, &:disabled {
	  color: #14112d;
	  background-color: #737688;
	  border-color: #8896af;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: #7988a4;
		border-color: #637493;
	  }
	}
  }

  .show > .btn-gray-500.dropdown-toggle {
	color: $white;
	background-color: #7988a4;
	border-color: #637493;
  }

  .btn-gray-500:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(120, 133, 158, 0.5);
	}
  }

  .show > .btn-gray-500.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(120, 133, 158, 0.5);
  }

  .btn-gray-700 {
	color: $white;
	background-color: #22252f;
	border-color: #4f5c73;

	&:hover {
	  color: $white;
	  background-color: #49566b;
	  border-color: #3a4455;
	}

	&:focus, &.focus {
	  box-shadow: 0 0 0 1px rgba(105, 116, 136, 0.5);
	}

	&.disabled, &:disabled {
	  color: $white;
	  background-color: #22252f;
	  border-color: #4f5c73;
	}

	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: #445064;
		border-color: #353e4d;
	  }
	}
  }

  .show > .btn-gray-700.dropdown-toggle {
	color: $white;
	background-color: #445064;
	border-color: #353e4d;
  }

  .btn-gray-700:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 1px rgba(105, 116, 136, 0.5);
	}
  }

  .show > .btn-gray-700.dropdown-toggle:focus {
	box-shadow: 0 0 0 1px rgba(105, 116, 136, 0.5);
  }

  [class*=btn-outline-] {
	padding: 0.4rem 1rem;
	line-height: 1.657;
	border-width: 1px;
	background-color: transparent;
  }

  .btn-outline-indigo {
	border-color: $primary;
	color: $primary;

	&:hover, &:focus {
	  background-color: $primary;
	  color: $white;
	}
  }

  .btn-outline-light {
	border-color: #737688;
	color: #737688;

	&:hover, &:focus {
	  background-color: #737688;
	}
  }

  .btn-group, .btn-group-vertical {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
  }

  .btn-group > .btn, .sp-container .btn-group > button, .btn-group-vertical > .btn, .sp-container .btn-group-vertical > button {
	position: relative;
	flex: 1 1 auto;
  }

  .btn-group > .btn:hover, .sp-container .btn-group > button:hover, .btn-group-vertical > .btn:hover, .sp-container .btn-group-vertical > button:hover, .btn-group > .btn:focus, .sp-container .btn-group > button:focus, .btn-group > .btn:active, .sp-container .btn-group > button:active, .btn-group > .btn.active, .sp-container .btn-group > button.active, .btn-group-vertical > .btn:focus, .sp-container .btn-group-vertical > button:focus, .btn-group-vertical > .btn:active, .sp-container .btn-group-vertical > button:active, .btn-group-vertical > .btn.active, .sp-container .btn-group-vertical > button.active {
	z-index: 1;
  }

  .btn-toolbar {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;

	.input-group {
	  width: auto;
	}
  }

  .btn-group > .btn:not(:first-child), .sp-container .btn-group > button:not(:first-child) {
	margin-left: -1px;
  }

  .btn-group > {
	.btn-group:not(:first-child) {
	  margin-left: -1px;
	}

	.btn:not(:last-child):not(.dropdown-toggle) {
	  border-top-right-radius: 0;
	  border-bottom-right-radius: 0;
	}
  }

  .sp-container .btn-group > button:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn, .sp-container .btn-group > .btn-group:not(:last-child) > button {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
  }

  .btn-group > .btn:not(:first-child), .sp-container .btn-group > button:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn, .sp-container .btn-group > .btn-group:not(:first-child) > button {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
  }

  .btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .sp-container .btn-group-sm > button + .dropdown-toggle-split {
	padding-right: 0.375rem;
	padding-left: 0.375rem;
  }

  .btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .sp-container .btn-group-lg > button + .dropdown-toggle-split {
	padding-right: 0.75rem;
	padding-left: 0.75rem;
  }

  .btn-group-vertical {
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;

	> .btn {
	  width: 100%;
	}
  }

  .sp-container .btn-group-vertical > button {
	width: 100%;
  }

  .btn-group-vertical > {
	.btn-group {
	  width: 100%;
	}

	.btn:not(:first-child) {
	  margin-top: -1px;
	}
  }

  .sp-container .btn-group-vertical > button:not(:first-child) {
	margin-top: -1px;
  }

  .btn-group-vertical > {
	.btn-group:not(:first-child) {
	  margin-top: -1px;
	}

	.btn:not(:last-child):not(.dropdown-toggle) {
	  border-bottom-right-radius: 0;
	  border-bottom-left-radius: 0;
	}
  }

  .sp-container .btn-group-vertical > button:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn, .sp-container .btn-group-vertical > .btn-group:not(:last-child) > button {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
  }

  .btn-group-vertical > .btn:not(:first-child), .sp-container .btn-group-vertical > button:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) > .btn, .sp-container .btn-group-vertical > .btn-group:not(:first-child) > button {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
  }

  .btn-group-toggle > .btn, .sp-container .btn-group-toggle > button, .btn-group-toggle > .btn-group > .btn, .sp-container .btn-group-toggle > .btn-group > button {
	margin-bottom: 0;
  }

  .btn-group-toggle > .btn input[type="radio"], .sp-container .btn-group-toggle > button input[type="radio"], .btn-group-toggle > .btn input[type="checkbox"], .sp-container .btn-group-toggle > button input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="radio"], .sp-container .btn-group-toggle > .btn-group > button input[type="radio"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"], .sp-container .btn-group-toggle > .btn-group > button input[type="checkbox"] {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
  }

  .btn-icon-list {
	display: flex;
	align-items: center;

	.btn + .btn, .sp-container button + .btn {
	  margin-left: 5px;
	}
  }

  .sp-container .btn-icon-list button + .btn, .btn-icon-list .sp-container .btn + button, .sp-container .btn-icon-list .btn + button, .btn-icon-list .sp-container button + button, .sp-container .btn-icon-list button + button {
	margin-left: 5px;
  }

/*--- FORM ELEMENTS ---*/

.form-control {
	display: block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5;
	color: #686868;
	background-color: $white;
	background-clip: padding-box;
	border: 1px solid $gray-100;
	border-radius: 0px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

	&::-ms-expand {
	  background-color: transparent;
	  border: 0;
	}

	height: 38px;
	border-radius: 0.3rem;

	&:focus {
	  border-color: #737688;
	  box-shadow: none;
	}
  }

  @media (prefers-reduced-motion: reduce) {
	.form-control {
	  transition: none;
	}
  }

  .custom-file, .custom-file-input {
	height: 38px;
  }

  .custom-file-label {
	height: 38px;
	line-height: 1.8;
	border-radius: 0;

	&::after {
	  line-height: 1.8;
	  border-radius: 0;
	  height: auto;
	}
  }

  .form-label {
	display: block;
	margin-bottom: 5px;
	color: #22252f;
	font-size: 13px;
  }

  .form-control {
	&:focus {
	  color: #22252f;
	  background-color: $white;
	  border-color: $primary;
	  outline: 0;
	}

	&::placeholder {
	  color: #737688;
	  opacity: 1;
	}

	&:disabled, &[readonly] {
	  background-color: $background;
	  opacity: 1;
	}
  }

  select.form-control:focus::-ms-value {
	color: #22252f;
	background-color: $white;
  }

  .form-control-file, .form-control-range {
	display: block;
	width: 100%;
  }

  .col-form-label {
	padding-top: calc(0.375rem + 1px);
	padding-bottom: calc(0.375rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.5;
  }

  .col-form-label-lg {
	padding-top: calc(0.5rem + 1px);
	padding-bottom: calc(0.5rem + 1px);
	font-size: 1.09375rem;
	line-height: 1.5;
  }

  .col-form-label-sm {
	padding-top: calc(0.25rem + 1px);
	padding-bottom: calc(0.25rem + 1px);
	font-size: 0.76562rem;
	line-height: 1.5;
  }

  .form-control-plaintext {
	display: block;
	width: 100%;
	padding-top: 0.375rem;
	padding-bottom: 0.375rem;
	margin-bottom: 0;
	line-height: 1.5;
	color: #22252f;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0;

	&.form-control-sm, &.form-control-lg {
	  padding-right: 0;
	  padding-left: 0;
	}
  }

  .form-control-sm {
	height: calc(1.5em + 0.5rem + 2px);
	padding: 0.25rem 0.5rem;
	font-size: 0.76562rem;
	line-height: 1.5;
	border-radius: 0rem;
  }

  .form-control-lg {
	height: calc(1.5em + 1rem + 2px);
	padding: 0.5rem 1rem;
	font-size: 1.09375rem;
	line-height: 1.5;
	border-radius: 0rem;
  }

  select.form-control {
	&[size], &[multiple] {
	  height: auto;
	}
  }

  textarea.form-control {
	height: auto;
  }

  .form-group {
	margin-bottom: 1rem;
  }

  .form-text {
	display: block;
	margin-top: 0.25rem;
  }

  .form-row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;

	> {
	  .col, [class*="col-"] {
		padding-right: 5px;
		padding-left: 5px;
	  }
	}
  }

  .form-check {
	position: relative;
	display: block;
	padding-left: 1.25rem;
  }

  .form-check-input {
	position: absolute;
	margin-top: 0.3rem;
	margin-left: -1.25rem;

	&:disabled ~ .form-check-label {
	  color: #737688;
	}
  }

  .form-check-label {
	margin-bottom: 0;
  }

  .form-check-inline {
	display: inline-flex;
	align-items: center;
	padding-left: 0;
	margin-right: 0.75rem;

	.form-check-input {
	  position: static;
	  margin-top: 0;
	  margin-right: 0.3125rem;
	  margin-left: 0;
	}
  }

  .form-inline {
	display: flex;
	flex-flow: row wrap;
	align-items: center;

	.form-check {
	  width: 100%;
	}
  }

  @media (min-width: 576px) {
	.form-inline {
	  label {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 0;
	  }

	  .form-group {
		display: flex;
		flex: 0 0 auto;
		flex-flow: row wrap;
		align-items: center;
		margin-bottom: 0;
	  }

	  .form-control {
		display: inline-block;
		width: auto;
		vertical-align: middle;
	  }

	  .form-control-plaintext {
		display: inline-block;
	  }

	  .input-group, .custom-select {
		width: auto;
	  }

	  .form-check {
		display: flex;
		align-items: center;
		justify-content: center;
		width: auto;
		padding-left: 0;
	  }

	  .form-check-input {
		position: relative;
		flex-shrink: 0;
		margin-top: 0;
		margin-right: 0.25rem;
		margin-left: 0;
	  }

	  .custom-control {
		align-items: center;
		justify-content: center;
	  }

	  .custom-control-label {
		margin-bottom: 0;
	  }
	}
  }

  .form-control-sm {
	border-radius: 0;
  }

  .form-group-rdiobox {
	display: flex;
	align-items: center;

	.rdiobox {
	  margin-bottom: 0;

	  + .rdiobox {
		margin-left: 30px;
	  }

	  span {
		padding-left: 0;
	  }
	}
  }

  .valid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: $success;
  }

  .valid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: .1rem;
	font-size: 0.76562rem;
	line-height: 1.5;
	color: $white;
	background-color: rgba(78, 220, 184, 0.9);
	border-radius: 0px;
  }

  .was-validated .form-control:valid, .form-control.is-valid, input.form-control.parsley-success, textarea.form-control.parsley-success {
	border-color: $success;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233bb001' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: center right calc(0.375em + 0.1875rem);
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, input.form-control.parsley-success:focus, textarea.form-control.parsley-success:focus {
	border-color: $success;
	box-shadow: 0 0 0 0.2rem rgba(78, 220, 184, 0.25);
  }

  .was-validated .form-control:valid ~ {
	.valid-feedback, .valid-tooltip {
	  display: block;
	}
  }

  .form-control.is-valid ~ .valid-feedback, input.form-control.parsley-success ~ .valid-feedback, textarea.form-control.parsley-success ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip, input.form-control.parsley-success ~ .valid-tooltip, textarea.form-control.parsley-success ~ .valid-tooltip {
	display: block;
  }

  .was-validated textarea.form-control:valid {
	padding-right: calc(1.5em + 0.75rem);
	background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
  }

  textarea.form-control {
	&.is-valid, &.parsley-success {
	  padding-right: calc(1.5em + 0.75rem);
	  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
	}
  }

  .was-validated .custom-select:valid, .custom-select.is-valid, input.custom-select.parsley-success, textarea.custom-select.parsley-success {
	border-color: $success;
	padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem (center / 8px) 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233bb001' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") $white no-repeat center right (1.75rem / calc(0.75em + 0.375rem)) calc(0.75em + 0.375rem);
  }

  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus, input.custom-select.parsley-success:focus, textarea.custom-select.parsley-success:focus {
	border-color: $success;
	box-shadow: 0 0 0 0.2rem rgba(78, 220, 184, 0.25);
  }

  .was-validated .custom-select:valid ~ {
	.valid-feedback, .valid-tooltip {
	  display: block;
	}
  }

  .custom-select.is-valid ~ .valid-feedback, input.custom-select.parsley-success ~ .valid-feedback, textarea.custom-select.parsley-success ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip, input.custom-select.parsley-success ~ .valid-tooltip, textarea.custom-select.parsley-success ~ .valid-tooltip {
	display: block;
  }

  .was-validated .form-control-file:valid ~ {
	.valid-feedback, .valid-tooltip {
	  display: block;
	}
  }

  .form-control-file.is-valid ~ .valid-feedback, input.form-control-file.parsley-success ~ .valid-feedback, textarea.form-control-file.parsley-success ~ .valid-feedback, .form-control-file.is-valid ~ .valid-tooltip, input.form-control-file.parsley-success ~ .valid-tooltip, textarea.form-control-file.parsley-success ~ .valid-tooltip {
	display: block;
  }

  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label, input.form-check-input.parsley-success ~ .form-check-label, textarea.form-check-input.parsley-success ~ .form-check-label {
	color: $success;
  }

  .was-validated .form-check-input:valid ~ {
	.valid-feedback, .valid-tooltip {
	  display: block;
	}
  }

  .form-check-input.is-valid ~ .valid-feedback, input.form-check-input.parsley-success ~ .valid-feedback, textarea.form-check-input.parsley-success ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip, input.form-check-input.parsley-success ~ .valid-tooltip, textarea.form-check-input.parsley-success ~ .valid-tooltip {
	display: block;
  }

  .was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label, input.custom-control-input.parsley-success ~ .custom-control-label, textarea.custom-control-input.parsley-success ~ .custom-control-label {
	color: $success;
  }

  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before, input.custom-control-input.parsley-success ~ .custom-control-label::before, textarea.custom-control-input.parsley-success ~ .custom-control-label::before {
	border-color: $success;
  }

  .was-validated .custom-control-input:valid ~ {
	.valid-feedback, .valid-tooltip {
	  display: block;
	}
  }

  .custom-control-input.is-valid ~ .valid-feedback, input.custom-control-input.parsley-success ~ .valid-feedback, textarea.custom-control-input.parsley-success ~ .valid-feedback, .custom-control-input.is-valid ~ .valid-tooltip, input.custom-control-input.parsley-success ~ .valid-tooltip, textarea.custom-control-input.parsley-success ~ .valid-tooltip {
	display: block;
  }

  .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before, input.custom-control-input.parsley-success:checked ~ .custom-control-label::before, textarea.custom-control-input.parsley-success:checked ~ .custom-control-label::before {
	border-color: #4ce301;
	background-color: #4ce301;
  }

  .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before, input.custom-control-input.parsley-success:focus ~ .custom-control-label::before, textarea.custom-control-input.parsley-success:focus ~ .custom-control-label::before {
	box-shadow: 0 0 0 0.2rem rgba(78, 220, 184, 0.25);
  }

  .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before, input.custom-control-input.parsley-success:focus:not(:checked) ~ .custom-control-label::before, textarea.custom-control-input.parsley-success:focus:not(:checked) ~ .custom-control-label::before, .was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label, input.custom-file-input.parsley-success ~ .custom-file-label, textarea.custom-file-input.parsley-success ~ .custom-file-label {
	border-color: $success;
  }

  .was-validated .custom-file-input:valid ~ {
	.valid-feedback, .valid-tooltip {
	  display: block;
	}
  }

  .custom-file-input.is-valid ~ .valid-feedback, input.custom-file-input.parsley-success ~ .valid-feedback, textarea.custom-file-input.parsley-success ~ .valid-feedback, .custom-file-input.is-valid ~ .valid-tooltip, input.custom-file-input.parsley-success ~ .valid-tooltip, textarea.custom-file-input.parsley-success ~ .valid-tooltip {
	display: block;
  }

  .was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label, input.custom-file-input.parsley-success:focus ~ .custom-file-label, textarea.custom-file-input.parsley-success:focus ~ .custom-file-label {
	border-color: $success;
	box-shadow: 0 0 0 0.2rem rgba(78, 220, 184, 0.25);
  }

  .invalid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: $danger;
  }

  .invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: .1rem;
	font-size: 0.76562rem;
	line-height: 1.5;
	color: $white;
	background-color: rgba(220, 53, 69, 0.9);
	border-radius: 0px;
  }

  .was-validated .form-control:invalid, .form-control.is-invalid, input.form-control.error, textarea.form-control.error {
	border-color: $danger;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
	background-repeat: no-repeat;
	background-position: center right calc(0.375em + 0.1875rem);
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, input.form-control.error:focus, textarea.form-control.error:focus {
	border-color: $danger;
	box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  .was-validated .form-control:invalid ~ {
	.invalid-feedback, .invalid-tooltip {
	  display: block;
	}
  }

  .form-control.is-invalid ~ .invalid-feedback, input.form-control.error ~ .invalid-feedback, textarea.form-control.error ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, input.form-control.error ~ .invalid-tooltip, textarea.form-control.error ~ .invalid-tooltip {
	display: block;
  }

  .was-validated textarea.form-control:invalid {
	padding-right: calc(1.5em + 0.75rem);
	background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
  }

  textarea.form-control {
	&.is-invalid, &.error {
	  padding-right: calc(1.5em + 0.75rem);
	  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
	}
  }

  .was-validated .custom-select:invalid, .custom-select.is-invalid, input.custom-select.error, textarea.custom-select.error {
	border-color: $danger;
	padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem (center / 8px) 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") $white no-repeat center right (1.75rem / calc(0.75em + 0.375rem)) calc(0.75em + 0.375rem);
  }

  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus, input.custom-select.error:focus, textarea.custom-select.error:focus {
	border-color: $danger;
	box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  .was-validated .custom-select:invalid ~ {
	.invalid-feedback, .invalid-tooltip {
	  display: block;
	}
  }

  .custom-select.is-invalid ~ .invalid-feedback, input.custom-select.error ~ .invalid-feedback, textarea.custom-select.error ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip, input.custom-select.error ~ .invalid-tooltip, textarea.custom-select.error ~ .invalid-tooltip {
	display: block;
  }

  .was-validated .form-control-file:invalid ~ {
	.invalid-feedback, .invalid-tooltip {
	  display: block;
	}
  }

  .form-control-file.is-invalid ~ .invalid-feedback, input.form-control-file.error ~ .invalid-feedback, textarea.form-control-file.error ~ .invalid-feedback, .form-control-file.is-invalid ~ .invalid-tooltip, input.form-control-file.error ~ .invalid-tooltip, textarea.form-control-file.error ~ .invalid-tooltip {
	display: block;
  }

  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label, input.form-check-input.error ~ .form-check-label, textarea.form-check-input.error ~ .form-check-label {
	color: $danger;
  }

  .was-validated .form-check-input:invalid ~ {
	.invalid-feedback, .invalid-tooltip {
	  display: block;
	}
  }

  .form-check-input.is-invalid ~ .invalid-feedback, input.form-check-input.error ~ .invalid-feedback, textarea.form-check-input.error ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip, input.form-check-input.error ~ .invalid-tooltip, textarea.form-check-input.error ~ .invalid-tooltip {
	display: block;
  }

  .was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label, input.custom-control-input.error ~ .custom-control-label, textarea.custom-control-input.error ~ .custom-control-label {
	color: $danger;
  }

  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before, input.custom-control-input.error ~ .custom-control-label::before, textarea.custom-control-input.error ~ .custom-control-label::before {
	border-color: $danger;
  }

  .was-validated .custom-control-input:invalid ~ {
	.invalid-feedback, .invalid-tooltip {
	  display: block;
	}
  }

  .custom-control-input.is-invalid ~ .invalid-feedback, input.custom-control-input.error ~ .invalid-feedback, textarea.custom-control-input.error ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-tooltip, input.custom-control-input.error ~ .invalid-tooltip, textarea.custom-control-input.error ~ .invalid-tooltip {
	display: block;
  }

  .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before, input.custom-control-input.error:checked ~ .custom-control-label::before, textarea.custom-control-input.error:checked ~ .custom-control-label::before {
	border-color: #e4606d;
	background-color: #e4606d;
  }

  .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before, input.custom-control-input.error:focus ~ .custom-control-label::before, textarea.custom-control-input.error:focus ~ .custom-control-label::before {
	box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before, input.custom-control-input.error:focus:not(:checked) ~ .custom-control-label::before, textarea.custom-control-input.error:focus:not(:checked) ~ .custom-control-label::before, .was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label, input.custom-file-input.error ~ .custom-file-label, textarea.custom-file-input.error ~ .custom-file-label {
	border-color: $danger;
  }

  .was-validated .custom-file-input:invalid ~ {
	.invalid-feedback, .invalid-tooltip {
	  display: block;
	}
  }

  .custom-file-input.is-invalid ~ .invalid-feedback, input.custom-file-input.error ~ .invalid-feedback, textarea.custom-file-input.error ~ .invalid-feedback, .custom-file-input.is-invalid ~ .invalid-tooltip, input.custom-file-input.error ~ .invalid-tooltip, textarea.custom-file-input.error ~ .invalid-tooltip {
	display: block;
  }

  .was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label, input.custom-file-input.error:focus ~ .custom-file-label, textarea.custom-file-input.error:focus ~ .custom-file-label {
	border-color: $danger;
	box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  /*--- FORM ELEMENTS ---*/

  /* ###### 5.3 Forms   ###### */

  /* Radio Box */

  .rdiobox {
	font-weight: normal;
	position: relative;
	display: block;
	line-height: 18px;

	span {
	  padding-left: 13px;

	  &:before, &:after {
		line-height: 18px;
		position: absolute;
	  }

	  &:before {
		content: '';
		width: 16px;
		height: 16px;
		background-color: $white;
		border: 1px solid #737688;
		border-radius: 50px;
		top: 2px;
		left: 0;
	  }

	  &:after {
		content: '';
		width: 6px;
		height: 6px;
		background-color: $white;
		border-radius: 50px;
		top: 7px;
		left: 5px;
		display: none;
	  }

	  &:empty {
		padding-left: 0;
		width: 16px;
		display: block;
	  }
	}

	input[type='radio'] {
	  opacity: 0;
	  margin: 0;

	  &:checked + span {
		&:before {
		  border-color: transparent;
		  background-color: $primary;
		}

		&:after {
		  display: block;
		}
	  }

	  &[disabled] + span {
		opacity: .75;

		&:before, &:after {
		  opacity: .75;
		}
	  }
	}
  }

  .rdiobox-inline {
	display: inline-block;
  }

  .main-toggle {
	width: 60px;
	height: 25px;
	background-color: #b4bdce;
	padding: 2px;
	position: relative;
	overflow: hidden;

	span {
	  position: absolute;
	  top: 3px;
	  bottom: 3px;
	  left: 3px;
	  display: block;
	  width: 20px;
	  background-color: $white;
	  cursor: pointer;
	  transition: all 0.2s ease-in-out;

	  &::before, &::after {
		position: absolute;
		font-size: 10px;
		font-weight: 500;
		letter-spacing: .5px;
		text-transform: uppercase;
		color: $white;
		top: 2px;
		line-height: 1.38;
	  }

	  &::before {
		content: 'on';
		left: -25px;
	  }

	  &::after {
		content: 'off';
		right: -29px;
	  }
	}

	&.on {
	  background-color: $primary;

	  span {
		left: 37px;
	  }
	}
  }

  @media (prefers-reduced-motion: reduce) {
	.main-toggle span {
	  transition: none;
	}
  }

  .main-toggle-secondary.on {
	background-color: $primary;
  }

  .main-toggle-success.on {
	background-color: $success;
  }

  .main-toggle-dark.on {
	background-color: #14112d;
  }

  .main-form-group {
	padding: 12px 15px;
	border: 1px solid $gray-100;

	&.focus {
	  border-color: #b4bdce;
	  box-shadow: 0 0 0 2px rgba(0, 110, 255, 0.16);
	}

	.form-label {
	  font-size: 13px;
	  margin-bottom: 2px;
	  font-weight: 400;
	  color: #737688;
	}

	.form-control {
	  padding: 0;
	  border-width: 0;
	  height: 25px;
	  color: #14112d;
	  font-weight: 500;

	  &:focus {
		box-shadow: none;
	  }
	}
  }

  /*--- PARSELY ---*/

  .morris-hover.morris-default-style {
	border-radius: 0;
  }

  input.error:focus, textarea.error:focus, input.parsley-success:focus, textarea.parsley-success:focus {
	box-shadow: none !important;
  }

  .parsley-checkbox {
	&.error {
	  display: inline-block;
	  padding: 10px;
	  border: 1px solid $danger;
	}

	&.parsley-success {
	  display: inline-block;
	  padding: 10px;
	  border: 1px solid $success;
	}
  }

  .errors-list {
	color: $danger;
	list-style: none;
	font-size: 12px;
	padding: 0;
	margin-bottom: 0;

	li {
	  margin: 5px 0 0;
	}
  }

  .parsley-style-1 {
	.parsley-input {
	  &.error .form-control {
		background-color: #fef9f9;
		border-color: $danger;
		border-bottom-width: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	  }

	  &.parsley-success .form-control {
		border-color: $success;
	  }
	}

	.parsley-checkbox.error {
	  border: 1px solid $danger;
	  background-color: #fef9f9;
	  border-top-left-radius: 2px;
	  border-top-right-radius: 2px;
	}

	.parsley-select.error .select2-container--default .select2-selection--single {
	  background-color: #fef9f9;
	  border-bottom-right-radius: 0;
	  border-bottom-left-radius: 0;
	}

	.errors-list.filled {
	  background-color: $danger;
	  color: $white;
	  padding: 7px 10px;
	  border-bottom-right-radius: 2px;
	  border-bottom-left-radius: 2px;

	  li:first-child {
		margin-top: 0;
	  }
	}
  }

  /*--- PARSELY ---*/

  .main-form-search {
	.btn, .sp-container button {
	  position: absolute;
	  top: 7px;
	  right: 5px;
	  background-color: transparent;
	  height: 40px;
	  color: #737688;
	  transition: none;
	  font-size: 16px;
	  padding-right: 13px;
	}
  }

  .sp-container .main-form-search button {
	position: absolute;
	top: 7px;
	right: 5px;
	background-color: transparent;
	height: 40px;
	color: #737688;
	transition: none;
	font-size: 16px;
	padding-right: 13px;
  }

  div.conv-form-wrapper div#messages div.message.from {
	background: #005995;
	color: $white;
  }

  form.convFormDynamic button.submit {
	border: 1px solid #005995;
	background: #005995;
  }

  div.conv-form-wrapper div.options div.option {
	border: 1px solid #005995;
	color: #005995;
  }

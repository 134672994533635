.main-media-list-reviews {
	.media + .media {
	  margin-top: 25px;
	}
  
	.main-img-user {
	  width: 32px;
	  height: 32px;
  
	  &::after {
		display: none;
	  }
	}
  
	.media-body {
	  margin-left: 15px;
  
	  h6 {
		margin-bottom: 2px;
		line-height: 1;
	  }
  
	  small {
		display: inline-block;
		font-size: 12px;
		color: #737688;
		line-height: 1;
	  }
	}
  }
  
  .main-media-list-activity {
	.media + .media {
	  margin-top: 25px;
	}
  
	.media-icon {
	  width: 32px;
	  height: 32px;
	  line-height: 32px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  color: $white;
	  border-radius: 100%;
  
	  i {
		font-size: 18px;
		line-height: 0.9;
	  }
	}
  
	.media-body {
	  margin-left: 15px;
  
	  h6 {
		margin-bottom: 2px;
		color: #14112d;
	  }
  
	  span {
		display: block;
		font-size: 11px;
		color: #737688;
	  }
	}
  
	.media-right {
	  font-size: 11px;
	  color: #737688;
	}
  }
  
  .media-icon {
	width: 45px;
	height: 45px;
	line-height: 45px;
	color: $white;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
  
	i {
	  font-size: 24px;
	}
  }
  
  .main-notification-list .media + .media {
	border-top: 1px solid  $gray-100;
  }
  
  .main-header .main-header-notification .dropdown-menu {
	top: 57px;
  }
  
  .main-notification-list .media {
	&:hover::before, &:focus::before {
	  background-color: #f6f7fd;
	  border-top: 1px solid  $gray-100;
	  border-bottom: 1px solid  $gray-100;
	}
  }
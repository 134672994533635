/*---Count-down---*/

#count-down {
	position: relative;
	text-align: center;
	display: inline-block;

	.clock-presenter {
	  height: 80px;
	  padding: 0px;
	  text-align: center;

	  .digit {
		margin-top: 10px;
		font-size: 1.5rem;
		line-height: 60px;
		height: 60px;
		display: inline-block;
		overflow: hidden;
		text-align: center;
		position: relative;
		cursor: default;
		font-weight: 600;
		border-radius: 0px;
	  }

	  width: 80px;
	  height: 80px;
	  float: left;
	  margin: 0 20px 0 0;
	  background: url(../../../assets/img/photos/4.jpg);
	  border-radius: 50%;
	  background-size: cover;
	  position: relative;
	  box-shadow: 0 0 0 rgb(17, 5, 150);
	  animation: pulse 2s infinite;

	  &:before {
		content: '';
		position: absolute;
		background: rgba(17, 5, 150, 0.75);
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		display: block;
		border-radius: 50%;
	  }

	  .note {
		position: relative;
		margin-bottom: 0px;
		cursor: default;
		font-size: 16px;
		opacity: 0.7;
	  }
	}
  }

  @media (max-width: 568px) {
	#count-down .clock-presenter {
	  float: none !important;
	  margin-bottom: 45px;
	}
  }

  .countdown li {
	background: $primary !important;
  }

/*--- PRICING ---*/

.pricing-style01 .list-unstyled li {
	padding: 8px 0;
	border-top: 1px solid $gray-100;
  
	&:first-child {
	  border-top: 0;
	}
  }
  
  .pricing-tabs ul.nav-price {
	text-align: center;
	margin: 0 auto;
	display: inline-flex;
	margin-top: 30px;
	margin-bottom: 40px;
	border: 1px solid $primary;
	border-radius: 0px;
	background: $white;
  
	li {
	  display: block;
  
	  a {
		padding: 12px 40px;
		display: block;
		background: $white;
		font-size: 16px;
		color: #14112d;
		border-radius: 0px;
		transition: 0.3s;
  
		&.active {
		  background: $primary;
		  color: $white;
		  transition: 0.3s;
		}
	  }
	}
  }
  
  /*--- PRICING ---*/